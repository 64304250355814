import * as Yup from "yup";

const authNewPasswordSchema = (t) => ({
  password: Yup.string()
    .required(t("auth.register.registerForm.validation.password.required"))
    .min(8, t("auth.register.registerForm.validation.password.min"))
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1})((?=.*\d){1}).*$/,
      t("auth.register.registerForm.validation.password.matches"),
    ),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      t("auth.register.registerForm.validation.passwordConfirmation.oneOf"),
    )
    .required(
      t("auth.register.registerForm.validation.passwordConfirmation.required"),
    ),
});

export default authNewPasswordSchema;
