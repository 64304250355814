import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Icon, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Text from "../components/Typography/Text";
import ProfileStatus from "./Account/Parts/ProfileStatus";
import { InformationIcon } from "../pages/account/edit";

const rest = " - Job Multiverse!";

export const Page = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>
          {``} {title}
          {rest}
        </title>
      </Helmet>
      {children}
    </>
  );
};

export const Head = () => {
  const { t } = useTranslation();

  const Default = `Job Multiverse! - ${t("parts.page.default")}`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{Default}</title>
    </Helmet>
  );
};

export const PageHeader = ({
  text,
  modalText,
  children,
  modalTitle,
  tabPercent,
  profileStatus = false,
  backButton = true,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const previousLocation = localStorage.getItem("previousLocation");

  const handleClick = () => {
    navigate(previousLocation);
  };
  return (
    <Box
      alignItems={"center"}
      backgroundColor={"#F6F6F6"}
      justifyContent={"space-between"}
      pt={3}
      pb={3}
      pl={2}
      pr={2}
      display={"flex"}
      sx={{
        width: { xs: "100vw", sm: "auto" },
        position: { xs: "relative", sm: "relative" },
        left: { xs: "50%", sm: "auto" },
        right: { xs: "50%", sm: "auto" },
        marginLeft: { xs: "-50vw", sm: "auto" },
        marginRight: { xs: "-50vw", sm: "auto" },
      }}
    >
      <Box
        width={`${tabPercent + 1}%`}
        height={"2px"}
        backgroundColor={theme.palette.primary.main}
        position={"absolute"}
        bottom={0}
        sx={{ transition: "0.5s" }}
        left={0}
      ></Box>
      <Box display={"flex"} alignItems={"center"}>
        {backButton && (
          <Box onClick={handleClick} mr={1}>
            <Icon sx={{ display: "flex", cursor: "pointer" }} color={"primary"}>
              <KeyboardArrowLeftIcon />
            </Icon>
          </Box>
        )}
        <Text text={text} variant={"h3"} />
        {profileStatus ? (
          <Box ml={1}>
            <ProfileStatus />
          </Box>
        ) : (
          <>
            {modalText && (
              <Box ml={1} display={"flex"} alignItems={"center"}>
                <InformationIcon title={modalTitle} text={modalText} />
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
