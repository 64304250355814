import React from "react";
import { Button } from "@mui/material";
import { keyframes, styled } from "@mui/system";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(81, 0, 185, 0.5);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(81, 0, 185, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(81, 0, 185, 0);
  }
`;

const CircleButton = styled(Button)(({ theme, active }) => ({
  width: 100,
  height: 100,
  borderRadius: "50%",
  position: "relative",
  overflow: "hidden",
  animation: active ? `${pulseAnimation} 1.8s infinite` : "unset",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  transition: "transform 0.3s",
  transform: active ? "scale(1)" : "scale(0)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const PlayIcon = styled("div")(({ active }) => ({
  width: 100,
  height: 100,
  borderRadius: "50%",
  opacity: active ? 1 : 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "opacity 0.3s, transform 0.3s",
  transform: active ? "scale(1)" : "scale(0.8)",
}));
const ButtonVideoPlay = ({ active, setActive, onClick, ...props }) => {
  return (
    <CircleButton active={active} onClick={onClick} {...props}>
      <PlayIcon active={active}>
        <PlayArrowIcon style={{ fontSize: 40 }} />
      </PlayIcon>
    </CircleButton>
  );
};

export default ButtonVideoPlay;
