import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";
import { useEffect, useState } from "react";
import ModalContent from "../../../components/Modals/Modal";
import { Box } from "@mui/material";
import ButtonPrimary from "../../../components/Buttons/Button";
import Text from "../../../components/Typography/Text";

const UnsavedChangesModal = ({ when, handleSubmit, isValid }) => {
  const { values, dirty } = useFormikContext();

  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [when, blocker]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (dirty) {
        event.returnValue = t("parts.unloadWarning.text");

        return t("parts.unloadWarning.text");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dirty]);

  const handleLeaveClick = () => {
    setModalVisible(false);
    blocker.proceed();
  };

  const handleSaveAndLeave = () => {
    setModalVisible(false);
    blocker.proceed();
    handleSubmit(values);
  };

  return (
    <ModalContent open={modalVisible} onClose={() => setModalVisible(false)}>
      <Text text={"Confirmation of leaving"} variant={"h3"} />
      <Box mt={2} display={"flex"} gap={2}>
        <ButtonPrimary fullWidth onClick={handleSaveAndLeave}>
          Save and leave
        </ButtonPrimary>
        <ButtonPrimary fullWidth onClick={handleLeaveClick}>
          Leave
        </ButtonPrimary>
      </Box>
    </ModalContent>
  );
};

export default UnsavedChangesModal;
