import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../hooks/useApi";

export const fetchConfig = createAsyncThunk("config/fetchConfig", async () => {
  const { request } = api();

  return await request({ url: "/options" });
});

const initialData = {
  config: null,
  loading: true,
  offline: true,
  plans: null,
};

const configSlice = createSlice({
  name: "config",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.offline = false;
        state.config = action.payload.variables;
        state.plans = action.payload.plans;
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        console.log("API ERROR: ", action.error.message);
        state.loading = false;
        state.offline = true;
      });
  },
});

export default configSlice.reducer;
