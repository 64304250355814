import * as Yup from "yup";

const authLoginSchema = () => ({
  email: Yup.string()
    .email("It doesn't look like email")
    .required("Email required"),
  password: Yup.string()
    .required("Enter password")
    .min(8, "Minimum password length is 8 characters")
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/,
      "Special character and capital letter are required!",
    ),
});

export default authLoginSchema;
