import { Box, useTheme } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";
import { ReactComponent as GoogleLogo } from "../../assets/svg/comapny-logo/google.svg";
import { ReactComponent as AppleLogo } from "../../assets/svg/comapny-logo/apple.svg";
import Text from "../../components/Typography/Text";
import { signInWithRedirect } from "aws-amplify/auth";

const SocialMediaAuth = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{ position: "relative" }}
      mt={3}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
    >
      <Box
        width={150}
        height={"2px"}
        backgroundColor={theme.palette.darkWhite.main}
        sx={{ position: "absolute", transform: "translateX(-50%)" }}
        top={-10}
        left={"50%"}
      ></Box>
      <ButtonPrimary
        onClick={() => signInWithRedirect({ provider: "Google" })}
        color={"darkWhite"}
      >
        <GoogleLogo />
        <Text
          text={"Continue with Google"}
          variant={"body1"}
          sx={{ textTransform: "capitalize" }}
          ml={1}
        />
      </ButtonPrimary>
      <ButtonPrimary
        onClick={() => signInWithRedirect({ provider: "Apple" })}
        color={"darkWhite"}
      >
        <AppleLogo />
        <Text
          text={"Continue with Apple"}
          variant={"body1"}
          sx={{ textTransform: "capitalize" }}
          ml={1}
        />
      </ButtonPrimary>
    </Box>
  );
};

export default SocialMediaAuth;
