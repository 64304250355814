import CloseOutside from "../../../components/CloseOutside";
import React, { useState } from "react";
import AppConfig from "../../../config/appConfig";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import CloseIcon from "../../Parts/CloseIcon";
import usePopup from "../../../hooks/usePopup";
import useApi from "../../../hooks/useApi";

const ReportViolation = ({ item }) => {
  const reports = AppConfig.report.reasons;

  const [error, setError] = useState(false);

  const [isReported, setIsReported] = useState(false);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { closePopup } = usePopup();

  const { request } = useApi();

  const handleClose = () => {
    closePopup();
  };

  const TopContent = ({ text }) => {
    return (
      <div className="d-flex justify-content-center border-bottom mb-3 pb-1">
        <h5>{text}</h5>
        {!isReported && (
          <CloseIcon handleClose={handleClose} additionalStyles="mt-3 me-3" />
        )}
      </div>
    );
  };

  const ReportReasons = () => {
    const report = (reportText) => {
      setLoading(true);

      const data = {
        id: item.uuid,
        type: "user",
        reason: reportText,
      };
      request({
        url: "/report",
        method: "post",
        body: data,
        onSuccess: () => {
          setIsReported(true);
        },
        onError: () => {
          closePopup();
          setLoading(false);
        },
      });
    };

    return (
      <>
        {Object.keys(reports).map((key, index) => (
          <p
            key={index}
            className="mb-0 pt-2 pb-2 ps-1 text-center font-lg"
            onClick={() => report(reports[key])}
          >
            {t(`config.appConfig.report.${reports[key]}`)}
          </p>
        ))}
      </>
    );
  };

  const ReportedInformation = () => {
    return (
      <>
        Your feedback will help us ensure safety.
        <button className="btn btn-primary mt-3 w-100" onClick={handleClose}>
          Close
        </button>
      </>
    );
  };

  return (
    <>
      <CloseOutside handleClose={handleClose}>
        <div className="report-violation">
          <TopContent
            text={
              isReported
                ? t("jobs.parts.reportViolation.topContent.thanks")
                : "Report"
            }
          />
          {isReported ? (
            <ReportedInformation />
          ) : (
            <>
              {loading ? (
                <Rings
                  height="40"
                  width="475"
                  color="#474747"
                  radius="6"
                  ariaLabel="rings-loading"
                />
              ) : (
                <ReportReasons />
              )}
            </>
          )}
        </div>
      </CloseOutside>
    </>
  );
};

export default ReportViolation;
