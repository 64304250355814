import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans";
import "@fontsource/poppins";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5100b9",
    },
    secondary: {
      main: "#00D1C6",
      contrastText: "#fff",
    },
    danger: {
      main: "#FF0050",
      contrastText: "#fff",
    },
    info: {
      main: "#D0D6DE",
      contrastText: "#fff",
    },
    dark: {
      main: "#000",
      secondary: "#40464E",
    },
    white: {
      main: "#ffffff",
      contrastText: "#000",
    },
    border: {
      input: "#D0D6DE",
    },
    warning: {
      main: "#e5c401",
    },
    darkWhite: {
      main: "#F8FAFD",
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 300,
    xs: 10,
    sm: 12,
    md: 14,
    base: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
    "3xl": 30,
    "4xl": 36,
    "5xl": 48,
    "6xl": 60,
    "7xl": 72,
    "8xl": 96,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightBlack: 900,
    fontFamilySecondary: "'Poppins', sans-serif",
    a: {
      textDecoration: "none",
    },
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "48px",
      "@media (max-width:768px)": {
        fontSize: "30px",
      },
      fontWeight: 500,
      color: "#5100b9",
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "42px",
      "@media (max-width:768px)": {
        fontSize: "25px",
      },
      fontWeight: 500,
      color: "#5100b9",
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "20px",
      "@media (max-width:768px)": {
        fontSize: "18px",
        fontWeight: 300,
      },
      fontWeight: 400,
      color: "#5100b9",
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "18px",
      "@media (max-width:768px)": {
        fontSize: "16px",
        fontWeight: 300,
      },
      fontWeight: 400,
      color: "#5100b9",
    },
    h5: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "9px",
      color: "#5100b9",
    },
    h6: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      "@media (max-width:768px)": {
        fontSize: "11px",
      },
      fontWeight: 400,
      color: "#5100b9",
    },
    body1: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      fontSize: "15px",
      "@media (max-width:768px)": {
        fontSize: "13px",
      },
      color: "#444444",
    },
    body2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      fontSize: "12px",
      color: "#999",
    },
    body3: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
      fontSize: "22px",
    },
    overline: {
      color: "#5100b9",
      fontSize: "12px",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
