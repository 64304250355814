import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { X } from "react-feather";
import useUser from "../../hooks/useUser";

const Notifications = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const [isWindowOpen, setIsWindowOpen] = useState(
    localStorage.getItem("completed-profile-window-close") !== null
      ? false
      : true,
  );

  if (!user) {
    return <></>;
  }

  const DeletedInfo = () => {
    const currentDate = new Date();

    const expireDate = new Date(user.deleted_at);

    const timeDifference = expireDate.getTime() - currentDate.getTime();

    const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return (
      <div className={"app-top-bar-info bg-danger"}>
        {t("parts.header.topBarInformation.deleteInfo.text1")} {daysRemaining}{" "}
        {t("parts.header.topBarInformation.deleteInfo.text2")}{" "}
        {t("parts.header.topBarInformation.deleteInfo.text3")}
        <Link to={"/account/settings?tab=delete"}>
          {" "}
          {t("parts.header.topBarInformation.deleteInfo.link")}
        </Link>
        .
      </div>
    );
  };

  const CompletedProfileWindow = ({ setIsWindowOpen }) => {
    const { user } = useUser();

    useEffect(() => {
      if (user.percent !== 100) {
        setIsWindowOpen(true);
      } else {
        setIsWindowOpen(false);

        localStorage.removeItem("completed-profile-window-close");
      }
    }, [user.percent]);

    const closeWindowPermanently = () => {
      setIsWindowOpen(false);

      localStorage.setItem("completed-profile-window-close", true);
    };

    return (
      <div className="app-top-bar-info bg-primary d-flex align-items-center justify-content-center position-relative pt-1 pb-1">
        <div className="mb-0 ps-3 pe-3 text-white d-flex align-items-center flex-column flex-sm-row">
          <p className={"mb-0 text-center"}>
            {t("parts.header.topBarInformation.completedProfileWindow.text")}{" "}
            <b className={"ms-1"}>{user.percent} %</b>
          </p>{" "}
          <Link
            className={"btn btn-outline-secondary ps-2 pe-2 pt-1 pb-1 ms-1"}
            to="/account/edit"
          >
            {t("parts.header.topBarInformation.completedProfileWindow.link")}
          </Link>
        </div>
        <X
          size={18}
          className="cursor-pointer position-absolute end-0 me-md-3 me-2 text-white"
          onClick={closeWindowPermanently}
        />
      </div>
    );
  };

  return (
    <>
      {user.status === "deleted" && <DeletedInfo />}
      {isWindowOpen && user.status !== "deleted" && (
        <CompletedProfileWindow setIsWindowOpen={setIsWindowOpen} />
      )}
    </>
  );
};

export default Notifications;
