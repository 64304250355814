import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Text from "../../components/Typography/Text";
import { Box } from "@mui/material";
import ShareView from "../Account/Parts/ShareView";
import AddToWatchlistView from "../Account/Parts/AddToWatchlistView";
import MoreOptionsView from "../Account/Parts/MoreOptionsView";
import { useNavigate } from "react-router-dom";

const ProfileHeader = ({ profile }) => {
  const navigate = useNavigate();
  const previousLocation = localStorage.getItem("previousLocation");

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        onClick={() => navigate(previousLocation)}
        display={"flex"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
        }}
      >
        <NavigateBeforeIcon color={"primary"} />
        <Text variant={"body1"} text={"Back"} />
      </Box>
      <Box gap={1} display={"flex"}>
        <AddToWatchlistView profile={profile} />
        <ShareView profile={profile} />
        <MoreOptionsView
          profile={profile}
          options={["close", "report", "watchlist", "share", "similar"]}
        />
      </Box>
    </Box>
  );
};

export default ProfileHeader;
