import { Box, useTheme } from "@mui/material";
import Text from "../../components/Typography/Text";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { firstLetterUpper } from "../../utils/appUtils";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import useConfig from "../../hooks/useConfig";
import Tooltip from "../../components/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useInstantSearch } from "react-instantsearch";
import useQueryString from "../../hooks/useQueryString";
import useUser from "../../hooks/useUser";
import { DisplayItem } from "../../components/Autocompletes/AutocompleteProfile";
import useLoadGoogleMaps from "../../hooks/useLoadGoogleMaps";
import getMapZoom, { Default } from "../../utils/geoUtils";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const SummaryItems = ({ details }) => {
  const { config } = useConfig();

  const { addQuery } = useQueryString();

  const salaryTypeConfig = {
    0: "hour",
    1: "week",
    2: "month",
  };

  const icons = {
    categories: (
      <WorkOutlineOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    tags: (
      <FolderOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    locations: (
      <LocationOnOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    salary: (
      <AttachMoneyOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    languages: (
      <LanguageOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    working_time: (
      <AccessTimeOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    working_mode: (
      <GroupOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    contract: (
      <ArticleOutlinedIcon
        color="primary"
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
  };

  const renderValue = (name, value, limit) => {
    if (!value) return "";
    if (name === "salary") {
      return `$${value[0].from} - $${value[0].to} / ${salaryTypeConfig[value[0].type]}`;
    }

    if (Array.isArray(value)) {
      const formattedValues = value.slice(0, limit).map((item) => {
        switch (name) {
          case "categories":
          case "tags":
            return firstLetterUpper(item);
          case "locations":
            return firstLetterUpper(item.name);
          case "languages":
            return `${firstLetterUpper(item.language)} ${firstLetterUpper(item.level)}`;
          case "working_mode":
          case "working_time":
          case "contract":
            return (
              config[name].items.find((el) => el.key === +item)?.value || ""
            );
          default:
            return "";
        }
      });
      return formattedValues.join(", ");
    }

    return "";
  };

  const renderTooltipContent = (name, value, limit, handleClick) => {
    const remainingValues = value.slice(limit);

    return (
      <Box>
        {remainingValues.map((item, index) => {
          const displayValue = (() => {
            switch (name) {
              case "categories":
              case "tags":
                return firstLetterUpper(item);
              case "locations":
                return firstLetterUpper(item.name);
              case "languages":
                return `${firstLetterUpper(item.language)} ${firstLetterUpper(item.level)}`;
              case "working_mode":
              case "working_time":
              case "contract":
                return (
                  config[name].items.find((el) => el.key === +item)?.value || ""
                );
              default:
                return "";
            }
          })();

          return (
            <Text
              key={index}
              onClick={() => handleClick?.(value, index + limit)}
              sx={{
                cursor: handleClick ? "pointer" : "text",
                color: "#fff",
              }}
              variant={"body1"}
              text={displayValue}
            />
          );
        })}
      </Box>
    );
  };

  const navigate = useNavigate();

  const { setIndexUiState } = useInstantSearch();

  const { handleSetGeo } = useUser();

  const handleClick = (attribute, item, index) => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    setIndexUiState((prev) => {
      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          [attribute]: [
            ...(prev.refinementList?.[attribute] ?? []),
            item[index],
          ],
        },
      };
    });
  };

  const handleSetLocation = async (location, index) => {
    const place = location[index];

    if (!place) {
      return;
    }

    addQuery("place", place.name);

    handleSetGeo({
      radius: place.radius,
      lat: place.lat,
      lng: place.lon,
      name: place.name,
      selected: true,
    });

    if (window.location.pathname !== "/") {
      navigate("/");
    }
  };

  const summaryConfig = [
    {
      name: "categories",
      value: details.categories,
      limit: 2,
      handleClick: (item, index) => handleClick("categories", item, index),
    },
    {
      name: "tags",
      value: details.tags,
      limit: 2,
      handleClick: (item, index) => handleClick("tags", item, index),
    },
    {
      name: "locations",
      value: details.locations,
      limit: 1,
      handleClick: (location, index) => handleSetLocation(location, index),
    },
    {
      name: "salary",
      value:
        details.salary_from && details.salary_to && details.salary_type
          ? [
              {
                from: details.salary_from,
                to: details.salary_to,
                type: details.salary_type,
              },
            ]
          : null,
    },
    { name: "languages", value: details.languages, limit: 2 },
    { name: "working_time", value: details.working_time, limit: 2 },
    { name: "working_mode", value: details.working_mode, limit: 2 },
    { name: "contract", value: details.contract, limit: 2 },
  ];

  return (
    <Box
      mt={1}
      display="flex"
      flexWrap="wrap"
      columnGap={1}
      rowGap={0.5}
      mb={2}
    >
      {summaryConfig.map((item, index) => {
        const isVisible = item.value
          ? Array.isArray(item.value)
            ? item.value?.length !== 0
            : item.value?.from?.length !== 0 && item.value?.to?.length !== 0
          : false;

        if (!isVisible) return null;

        const hasOverflow = item.limit && item.value.length > item.limit;

        return (
          <Box key={index} display="flex" alignItems="center">
            <Box mr={0.5}>{icons[item.name]}</Box>
            <Box display="flex" gap={0.8}>
              {item.value &&
                Array.isArray(item.value) &&
                item.value.slice(0, item.limit).map((valueItem, valueIndex) => (
                  <Box key={valueIndex} display="flex" alignItems="center">
                    <Text
                      onClick={() =>
                        item.handleClick
                          ? item.handleClick(item.value, valueIndex)
                          : null
                      }
                      sx={{ cursor: item.handleClick ? "pointer" : "text" }}
                      text={renderValue(item.name, [valueItem], 1)}
                      body={"body1"}
                    />
                  </Box>
                ))}
            </Box>
            {hasOverflow && item.value && (
              <Tooltip
                title={renderTooltipContent(
                  item.name,
                  item.value,
                  item.limit,
                  item.handleClick,
                )}
                placement="top"
              >
                <Box>
                  <Text
                    ml={0.8}
                    sx={{ cursor: "pointer" }}
                    text={`+${item.value.length - item.limit} other`}
                    variant="body1"
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const Items = ({ details }) => {
  const { config } = useConfig();

  const contentConfig = [
    {
      name: "skills",
      value: details.skills,
      title: "Skills",
      visible: details.skills.length !== 0,
    },
    {
      name: "languages",
      value: details.languages.map(
        (el) =>
          `${firstLetterUpper(el.language)} ${firstLetterUpper(el.level)}`,
      ),
      title: "Languages",
      visible: details.languages.length !== 0,
    },
    {
      name: "interests",
      value: details.interests,
      title: "Interests",
      visible: details.interests.length !== 0,
    },
    {
      name: "characterTraits",
      value: [
        details.working_mode?.map(
          (el) =>
            config.working_mode.items.find(
              (configItem) => configItem.key === +el,
            ).value,
        ),
        details.working_time?.map(
          (el) =>
            config.working_time.items.find(
              (configItem) => configItem.key === +el,
            ).value,
        ),
        details.contract?.map(
          (el) =>
            config.contract.items.find((configItem) => configItem.key === +el)
              .value,
        ),
      ],
      title: "Desirable character traits",
      visible:
        (details.working_mode && details.working_mode.length !== 0) ||
        (details.working_mode && details.working_time.length !== 0) ||
        (details.contract && details.contract.length !== 0),
    },
  ];

  return (
    <Box mt={2}>
      {contentConfig.map((item, index) => {
        if (!item.visible || !item.title) {
          return;
        }

        return (
          <>
            <Box key={index} mt={2} mb={2}>
              <Text mb={0.5} text={item.title} variant={"h3"} />
              {item.name !== "characterTraits" ? (
                <>
                  {item.visible && (
                    <Box flexWrap={"wrap"} display={"flex"} gap={1}>
                      {item.value.map((el, index) => (
                        <DisplayItem text={el} key={index} />
                      ))}
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {item.visible && (
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      {item.value.map((el, index) => (
                        <Box
                          flexWrap={"wrap"}
                          display={"flex"}
                          gap={1}
                          key={index}
                        >
                          {el.map((item, index) => (
                            <DisplayItem text={item} key={index} />
                          ))}
                        </Box>
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        );
      })}
    </Box>
  );
};

const AboutMe = ({ about }) => {
  const theme = useTheme();

  const [showMore, setShowMore] = useState(false);

  const maxLength = 250;

  const textToDisplay = showMore
    ? about
    : about?.length > maxLength
      ? about.slice(0, maxLength) + "..."
      : about;

  return (
    <Box
      p={2}
      borderRadius={4}
      border={`1px solid ${theme.palette.border.input}`}
    >
      <Text variant={"h3"} text={"About me"} mb={0.2} />
      <Text text={textToDisplay} />
      {textToDisplay && about.length > maxLength && (
        <Text
          onClick={() => setShowMore((prevState) => !prevState)}
          text={`Show ${showMore ? "less" : "more"}`}
          variant={"overline"}
        />
      )}
    </Box>
  );
};

const Experience = ({ experience }) => {
  const theme = useTheme();

  if (!experience || experience.length === 0) {
    return;
  }

  return (
    <Box mb={2}>
      <Text variant={"h3"} text={"Experience"} />
      <Box mt={1} display={"flex"} flexDirection={"column"} gap={1}>
        {experience.map((el, index) => (
          <Box display={"flex"} key={index}>
            {el.from.length === 0 && el.to.length === 0 ? (
              <></>
            ) : (
              <Box
                pr={0.8}
                mr={0.8}
                borderRight={`2px solid ${theme.palette.primary.main}`}
              >
                <Text text={`${el.from} - ${el.to}`} key={index} />
              </Box>
            )}
            <Box>
              <Text text={el.value} key={index} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Location = ({ locations }) => {
  if (!locations || locations.length === 0) {
    return;
  }

  const { isLoaded, error } = useLoadGoogleMaps();
  const mapRef = useRef();
  const lat = locations.length !== 0 ? locations[0].lat : null;
  const lng = locations.length !== 0 ? locations[0].lon : null;
  const radius = locations.length !== 0 ? locations[0].radius : null;

  const [currentLocationMap, setCurrentLocationMap] = useState({
    lat: lat,
    lng: lng,
    radius: radius,
  });

  useEffect(() => {
    if (isLoaded && mapRef.current && window.google) {
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: getMapZoom(currentLocationMap.radius),
        center: currentLocationMap,
        mapId: Default.mapId,
        disableDefaultUI: true,
      });
      locations.map((el) => {
        new window.google.maps.Circle({
          strokeColor: "#aa60f9",
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: "#5100b9",
          fillOpacity: 0.2,
          map,
          radius: el.radius * 1000,
          position: { lat: el.lat, lng: el.lon },
          center: { lat: el.lat, lng: el.lon },
        });
      });
    }
  }, [currentLocationMap, isLoaded]);

  return (
    <Box>
      <Text variant={"h3"} text={"Locations"} />
      <Box display={"flex"} flexDirection={"column"} gap={0.7} mb={1} mt={0.5}>
        {locations.map((location, index) => (
          <Box display={"flex"} alignItems={"center"} key={index}>
            <FmdGoodOutlinedIcon
              sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
              color={"primary"}
            />
            <Text
              onClick={() =>
                setCurrentLocationMap({
                  lat: location.lat,
                  lng: location.lon,
                  radius: location.radius,
                })
              }
              sx={{ cursor: "pointer" }}
              text={`${location.name} + ${location.radius} miles`}
              variant={"body1"}
            />
          </Box>
        ))}
      </Box>
      <div style={{ height: "500px" }} id="map" ref={mapRef}></div>
    </Box>
  );
};

const DisplayName = ({ name, social }) => {
  if (!social) {
    return;
  }

  return (
    <Box
      mt={1}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Text variant={"h3"} text={name} />
      <Box display={"flex"} gap={0.2}>
        {social.map((item, index) => (
          <a rel="noreferrer" href={`${item}`} key={index} target={"_blank"}>
            <DisplayIcon url={item} key={index} />
          </a>
        ))}
      </Box>
    </Box>
  );
};

const DisplayIcon = ({ url }) => {
  const DefaultIcon = <OpenInNewIcon color={"primary"} />;
  const iconsConfig = {
    "facebook.com": <FacebookOutlinedIcon color={"primary"} />,
    "linkedin.com": <LinkedInIcon color={"primary"} />,
    "instagram.com": <InstagramIcon color={"primary"} />,
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getHost = (url) => {
    if (!isValidUrl(url)) {
      return null;
    }

    const formattedUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`;

    const parse = new URL(formattedUrl);
    return parse.hostname.replace(/^www\./, "");
  };

  const host = getHost(url);

  if (host !== null && iconsConfig[host]) {
    return <>{iconsConfig[host]}</>;
  } else {
    return <>{DefaultIcon}</>;
  }
};

const ProfileContent = ({ details }) => {
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <AccessTimeIcon sx={{ width: "20px", height: "20px" }} color={"info"} />
        <Text ml={0.5} variant={"body2"} text={"from July 7, 2023"} />
      </Box>
      <DisplayName name={details.name} social={details.social} />
      <SummaryItems details={details} />
      <AboutMe about={details.about} />
      <Items details={details} />
      <Experience experience={details.experience} />
      <Location locations={details.locations} />
    </Box>
  );
};

export default ProfileContent;
