import React, { useEffect, useRef, useState } from "react";
import Controls from "./Controls";
import { Video as VideoIcon } from "react-feather";
import "./Video.scss";

const Video = ({
  src,
  poster,
  autoplay,
  duration = null,
  controls = true,
  muted = false,
  loop = false,
  setUploadFormVisible = false,
}) => {
  const [ended, setEnded] = useState(false);

  const videoRef = useRef();

  const [loading, setLoading] = useState(true);

  const [autoPlay, setAutoPlay] = useState(false);

  const [hoverActive, setHoverActive] = useState(false);

  /**
   *
   * handle Load video
   *
   */

  const handleVideoLoaded = () => {
    setLoading(false);
  };

  /**
   *
   * Autoplay if it's true, pause if autoplay its changed
   *
   */

  useEffect(() => {
    if (videoRef.current !== null) {
      if (autoplay === true && ended !== true) {
        setAutoPlay(true);
        videoRef.current.play().catch(() => {});
      } else {
        setAutoPlay(false);
        videoRef.current.pause();
        setEnded(false);
      }

      if (muted) {
        videoRef.current.muted = true;
      }

      if (loop) {
        videoRef.current.muted = true;
      }
    }
  }, [autoplay, ended]);

  /**
   *
   * handle Play and Pause
   *
   */

  const onPlaying = () => {};

  const onPause = () => {};

  const onEnded = () => {
    setEnded(true);
  };

  return (
    <div
      className={`video-full loaded`}
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
    >
      <video
        poster={poster}
        src={src}
        ref={videoRef}
        onProgress={handleVideoLoaded}
        onPlaying={onPlaying}
        onPause={onPause}
        autoPlay={false}
        onEnded={onEnded}
        playsInline
        style={{ borderRadius: "8px" }}
      ></video>
      {controls && (
        <Controls
          hoverActive={hoverActive}
          ended={ended}
          forwardRef={videoRef}
          src={src}
          autoplay={autoPlay}
          loading={loading}
          duration={duration}
          setUploadFormVisible={setUploadFormVisible}
        />
      )}
    </div>
  );
};
export default Video;
