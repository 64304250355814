import React from "react";
import { useCurrentRefinements, useInstantSearch } from "react-instantsearch";
import useConfig from "../../../hooks/useConfig";
import { useTranslation } from "react-i18next";
import { FaX } from "react-icons/fa6";

function isModifierClick(event) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey,
  );
}

const Filter = ({ refinement, item, refine }) => {
  const { config } = useConfig();
  const { t } = useTranslation();

  if (item.attribute === "languages.language") {
    const { value = "Unknown language" } = config.languages.items.find(
      (language) => language.key === refinement.value,
    );

    refinement.label = value;
  } else if (item.attribute === "categories") {
    const { title = "Unknown category" } = config.categories.items.find(
      (category) => category.slug === refinement.value,
    );

    refinement.label = title;
  } else if (item.attribute === "tags") {
    refinement.label = refinement.value;
  } else {
    refinement.label = t(
      `browse.filters.${item.attribute}.values.${refinement.value}`,
    );
  }

  const handleRemove = (event) => {
    if (isModifierClick(event)) {
      return;
    }
    refine(refinement);
  };

  return (
    <div className={"filter"}>
      <span>{refinement.label}</span>
      <button type="button" onClick={handleRemove}>
        <FaX />
      </button>
    </div>
  );
};

const FiltersActive = (props) => {
  const { items, refine } = useCurrentRefinements(props);

  const { setIndexUiState } = useInstantSearch();

  return (
    <div className={"active-filters"}>
      {items.map((item) => (
        <React.Fragment key={[item.indexName, item.label].join("/")}>
          {item.refinements.map((refinement) => (
            <Filter
              key={refinement.label}
              refinement={refinement}
              item={item}
              refine={refine}
            />
          ))}
        </React.Fragment>
      ))}
      {items.length !== 0 && (
        <button
          className={"btn bg-gray border-1"}
          onClick={() => setIndexUiState([])}
        >
          Clear filters
        </button>
      )}
    </div>
  );
};

export default FiltersActive;
