import { Box } from "@mui/material";
import Cropper from "react-easy-crop";
import React, { useState } from "react";
import getCroppedImg from "../../../services/media/cropImage";
import ButtonPrimary from "../../../components/Buttons/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const ImageCrop = ({ imageBlob, setImageBlob, onCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [rotation, setRotation] = useState(0);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCloseModal = () => {
    setImageBlob({
      blob: null,
      visible: false,
    });
  };

  const handleCropImage = async () => {
    const croppedImage = await getCroppedImg(
      imageBlob.blob,
      croppedAreaPixels,
      rotation,
    );

    const croppedImageBlob = await fetch(croppedImage).then((res) =>
      res.blob(),
    );

    handleCloseModal();
    onCrop(croppedImageBlob, imageBlob.upload_type);
  };

  const cropRotate = (direction) => {
    setRotation((prevState) =>
      direction === "left" ? prevState - 90 : prevState + 90,
    );
  };

  return (
    <Box>
      <Cropper
        image={imageBlob.blob}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        maxZoom={2}
        aspect={4 / 4}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        zoomSpeed={0.2}
      />
      <Box
        p={1}
        position={"absolute"}
        bottom={0}
        backgroundColor={"#fff"}
        width={"100%"}
        display={"flex"}
        gap={3}
      >
        <Box width={"50%"} display={"flex"} gap={1}>
          <ButtonPrimary
            fullWidth
            size={"md"}
            onClick={() => cropRotate("right")}
          >
            <RotateRightIcon />
          </ButtonPrimary>
          <ButtonPrimary
            fullWidth
            size={"md"}
            onClick={() => cropRotate("left")}
          >
            <RotateLeftIcon />
          </ButtonPrimary>
        </Box>
        <Box width={"50%"} display={"flex"} gap={1}>
          <ButtonPrimary
            fullWidth
            color={"info"}
            size={"md"}
            onClick={handleCloseModal}
          >
            Cancel
          </ButtonPrimary>
          <ButtonPrimary fullWidth onClick={handleCropImage} size={"md"}>
            Crop
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCrop;
