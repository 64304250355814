import React, { useState } from "react";
import "./Item.scss";
import ItemFooter from "./ItemFooter";
import { Col, Row } from "react-bootstrap";
import Share from "../../old_components/Parts/Misc/Share";
import MoreOptions from "../../old_components/Parts/Misc/MoreOptions";
import { Link } from "react-router-dom";
import ObserverButton from "../../old_components/Browse/Parts/ObserverButton";
import placeholderImage from "../../assets/images/placeholder.jpg";
import { ReactComponent as Icon } from "../../assets/svg/premium/thumbnail.svg";
import { Rings } from "react-loader-spinner";
import { BadgePremium } from "../Badge";
import { itemLink } from "../../hooks/useItem";

const Item = ({ item, children, handleClick, setIndexUiState }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={`app-browse-loop-item ${item.plan_id === "premium" ? "is-premium" : "is-normal"}`}
    >
      <div className={"thumbnail"}>
        <BadgePremium
          planID={item.plan_id}
          marginRight={3}
          additionalClass={"mt-1"}
        />
        {isLoading && (
          <div className={"position-absolute top-50 start-50 translate-middle"}>
            <Rings
              height="125"
              width="125"
              color="#5100b9"
              radius="6"
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        )}
        <img
          src={item.avatar || placeholderImage}
          alt={item.name}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={isLoading ? { display: "none" } : {}}
        />
        <div
          className={"video-preview cursor-pointer"}
          onClick={() => handleClick(item)}
        ></div>
        <Row className={"top-icons"}>
          <Col xs={8} className={"float-start d-flex align-items-start"}>
            <ObserverButton size={24} isText={false} uid={item.uuid} />
            <Share size={24} item={item} />
          </Col>
          <Col>
            <MoreOptions
              item={item}
              options={[
                "Share",
                "Observe",
                "ShowSimilar",
                "NoMore",
                "Report",
                "Hide",
                "Close",
              ]}
            />
          </Col>
        </Row>
        <div className={"title text-limit"}>
          <Link
            to={itemLink(false, item.uuid, item.name)}
            className={"d-flex align-items-center"}
          >
            {item.plan_id === "premium" && (
              <Icon width={18} className={"me-1"} />
            )}
            {item.name}
          </Link>
        </div>
      </div>
      <ItemFooter item={item} setIndexUiState={setIndexUiState} />
      {children}
    </div>
  );
};

export default Item;
