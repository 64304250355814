import {
  AutocompleteProfile,
  DisplayItem,
  DisplayItems,
} from "../../../components/Autocompletes/AutocompleteProfile";
import React, { useState } from "react";
import useConfig from "../../../hooks/useConfig";
import { useFormikContext } from "formik";
import Text from "../../../components/Typography/Text";
import { Box } from "@mui/material";
import { Select } from "../../../components/Forms/Select";
import ButtonPrimary from "../../../components/Buttons/Button";
import useError from "../../../hooks/useError";
import { firstLetterUpper } from "../../../utils/appUtils";
import { InformationIcon } from "../../../pages/account/edit";

const SkillsView = () => {
  const { config } = useConfig();

  const { values, setFieldValue } = useFormikContext();

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [selectedLevel, setSelectedLevel] = useState(null);

  const { detailsError } = useError();

  const addNewLanguage = () => {
    setFieldValue(
      "languages",
      values.languages
        ? [
            ...values.languages,
            { level: selectedLevel.key, language: selectedLanguage.key },
          ]
        : [{ level: selectedLevel.key, language: selectedLanguage.key }],
    );
    setSelectedLanguage(null);
    setSelectedLevel(null);
  };
  return (
    <>
      <Box mb={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
        >
          <Text text={"Choose your Skills"} variant={"h3"} />
          <InformationIcon
            title={"Skills"}
            text={
              "To enhance your profile, please add your key skills. This helps clients quickly understand your expertise and find the right match for their needs. You can add a variety of skills, including languages, professional abilities, and relevant experience. Keep your skills updated to stay competitive and attract more opportunities."
            }
          />
        </Box>
        <AutocompleteProfile
          chipError={detailsError.skills}
          options={config.skills.items}
          getOptionLabel={(option) => option}
          value={values.skills}
          limit={3}
          ownValue={true}
          onChange={(event, newValue) => {
            setFieldValue(
              "skills",
              newValue.map((item) => item),
            );
          }}
          name={"skills"}
        />
      </Box>
      <Text text={"Languages"} mb={1} variant={"h3"} />
      <Box display={"flex"} gap={1} mb={1}>
        <Select
          value={selectedLanguage}
          fullWidth
          options={config.languages.items}
          getOptionLabel={(option) => option.value}
          label={"Language"}
          onChange={(event, newValue) => {
            setSelectedLanguage(newValue);
          }}
        />
        <Select
          value={selectedLevel}
          fullWidth
          options={config.languages.additional.levels}
          getOptionLabel={(option) => option.value}
          onKeyDown={(e) =>
            e.keyCode === 13 && selectedLevel ? addNewLanguage() : null
          }
          label={"Level"}
          onChange={(event, newValue) => {
            setSelectedLevel(newValue);
          }}
        />
      </Box>
      <ButtonPrimary fullWidth onClick={addNewLanguage}>
        Add Language
      </ButtonPrimary>
      {values.languages && values.languages.length !== 0 && (
        <DisplayItems mt={2} name={"Your languages"}>
          {values.languages.map((language, index) => (
            <React.Fragment key={index}>
              <DisplayItem
                text={`${firstLetterUpper(language.language)} ${firstLetterUpper(language.level)}`}
                onRemove={() =>
                  setFieldValue(
                    "languages",
                    values.languages.filter(
                      (el) =>
                        el.level !== language.level &&
                        el.language !== language.language,
                    ),
                  )
                }
              />
            </React.Fragment>
          ))}
        </DisplayItems>
      )}
    </>
  );
};

export default SkillsView;
