import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useSavePreviousLocation = () => {
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);

  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      localStorage.setItem("previousLocation", prevLocationRef.current);

      prevLocationRef.current = location.pathname;
    }
  }, [location.pathname]);
};

export default useSavePreviousLocation;
