// import
import React from "react";
import {
  RequireAccountWrapper,
  RequireAuth,
  RequireSeller,
  RequireValidUserOrQuest,
} from "./Requires";
import Home from "./pages/home";
import Observations from "./pages/observations";
import Messages from "./pages/messages";
import NotFound from "./pages/404";
import Default from "./views/Layouts/Default";
import Similar from "./pages/similar";
import Settings from "./pages/account/settings";
import Edit from "./pages/account/edit";
import Profile from "./pages/profile";

const routes = [
  {
    path: "/",
    element: <RequireValidUserOrQuest />,
    children: [
      {
        element: <Default />,
        children: [
          { path: "/", element: <Home /> },
          { path: "similar/:id", element: <Similar /> },
          { path: "profile/:id", element: <Profile /> },
          { path: "profile/:id/:slug", element: <Profile /> },
          { path: "/premium", element: <>TO DO</> },
          {
            element: <RequireAuth />,
            children: [
              {
                path: "account/",
                element: <Profile />,
              },
              {
                path: "account/*",
                element: <RequireAccountWrapper />,
                children: [
                  {
                    element: <RequireSeller />,
                    children: [{ path: "edit", element: <Edit /> }],
                  },
                  { path: "settings", element: <Settings /> },
                ],
              },
              {
                path: "observations/*",
                element: <Observations />,
              },
              {
                path: "messages/*",
                element: <Messages />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
export default routes;
