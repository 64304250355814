import ReactDOM from "react-dom";
import React, { useState } from "react";
import FiltersDropdown from "./FiltersDropdown";
import { FiltersToggleButton } from "./FiltersParts";
import useUser from "../../../hooks/useUser";
import CloseOutside from "../../../components/CloseOutside";
import AnimatedDropdown from "../../Parts/AnimatedDropdown";
import CloseIcon from "../../Parts/CloseIcon";

const FiltersPortal = ({ visible, setVisible }) => {
  const handleClose = () => {
    setVisible(false);
  };

  return ReactDOM.createPortal(
    <div className={`app-filters-fixed ${visible ? "visible" : "hidden"}`}>
      <FiltersDropdown setVisible={setVisible}>
        <CloseIcon
          handleClose={() => setVisible(false)}
          additionalStyles="mt-3 me-3"
        />
      </FiltersDropdown>
      <div
        className={"app-filters-fixed-overlay"}
        onClick={() => setVisible(false)}
      />
    </div>,
    document.getElementById("app-portal"),
  );
};

const FiltersMobile = ({ visible, setVisible }) => {
  return (
    <div className={"app-browse-filters"}>
      <FiltersToggleButton setVisible={setVisible} />
      <FiltersPortal visible={visible} setVisible={setVisible} />
    </div>
  );
};

const FiltersDesktop = ({ visible, setVisible }) => {
  return (
    <CloseOutside
      handleClose={() => setVisible(false)}
      className={"app-browse-filters"}
    >
      <FiltersToggleButton setVisible={setVisible} />
      <AnimatedDropdown isOpen={visible} className="dropdown-filters">
        <FiltersDropdown visible={visible} setVisible={setVisible} />
      </AnimatedDropdown>
    </CloseOutside>
  );
};

const Filters = () => {
  const [visible, setVisible] = useState(false);

  const { isMobile } = useUser();

  return isMobile ? (
    <FiltersMobile visible={visible} setVisible={setVisible} />
  ) : (
    <FiltersDesktop visible={visible} setVisible={setVisible} />
  );
};

export default Filters;
