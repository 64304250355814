import { Overlay, Tooltip } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const TooltipBadge = ({ children, length = 0 }) => {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState(false);

  const target = useRef(null);

  const tooltipRef = useRef(null);

  const timeoutId = useRef();

  const handleMouseEnter = () => {
    clearTimeout(timeoutId.current);

    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      if (
        !tooltipRef.current ||
        !tooltipRef.current.contains(document.activeElement)
      ) {
        setShowTooltip(false);
      }
    }, 350);
  };

  return (
    <>
      <p
        className="mb-0 tooltip-item font-family-open-sans"
        ref={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {length === 1
          ? `+ 1 ${t("jobs.browse.parts.tooltipItem.other")}`
          : `+ ${length} ${t("jobs.browse.parts.tooltipItem.others")}`}
      </p>
      <Overlay target={target.current} show={showTooltip} placement="top">
        <Tooltip
          id="button-tooltip"
          ref={tooltipRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </Tooltip>
      </Overlay>
    </>
  );
};
