import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const stripeConfig = (subscriptionData) => {
  const options = {
    locale: "en",
    clientSecret:
      subscriptionData.data?.latest_invoice?.payment_intent?.client_secret,
    // Fully customizable with appearance API.
    // https://docs.stripe.com/elements/appearance-api
    appearance: {
      theme: "flat",
    },
  };

  return {
    stripePromise,
    options,
  };
};

export default stripeConfig;
