import React from "react";
import InboxChat from "../views/Inbox/InboxChat";
import InboxConversations from "../views/Inbox/InboxConversations";
import Container from "../components/Page/Container";
import Grid from "@mui/material/Grid2";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { Box, useTheme } from "@mui/material";

const MessagesEmptyReceiver = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0 10px 50px 10px rgba(150, 138, 186, 0.2)",
      }}
    >
      <Alert severity="info">
        Please select conversations to view messages
      </Alert>
    </Box>
  );
};

const Messages = () => {
  const [searchParams] = useSearchParams();
  const receiver = searchParams.get("uid");

  return (
    <Container sx={{ padding: { xs: 0, md: "16px" } }}>
      <Grid container spacing={3}>
        <Grid
          size={{
            xs: 12,
            md: 3,
          }}
          sx={{
            minHeight: {
              xs: "calc(100vh - 64px)",
              md: "calc(100vh - 64px)",
            },
            display: {
              xs: receiver === null ? "block" : "none",
              md: "block",
            },
            padding: {
              xs: 2,
              md: 1,
            },
          }}
        >
          <InboxConversations receiver={receiver} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 9,
          }}
          sx={{
            height: {
              xs: "calc(100vh - 72px)",
              md: "calc(100vh - 150px)",
            },
            py: {
              xs: 0,
              md: 2,
            },
            flexDirection: "column",
            display: {
              xs: receiver !== null ? "flex" : "none",
              md: "flex",
            },
          }}
        >
          {receiver ? (
            <InboxChat receiver={receiver} />
          ) : (
            <MessagesEmptyReceiver />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Messages;
