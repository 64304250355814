import { Box, TextField, Typography } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import Grid from "@mui/material/Grid2";
import React from "react";
import {
  AutocompleteHeaderLocationPaper,
  AutocompleteStyled,
} from "./Autocomplete";

const AutocompleteLocation = ({
  handleSelect,
  options,
  value,
  setInputValue,
  sx,
}) => {
  return (
    <AutocompleteStyled
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={handleSelect}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Add a location" fullWidth />
      )}
      slots={{
        paper: AutocompleteHeaderLocationPaper,
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ width: "100%", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          minHeight: "30px",
        },
        "& .MuiInputLabel-root": {
          fontSize: "13px",
          transform: "translate(14px, 11px)",
          color: "#AEAEAE",
        },
        "& .MuiInputLabel-shrink": {
          color: "#AEAEAE",
          transform: "translate(14px, -9px) scale(0.77)",
        },
        ...sx,
      }}
    />
  );
};

export default AutocompleteLocation;
