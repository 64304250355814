import ButtonPrimary from "../../../components/Buttons/Button";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import React, { useRef, useState } from "react";
import Modal from "../../../components/Modals/Modal";
import { Box } from "@mui/material";
import Text from "../../../components/Typography/Text";
import TextField from "../../../components/Forms/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNotification } from "../../../hooks/useNotifications";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@mui/material/Link";
import { itemLink } from "../../../hooks/useItem";

export const ShareModal = ({
  shareModalVisible,
  setShareModalVisible,
  profile,
}) => {
  const linkRef = useRef();

  const { addNotification } = useNotification();

  const config = [
    {
      icon: <FacebookIcon sx={{ fontSize: 25 }} />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${itemLink(true, profile.details.uuid, profile.details.name)}`,
    },
    {
      icon: <XIcon sx={{ fontSize: 25 }} />,
      link: `https://twitter.com/intent/tweet?url=${itemLink(true, profile.details.uuid, profile.details.name)}`,
    },
    {
      icon: <LinkedInIcon sx={{ fontSize: 25 }} />,
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${itemLink(true, profile.details.uuid, profile.details.name)}`,
    },
  ];

  const handleCopyUrl = () => {
    if (linkRef.current) {
      linkRef.current.select();
      document.execCommand("copy");
      addNotification("success", "Url copied");
    }
  };

  return (
    <Modal open={shareModalVisible} onClose={() => setShareModalVisible(false)}>
      <Box>
        <Text text={"Share"} variant={"h3"} />
        <Box mb={2} mt={3}>
          <TextField
            inputRef={linkRef}
            label={"Copy url"}
            fullWidth
            value={itemLink(true, profile.details.uuid, profile.details.name)}
            slotProps={{
              input: {
                endAdornment: (
                  <ContentCopyIcon
                    onClick={handleCopyUrl}
                    color={"primary"}
                    cursor={"pointer"}
                  />
                ),
              },
            }}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          {config.map((el, index) => (
            <Link key={index} href={el.link} target={"_blank"}>
              <ButtonPrimary sx={{ minWidth: "40px", width: "40px" }}>
                {el.icon}
              </ButtonPrimary>
            </Link>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

const ShareView = ({ profile, children }) => {
  const [shareModalVisible, setShareModalVisible] = useState(false);

  if (!profile || !profile.details) {
    return;
  }

  return (
    <>
      {children ? (
        children
      ) : (
        <ButtonPrimary
          onClick={() => setShareModalVisible(true)}
          color={"info"}
          sx={{ minWidth: "40px", width: "40px" }}
        >
          <ShareOutlinedIcon />
        </ButtonPrimary>
      )}
      <ShareModal
        shareModalVisible={shareModalVisible}
        setShareModalVisible={setShareModalVisible}
        profile={profile}
      />
    </>
  );
};

export default ShareView;
