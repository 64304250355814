import svg from "../../assets/logo.svg";
import React from "react";
import { Box } from "@mui/material";

const Logo = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mr={{ xs: 0, sm: 0, md: 2 }}
      pb={{ xs: 1, sm: 1, md: 0 }}
      sx={{ cursor: "pointer" }}
      {...props}
    >
      <Box
        component="img"
        src={svg}
        alt="logo"
        sx={{
          height: { xs: 24, sm: 24, md: 36 },
          mt: { xs: 0, sm: 0, md: -0.5 },
          position: "relative",
        }}
      />
    </Box>
  );
};

export default Logo;
