import React from "react";

const AnimatedDropdown = ({ isOpen, children, className }) => {
  return (
    <div className={`${className} dropdown-animated ${isOpen ? "open" : ""}`}>
      {children}
    </div>
  );
};

export const AnimatedDropdownList = ({ children }) => {
  return <ul>{children}</ul>;
};

export const AnimatedDropdownListItem = ({ children, handleClick }) => {
  return <li onClick={handleClick}>{children}</li>;
};

export default AnimatedDropdown;
