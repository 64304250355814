import React, { useState } from "react";
import useAddressPredictions from "../../../hooks/useAddressPredictions";
import { Configure } from "react-instantsearch";
import useQueryString from "../../../hooks/useQueryString";
import useUser from "../../../hooks/useUser";
import { getLocationName } from "../../../utils/geoUtils";
import { useNavigate } from "react-router-dom";
import AutocompleteHeaderLocation from "../../../components/Autocompletes/AutocompleteHeaderLocation";
import PlaceIcon from "@mui/icons-material/Place";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useNotification } from "../../../hooks/useNotifications";
import useBrowse from "../../../hooks/useBrowse";
import SearchRadius from "./SearchRadius";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
const SearchLocationClient = () => {
  const [loading, setLoading] = useState(false);
  const { handleSetGeo } = useUser();
  const { addNotification } = useNotification();
  const { addQuery } = useQueryString();
  const navigate = useNavigate();

  const handleSetLocation = async (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    const name = await getLocationName(lat, lng);

    addQuery("place", name.name);

    handleSetGeo({
      radius: 50,
      lat: lat,
      lng: lng,
      name: name.name,
      selected: true,
    });

    setLoading(false);

    if (window.location.pathname !== "/") {
      navigate("/");
    }
  };

  const handleLocate = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSetLocation, (error) => {
        addNotification(
          "error",
          `Error getting geolocation. Please make sure you have allowed location access. (${error.message})`,
        );
        setLoading(false);
      });
    } else {
      addNotification(
        "error",
        `Geolocation is not supported by your browser. Please make sure you have allowed location access.`,
      );
      setLoading(false);
    }
  };

  return (
    <IconButton onClick={handleLocate}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <PlaceOutlinedIcon sx={{ fontSize: "24px", color: "#E8EAED" }} />
      )}
    </IconButton>
  );
};

const SearchLocation = () => {
  const { resetGeo } = useBrowse();
  const { geo, handleSetGeo } = useUser();

  const { addQuery } = useQueryString();

  const handleChangePrediction = async (prediction) => {
    if (prediction === null) {
      resetGeo();
      return;
    }
    let placeId = prediction.place_id;

    const geocoder = new window.google.maps.Geocoder();

    await geocoder.geocode(
      { placeId },

      (results) => {
        handleSetGeo({
          ...geo,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          name: prediction.description,
          selected: true,
        });

        // Add the place to the query string
        addQuery("place", prediction.description);
      },
    );
  };

  const { setAddress, loading, predictions, address } = useAddressPredictions();

  const handleReset = () => {
    resetGeo(() => {
      setAddress("");
    });
  };

  const Append = () => {
    return (
      <>
        {!geo.selected && !loading && <SearchLocationClient />}
        {geo.selected && !loading && (
          <IconButton onClick={handleReset}>
            <ClearIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{
        marginLeft: {
          xs: 0,
          md: 1,
        },
        marginTop: {
          xs: 1,
          md: 0,
        },
      }}
    >
      {geo.selected && (
        <Configure
          aroundLatLng={geo.selected ? `${geo.lat}, ${geo.lng}` : false}
          aroundRadius={geo.radius * 1000}
        />
      )}
      <AutocompleteHeaderLocation
        handleSelect={setAddress}
        Append={<Append />}
        // original props
        options={predictions}
        loading={loading}
        value={geo.name ? geo.name : address}
        onChange={async (event, value) => {
          await handleChangePrediction(value);
        }}
        sx={{
          flex: 1,
        }}
      />
      {geo.selected && <SearchRadius sx={{ marginLeft: 1 }} />}
    </Box>
  );
};

export default SearchLocation;
