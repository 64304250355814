import React from "react";
import { useNavigate } from "react-router";
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Skeleton } from "@mui/lab";

import { itemLink } from "../../hooks/useItem";

const InboxChatHeaderSkeleton = () => {
  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
      }}
    >
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton
        variant="text"
        sx={{ fontSize: "0.875rem", minWidth: 160, ml: 1 }}
      />
      <Skeleton
        variant="circular"
        width={32}
        height={32}
        sx={{ alignSelf: "flex-end", ml: "auto" }}
      />
    </Stack>
  );
};

const InboxChatHeader = ({ conversation = null }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (conversation.receiverId === "JOBOT2a1-f8ae-401c-9c7c-d8ec17488229") {
      return;
    }
    navigate(
      itemLink(false, conversation.receiverId, conversation.receiverName),
    );
  };

  const handleBack = () => {
    navigate("/messages");
  };

  if (!conversation) {
    return (
      <Box
        sx={{
          display: "flex",
          padding: 2,
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <InboxChatHeaderSkeleton />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        padding: 2,
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <IconButton
        onClick={handleBack}
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
        }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
          "&:hover": {
            color: "#000",
          },
        }}
        onClick={() => handleClick()}
      >
        <Avatar src={conversation.receiverAvatar} alt="User Logo" />
        <Typography>{conversation.receiverName || "No name"}</Typography>
      </Box>
    </Box>
  );
};

export default InboxChatHeader;
