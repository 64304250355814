import Text from "../../../components/Typography/Text";
import React, { useState } from "react";
import ButtonPrimary from "../../../components/Buttons/Button";
import Modal, { ModalHelper } from "../../../components/Modals/Modal";
import { Box, useTheme } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { useNotification } from "../../../hooks/useNotifications";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import useUser from "../../../hooks/useUser";

const HiddenProfilesView = () => {
  const [confirmModal, setConfirmModal] = useState(false);

  const { user, handleSetUser } = useUser();

  const [loading, setLoading] = useState(false);

  const { request } = useApi();

  const theme = useTheme();

  const { addNotification } = useNotification();

  const resetProfiles = async () => {
    setLoading(true);
    request({
      url: "/profile",
      method: "post",
      body: {
        action: "reset_hidden_profiles",
      },
      onSuccess: () => {
        setConfirmModal(false);
        handleSetUser({ ...user, hidden_profiles: [] });
        addNotification("success", "Restarting profiles was successful");
      },
      onError: () => {
        addNotification("error", "Restarting profiles failed");
      },
    }).finally(() => setLoading(false));
  };

  return (
    <>
      <Box display={"flex"} mb={2} alignItems={"center"}>
        <Text mr={1} text={"Black listed profiles"} variant={"h3"} />
        <ModalHelper
          title={"Hidden profiles"}
          text={
            "In this section, you can clear the list of profiles you've marked as \"Don't Show More.\" This allows you to reset the profiles you've chosen to hide, and they will start appearing again in your search results. Use this feature to manage which profiles you want to see in the future."
          }
        />
      </Box>
      {user?.hidden_profiles.length === 0 ? (
        <Box
          p={3}
          border={`1px solid ${theme.palette.border.input}`}
          borderRadius={1}
          display={"flex"}
          gap={3}
        >
          <PersonPinOutlinedIcon
            sx={{
              color: theme.palette.border.input,
              width: "70px",
              height: "70px",
            }}
          />
          <Text
            color={theme.palette.border.input}
            text={"You don't have any hidden profiles on your list"}
            variant={"body3"}
            sx={{
              lineHeight: "21px",
            }}
          />
        </Box>
      ) : (
        <ButtonPrimary
          onClick={() => setConfirmModal(true)}
          variant={"contained"}
          fullWidth
        >
          Reset all hidden profiles
        </ButtonPrimary>
      )}
      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <Text text={"Reset hidden profiles"} variant={"h3"} />
        <Text
          text={
            "Are you sure you want to clear your blacklist of hidden profiles?"
          }
          mt={3}
          mb={3}
          variant={"body1"}
        />
        <Box mt={2} display="flex" flexDirection="row" gap={1}>
          <ButtonPrimary
            fullWidth
            color={"info"}
            onClick={() => setConfirmModal(false)}
          >
            Cancel
          </ButtonPrimary>
          <ButtonPrimary
            fullWidth
            loading={loading}
            onClick={resetProfiles}
            variant={"contained"}
          >
            Ok
          </ButtonPrimary>
        </Box>
      </Modal>
    </>
  );
};

export default HiddenProfilesView;
