import React, { useEffect, useState } from "react";
import { Volume2, VolumeX } from "react-feather";
import ProgressBar from "./ProgressBar";
import { ReactComponent as PlayIcon } from "../../../../assets/svg/video/play.svg";
import ButtonVideoPlay from "../../../../components/Buttons/ButtonVideoPlay";
import { Box, CircularProgress } from "@mui/material";

const BtnPlay = ({ autoplay, forwardRef, ended }) => {
  const [playing, setPlaying] = useState(autoplay);

  useEffect(() => {
    if (ended) {
      setPlaying(false);
    }
  }, [ended]);

  useEffect(() => {
    if (autoplay === true) {
      forwardRef.current.play().catch(() => {});

      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [autoplay]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (forwardRef.current?.paused === true) {
      forwardRef.current.play().catch(() => {});

      setPlaying(true);
    } else {
      forwardRef.current.pause();

      setPlaying(false);
    }
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={handleClick}
    >
      <ButtonVideoPlay
        active={!playing}
        setActive={setPlaying}
        onClick={handleClick}
      />
    </Box>
  );
};

const BtnMute = ({ muted, forwardRef }) => {
  const [mute, setMute] = useState(
    localStorage.getItem("video-muted") === "true" ? true : false,
  );

  if (muted) {
    setMute(true);
  }

  useEffect(() => {
    if (mute) {
      forwardRef.current.muted = true;
    } else {
      forwardRef.current.muted = false;
    }
  }, [mute]);

  const handleClick = () => {
    if (forwardRef.current.muted === true) {
      forwardRef.current.muted = false;

      localStorage.setItem("video-muted", false);

      setMute(false);
    } else {
      forwardRef.current.muted = true;

      localStorage.setItem("video-muted", true);

      setMute(true);
    }
  };

  return (
    <button type="button" onClick={handleClick}>
      {mute ? <VolumeX size={20} /> : <Volume2 size={20} />}
    </button>
  );
};

const BtnSpeedUp = ({ forwardRef }) => {
  const [currentSpeed, setCurrentSpeed] = useState(1);

  useEffect(() => {
    forwardRef.current.playbackRate = currentSpeed;
  }, [currentSpeed]);
  if (!forwardRef.current) {
    return <></>;
  }
  const handleClick = () => {
    currentSpeed === 1 ? setCurrentSpeed(1.75) : setCurrentSpeed(1);
  };
  return (
    <button onClick={handleClick} className="btn btn-primary btn-speed-up">
      {currentSpeed === 1 ? "1x" : "1.75x"}
    </button>
  );
};

const Controls = ({
  hoverActive,
  forwardRef,
  autoplay,
  duration,
  loading,
  ended,
  muted = false,
}) => {
  return (
    <>
      {loading ? (
        <Box
          top={"50%"}
          left={"50%"}
          sx={{ transform: "translate(-50%, -50%)", position: "absolute" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <BtnPlay autoplay={autoplay} forwardRef={forwardRef} ended={ended} />
          <div className={"video-controls"}>
            <div className={"center"}>
              <BtnMute muted={muted} forwardRef={forwardRef} />
              <BtnSpeedUp forwardRef={forwardRef} />
            </div>
            {duration && (
              <ProgressBar
                hoverActive={hoverActive}
                duration={duration}
                forwardRef={forwardRef}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Controls;
