import React, { useState } from "react";
import { useWebSocket } from "../../hooks/useWebSocket";
import { Avatar, Box, IconButton, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import Dropdown from "../../components/Dropdown/Dropdown";
import { BadgeText } from "../../components/Badge";
import { formatDate } from "../../utils/appUtils";

const InboxConversationItem = ({ data, current = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const { requests } = useWebSocket();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (current !== data.receiverId) {
      navigate(`/messages?uid=${data.receiverId}`);
    }
  };

  const handleArchive = () => {
    handleMenuClose();
    requests.archiveConversation(data.receiverId);
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: current === data.receiverId ? "#f5f5f5" : "transparent",
        "&:hover": {
          background: "#eee",
        },
      }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          width: "calc(100% - 40px)",
        }}
        onClick={handleClick}
      >
        <Avatar src={data.receiverAvatar} alt="User Logo" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 2,
            maxWidth: "calc(100% - 60px)",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: data.unreadCount > 0 ? "700" : "400",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
              fontSize: 14,
              color: data.unreadCount > 0 ? "primary.main" : "textPrimary",
              lineHeight: 1,
            }}
          >
            <BadgeText
              color="error"
              overlap="circular"
              badgeContent={data.unreadCount}
              max={99}
            >
              {data.receiverName ? data.receiverName.slice(0, 25) : "No Name"}
            </BadgeText>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: 11 }}
          >
            {data.lastMessage
              ? formatDate(new Date(parseInt(data.lastMessage)))
              : ""}
          </Typography>
        </Box>
      </Box>

      {data.receiverId !== "JOBOT2a1-f8ae-401c-9c7c-d8ec17488229" && (
        <Box
          sx={{
            p: 1,
          }}
        >
          <IconButton onClick={handleMenuOpen} size="small">
            <MoreVertIcon />
          </IconButton>
          <Dropdown
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            disableScrollLock={true}
          >
            <MenuItem onClick={handleArchive}>Archive</MenuItem>
          </Dropdown>
        </Box>
      )}
    </Box>
  );
};

export default InboxConversationItem;
