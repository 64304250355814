import Modal from "../../../components/Modals/Modal";
import { Box, useTheme } from "@mui/material";
import Text from "../../../components/Typography/Text";
import React, { useState } from "react";
import useApi from "../../../hooks/useApi";
import { LoaderComponent } from "../../../components/Loaders/Loader";
import { useNotification } from "../../../hooks/useNotifications";

export const ReportModal = ({
  reportModalVisible,
  setReportModalVisible,
  profile,
}) => {
  const reportsConfig = {
    spam: "This is spam",
    nudity: "Nudity or sexual activity",
    hateSpeech: "Hate speech or forbidden symbols",
    violence: "Violence or dangerous organizations",
    illegalGoods: "Sale of illegal or controlled goods",
    harassment: "Harassment or stalking",
    suicide: "Suicide or self-harm",
    eatingDisorders: "Eating disorder",
    scam: "Scam or cheating",
    falseInformation: "False information",
    dontLike: "I just don't like it",
    againstLaw: "Report as illegal",
  };

  const { addNotification } = useNotification();

  const { request } = useApi();

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const report = (reportText) => {
    setLoading(true);

    const data = {
      id: profile.details.userId,
      type: "user",
      reason: reportText,
    };
    request({
      url: "/report",
      method: "post",
      body: data,
      onSuccess: () => {
        addNotification("success", "The report has been sent");
        setLoading(false);
        setReportModalVisible(false);
      },
      onError: () => {
        addNotification("error", "Error with sending report");
        setLoading(false);
        setReportModalVisible(false);
      },
    });
  };

  return (
    <Modal
      open={reportModalVisible}
      onClose={() => setReportModalVisible(false)}
    >
      <Box>
        <Text mb={2} variant={"h3"} text={"Report"} />
        {loading ? (
          <LoaderComponent height={200} mt={2} />
        ) : (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {Object.keys(reportsConfig).map((key, index) => (
              <Box
                pb={1}
                key={index}
                sx={{ cursor: "pointer" }}
                borderBottom={`1px solid ${theme.palette.border.input}`}
              >
                <Text
                  textAlign={"center"}
                  onClick={() => report(key)}
                  variant={"body1"}
                  text={reportsConfig[key]}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
