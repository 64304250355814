import SmileIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { Box, ClickAwayListener, IconButton } from "@mui/material";
import useWebSocket from "../../hooks/useWebSocket";
import useUser from "../../hooks/useUser";
import { InputDefault } from "../../components/Forms/Input";

const InboxChatInputButtons = ({ setMessage, handleSend }) => {
  const [visible, setVisible] = useState();
  const handleClick = (obj) => {
    setMessage((prev) => prev + obj.emoji);
  };
  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <Box
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
        }}
      >
        <IconButton onClick={() => setVisible((prev) => !prev)} size="small">
          <SmileIcon />
        </IconButton>
        {visible && (
          <EmojiPicker
            size={55}
            className="position-absolute bottom-100 mb-1 end-0"
            onEmojiClick={handleClick}
          />
        )}
        <IconButton onClick={handleSend} size="small" color={"primary"}>
          <SendIcon />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
};

const InboxChatFooter = ({ receiver, chatRef, setMessages, conversation }) => {
  const { requests } = useWebSocket();
  const { user } = useUser();
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [receiver]);

  const handleSend = () => {
    if (!message) return;
    const msgObj = {
      conversationKey: conversation.conversationKey,
      messageId: "tmp",
      receiverId: conversation.senderId,
      senderId: user.id,
      text: message,
      time: new Date().getTime(),
      seen: 0,
    };
    setMessages((prev) => ({
      count: prev.count + 1,
      items: [...prev.items, msgObj],
    }));
    requests.sendMessage(receiver, message);
    setMessage("");
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        borderTop: "1px solid #e0e0e0",
        justifySelf: "flex-end",
        marginTop: "auto",
      }}
    >
      <Box sx={{ flex: "1", position: "relative" }}>
        <InputDefault
          value={message}
          onPressEnter={handleSend}
          onChange={handleChange}
          sx={{ width: "100%", ".MuiInput-input": { paddingRight: "70px" } }}
          inputRef={inputRef}
          multiline
          maxRows={4}
        />
        <InboxChatInputButtons
          setMessage={setMessage}
          handleSend={handleSend}
        />
      </Box>
    </Box>
  );
};

export default InboxChatFooter;
