import Text from "../../../components/Typography/Text";
import { ButtonRemove } from "../../../components/Buttons/Button";
import { Box, CircularProgress, Icon, useTheme } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import React, { useEffect, useState } from "react";
import { InputHidden } from "../../../components/Forms/TextField";
import s3upload from "../../../services/media/s3upload";
import useApi from "../../../hooks/useApi";
import useUser from "../../../hooks/useUser";
import Video from "../../../old_components/Browse/Thumbnail/Video/Video";
import Modal from "@mui/material/Modal";
import ImageCrop from "../Parts/ImageCrop";
import { Image as ThumbnailImage } from "../../../old_components/Browse/Thumbnail/Image";
import { useWebSocket } from "../../../hooks/useWebSocket";
import useConfig from "../../../hooks/useConfig";
import Grid from "@mui/material/Grid2";
import useError from "../../../hooks/useError";
import { TbAlertCircleFilled } from "react-icons/tb";
import Tooltip from "../../../components/Tooltip";
import { InformationIcon } from "../../../pages/account/edit";
import AppConfig from "../../../config/appConfig";
import { useNotification } from "../../../hooks/useNotifications";

const ThumbnailChoose = ({ handleFileChange }) => {
  return (
    <>
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        sx={{
          transform: "translate(-50%)",
        }}
      >
        <Icon sx={{ display: "flex" }} fontSize={"large"} color={"info"}>
          <FileUploadOutlinedIcon />
        </Icon>
      </Box>
      <InputHidden
        type="file"
        accept="video/mp4, video/webm, video/mov, video/avi, video/mkv, video/wmv, image/jpeg, image/png, image/webp"
        onChange={(e) => handleFileChange(e, "thumbnail")}
      />
    </>
  );
};

const Thumbnail = ({ setLoading }) => {
  const { user, handleSetThumbnail } = useUser();

  const { request } = useApi();

  const { handleSetIndexStatusValue } = useError();

  const handleRemoveThumbnail = async () => {
    setLoading(true);
    await request({
      method: "post",
      url: `profile`,
      body: {
        action: "reset_thumbnail",
      },
      onSuccess: () => {
        handleSetIndexStatusValue("pending");
        handleSetThumbnail({ source: null });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    }).finally(() => setLoading(false));
  };

  let formatImage = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

  return (
    <Box
      borderRadius={2}
      position={"absolute"}
      top={0}
      left={0}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
    >
      {formatImage.includes(user.thumbnail.resource_type) ? (
        <>
          <Box position={"absolute"} right={4} top={4} display={"flex"}>
            <ButtonRemove onClick={handleRemoveThumbnail} />
          </Box>
          <ThumbnailImage src={user.thumbnail.source} />
        </>
      ) : (
        <>
          <Box position={"absolute"} right={4} top={4} display={"flex"}>
            <ButtonRemove onClick={handleRemoveThumbnail} />
          </Box>
          <Video src={user.thumbnail.source} autoplay={false} />
        </>
      )}
    </Box>
  );
};

const CropModal = ({ imageBlob, setImageBlob, upload }) => {
  return (
    <Modal open={imageBlob.visible}>
      <Box>
        <ImageCrop
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
          onCrop={async (croppedImageBlob, upload_type) =>
            await upload(croppedImageBlob, upload_type)
          }
        />
      </Box>
    </Modal>
  );
};

const GalleryImage = ({ image, setImages, fetchLoading }) => {
  const { request } = useApi();

  const { galleryError, handleSetIndexStatusValue } = useError();

  const errorObject = galleryError.find((item) => item.id === image.imageId);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const handleRemoveImage = async () => {
    setLoading(true);

    await request({
      method: "delete",
      url: `/profile/gallery/${image.imageId}`,
      onSuccess: () => {
        setImages((prevState) =>
          prevState.filter((i) => i.imageId !== image.imageId),
        );
        handleSetIndexStatusValue("pending");
        setLoading(false);
      },
    });
  };

  return (
    <>
      {errorObject && (
        <Box
          position={"absolute"}
          bottom={0}
          right={0}
          zIndex={2}
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <Tooltip placement="left" title={errorObject.errors}>
            <Box display="flex">
              <TbAlertCircleFilled color={theme.palette.error.main} />
            </Box>
          </Tooltip>
        </Box>
      )}
      {loading || fetchLoading ? (
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Box
          position={"absolute"}
          top={0}
          border={`${errorObject ? `1px solid ${theme.palette.error.main}` : ""}`}
          borderRadius={2}
        >
          <Box position={"absolute"} right={4} top={4} display={"flex"}>
            <ButtonRemove onClick={handleRemoveImage} />
          </Box>
          <img
            style={{ borderRadius: "8px" }}
            width={"100%"}
            src={image.imageLink}
          />
        </Box>
      )}
    </>
  );
};

const GalleryItemChoose = ({ handleFileChange, fetchLoading }) => {
  const [loading, setLoading] = useState(false);

  const imageFormats = AppConfig.imageFormats;
  return (
    <>
      {loading || fetchLoading ? (
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          sx={{
            transform: "translate(-50%)",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box
            position={"absolute"}
            top={"50%"}
            left={"50%"}
            sx={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <Icon sx={{ display: "flex" }}>
              <FileUploadOutlinedIcon color={"info"} />
            </Icon>
          </Box>
          <InputHidden
            type="file"
            accept={imageFormats}
            onChange={(e) => {
              handleFileChange(e, "gallery", setLoading);
            }}
          />
        </>
      )}
    </>
  );
};

const Gallery = ({ handleFileChange, images, setImages }) => {
  const { request } = useApi();

  const { plans } = useConfig();

  const { user } = useUser();

  const [loading, setLoading] = useState(false);

  const maxSize =
    user.plan_id === "free"
      ? plans.free.limits.images
      : plans.premium.limits.images;

  const numberOfPlaceholders = maxSize - images.length;

  useEffect(() => {
    setLoading(true);
    request({
      method: "get",
      url: "/profile/gallery",
      onSuccess: (data) => {
        setImages(data.sort((a, b) => b.createdAt - a.createdAt));
      },
    }).finally(() => setLoading(false));
  }, []);

  return (
    <Grid container spacing={1}>
      {images.map((image, index) => {
        return (
          <Grid size={{ xs: 4, sm: 3 }} key={index}>
            <Box
              borderRadius={2}
              fullWidth
              paddingTop={"100%"}
              position={"relative"}
              backgroundColor={loading ? "#D0D6DE" : "transparent"}
            >
              <GalleryImage
                image={image}
                setImages={setImages}
                fetchLoading={loading}
              />
            </Box>
          </Grid>
        );
      })}
      {[...Array(numberOfPlaceholders)].map((_, index) => (
        <Grid size={{ xs: 4, sm: 3 }} key={index + images.length}>
          <Box
            borderRadius={2}
            fullWidth
            paddingTop={"100%"}
            border={"1px solid #D0D6DE"}
            position={"relative"}
            backgroundColor={loading ? "#D0D6DE" : "transparent"}
          >
            <GalleryItemChoose
              handleFileChange={handleFileChange}
              fetchLoading={loading}
            />
          </Box>
        </Grid>
      ))}
      <Grid size={{ xs: 8, sm: 12 }}>
        <Box
          display={"flex"}
          border={"1px solid #5100b9"}
          borderRadius={2}
          height={"100%"}
          alignItems={"center"}
          p={3}
        >
          <Text text={"Remember, Video is better than photo"} variant={"h4"} />
        </Box>
      </Grid>
    </Grid>
  );
};

const GalleryView = () => {
  const { request } = useApi();

  const imageFormats = AppConfig.imageFormats;

  const videoFormats = AppConfig.videoFormats;

  const imageFileSizeMB = AppConfig.imageFileSizeMB;

  const maxVideoDurationSeconds = AppConfig.maxVideoDurationSeconds;

  const { register, unregister } = useWebSocket();

  const [loading, setLoading] = useState(false);

  const { user, handleSetThumbnail, handleSetAvatar } = useUser();

  const [images, setImages] = useState([]);

  const { addNotification } = useNotification();

  const { thumbnailError, handleSetIndexStatusValue } = useError();

  const [imageBlob, setImageBlob] = useState({
    blob: null,
    visible: false,
  });

  const upload = async (file, upload_type) => {
    if (upload_type === "thumbnail") {
      setLoading(true);
    }
    handleSetIndexStatusValue("pending");
    await request({
      method: "get",
      url: `profile/upload/url?upload_type=${upload_type}&resource_type=${file.type}`,
      onSuccess: async (data) => {
        await s3upload(
          data.url,
          file,
          () => {
            if (upload_type === "thumbnail") {
              setLoading(true);
            }
          },
          () => {
            if (upload_type === "thumbnail") {
              setLoading(true);
            }
          },
        );
      },
    });
  };
  const getVideoDuration = async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = function () {
        reject("Unable to retrieve video duration");
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileChange = async (
    event,
    upload_type,
    setLoadingGalleryItem,
  ) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileType = file.type.split("/")[0];
    const src = URL.createObjectURL(file);

    if (fileType === "video") {
      const videoDuration = await getVideoDuration(file);
      if (!videoFormats.includes(file.type)) {
        addNotification("error", `Video format ${file.type} is not allowed`);
      } else if (videoDuration > maxVideoDurationSeconds) {
        addNotification(
          "error",
          `The video is longer than a ${maxVideoDurationSeconds}s`,
        );
      } else {
        await upload(file, "thumbnail");
      }
    }
    if (fileType === "image") {
      const fileSizeMB = file.size / 1024 / 1024;
      const image = new Image();
      if (fileSizeMB > imageFileSizeMB) {
        addNotification(
          "error",
          `The photo weighs more than ${imageFileSizeMB} MB`,
        );
        return;
      }
      image.onload = function () {
        if (!imageFormats.includes(file.type)) {
          addNotification("error", `Photo format ${file.type} is not allowed`);
        } else if (image.width < 600 || image.height < 600) {
          addNotification("error", `Minimum image size up to 600x600px.`);
        } else {
          setLoadingGalleryItem ? setLoadingGalleryItem(true) : null;
          setImageBlob({
            blob: src,
            visible: true,
            upload_type: upload_type,
          });
        }
      };
      image.src = src;
    }
  };

  useEffect(() => {
    const handleThumbnailUploadCompletedVideo = (data) => {
      handleSetThumbnail({ source: data.url });
      handleSetAvatar(data.preview);
      setLoading(false);
    };

    const handleThumbnailUploadCompletedImage = (data) => {
      handleSetThumbnail({ source: data.url, resource_type: data.type });
      handleSetAvatar(data.preview);
      setLoading(false);
    };

    const handleGalleryUploadCompletedImage = (data) => {
      setImages((prevState) => {
        if (prevState.some((img) => img.imageId === data.imageId)) {
          return prevState;
        }
        return [{ imageLink: data.url, imageId: data.imageId }, ...prevState];
      });
      setLoading(false);
    };

    register(
      "thumbnail_upload_completed_video",
      handleThumbnailUploadCompletedVideo,
    );
    register(
      "thumbnail_upload_completed_image",
      handleThumbnailUploadCompletedImage,
    );
    register(
      "gallery_upload_completed_image",
      handleGalleryUploadCompletedImage,
    );

    return () => {
      unregister(
        "thumbnail_upload_completed_video",
        handleThumbnailUploadCompletedVideo,
      );
      unregister(
        "thumbnail_upload_completed_image",
        handleThumbnailUploadCompletedImage,
      );
      unregister(
        "gallery_upload_completed_image",
        handleGalleryUploadCompletedImage,
      );
    };
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", sm: "row" }}
      gap={1}
      mb={{ xs: 0, sm: 2 }}
    >
      <Box width={{ xs: "100%", sm: "30%" }}>
        <Box
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "none" } }}
          mb={1}
        >
          <Text text={"Add main video or photo*"} variant={"h3"} />
          <InformationIcon
            title={"Gallery"}
            text={
              "To complete your profile, please upload at least one main video or photo. This will be the first thing potential clients see, so choose a high-quality media that best represents your skills or services. You can upload additional images or videos later, depending on your subscription plan."
            }
          />
        </Box>
        <Box
          mb={{ xs: 2, sm: 0 }}
          borderRadius={2}
          paddingTop={"100%"}
          fullWidth
          border={user.thumbnail.source ? "" : "1px solid #D0D6DE"}
          position={"relative"}
          backgroundColor={loading ? "#D0D6DE" : "transparent"}
        >
          {loading ? (
            <>
              <Box
                position={"absolute"}
                top={"50%"}
                left={"50%"}
                sx={{
                  transform: "translate(-50%)",
                }}
              >
                <CircularProgress size={40} />
              </Box>
            </>
          ) : (
            <>
              {thumbnailError.errors && (
                <Box position={"absolute"} right={0} bottom={0} zIndex={2}>
                  <Tooltip
                    placement="left"
                    title={
                      thumbnailError.errors[0].message || thumbnailError.errors
                    }
                  >
                    <Box
                      display="flex"
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        height: "100%",
                        pr: 1,
                        pb: 1,
                      }}
                    >
                      <TbAlertCircleFilled color={"red"} />
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {user.thumbnail.source ? (
                <Thumbnail setLoading={setLoading} />
              ) : (
                <ThumbnailChoose handleFileChange={handleFileChange} />
              )}
            </>
          )}
        </Box>
      </Box>
      <Box width={{ xs: "100%", sm: "70%" }}>
        <Text
          text={"Add photos to gallery"}
          mb={1}
          variant={"h3"}
          sx={{ display: { xs: "block", sm: "none" } }}
        />
        <Box mt={{ xs: 1, sm: 0 }}>
          <Gallery
            images={images}
            setImages={setImages}
            handleFileChange={handleFileChange}
          />
        </Box>
      </Box>
      <CropModal
        imageBlob={imageBlob}
        setImageBlob={setImageBlob}
        upload={upload}
      />
    </Box>
  );
};

export default GalleryView;
