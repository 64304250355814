import { PageHeader } from "../../views/Page";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountTypeView from "../../views/Account/Settings/AccountTypeView";
import SecurityView from "../../views/Account/Settings/SecurityView";
import HiddenProfilesView from "../../views/Account/Settings/HiddenProfilesView";
import LogOutView from "../../views/Account/Settings/LogOutView";
import Tabs from "../../components/Page/Tabs";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import PlansView from "../../views/Account/Settings/PlansView";
import BillingView from "../../views/Account/Settings/BillingView";
import { PaymentProvider } from "../../hooks/usePayment";

const Settings = () => {
  const { t } = useTranslation();

  const configurationTabs = [
    {
      key: "account-type",
      section_id: "account-type",
      title: "Account Type",
      components: <AccountTypeView />,
    },
    {
      key: "security",
      section_id: "security",
      title: "Security",
      components: <SecurityView />,
    },
    {
      key: "reset",
      section_id: "reset",
      title: "Hidden profiles",
      components: <HiddenProfilesView />,
    },
    {
      key: "plans",
      section_id: "plans",
      title: "Plans",
      components: <PlansView />,
    },
    {
      key: "billing",
      section_id: "billing",
      title: "Billing",
      components: <BillingView />,
    },
    {
      key: "logout",
      section_id: "logout",
      title: t(
        "profile.profile.tabs.settingsTab.index.configuration.logout.title",
      ),
      components: <LogOutView />,
    },
  ];

  const [searchParams] = useSearchParams();

  return (
    <Box mb={5}>
      <PaymentProvider>
        <Box mb={2}>
          <PageHeader
            text={"Profile"}
            modalText={
              " In the Settings section, you can manage important aspects of your account. Here, you can update your account type, adjust your security settings (change password), manage hidden profiles, choose your subscription plan, and handle billing details. Keep your settings up to date to ensure your profile works exactly the way you want it to."
            }
            modalTitle={"Settings"}
            tabPercent={
              ((+searchParams.get("tab-index") + 1) * 100) /
                configurationTabs.length -
              1
            }
          />
        </Box>
        <Tabs tabs={configurationTabs} syncWithUrl={true} />
      </PaymentProvider>
    </Box>
  );
};

export default Settings;
