import React from "react";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Offline = ({ Message, Refresh }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        transition: "all 200ms ease-in-out",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: "#fff",
        justifyContent: "center",
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 120 }} />
      <Typography variant="h3">Error</Typography>
      <p>{Message}</p>
      <ButtonPrimary variant={"outlined"} onClick={Refresh} color={"white"}>
        {t("main.offline.tryAgain")}
      </ButtonPrimary>
    </Box>
  );
};

export { Offline };
