import { styled } from "@mui/material";
import TextFieldMui from "@mui/material/TextField";
import React from "react";
import { ReactComponent as Hide } from "../../assets/svg/home/hide.svg";
import { ReactComponent as Show } from "../../assets/svg/home/show.svg";

const TextFieldStyled = styled(TextFieldMui)(({ theme, ...props }) => ({
  "& .MuiOutlinedInput-root": {
    height: props.multiline ? "auto" : "40px",
    borderRadius: "30px",
    "&.Mui-disabled": {
      backgroundColor: "#f5f5f5",
    },
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px!important",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: props.multiline ? 0 : "0 0 0 14px",
    minHeight: "40px",
    fontSize: "13px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    transform: "translate(14px, 11px) scale(1)",
    color: "#AEAEAE",
  },
  "& .MuiInputLabel-shrink": {
    color: "#AEAEAE",
    transform: "translate(14px, -9px) scale(0.77)",
  },
  "& .Mui-disabled": {
    backgroundColor: "transparent",
    borderRadius: "30px",
  },
}));

const TextField = ({
  label,
  variant,
  onEnter,
  tooltip = null,
  sx,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && onEnter) {
      onEnter(event.target.value);
      event.preventDefault();
    }
  };
  return (
    <>
      <TextFieldStyled
        label={label}
        variant={variant}
        onKeyDown={handleKeyDown}
        sx={sx}
        {...props}
      />
    </>
  );
};

export const TogglePassword = ({ passwordVisible, onToggle }) => {
  return (
    <span tabIndex={-1} onClick={onToggle}>
      {passwordVisible ? <Hide /> : <Show />}
    </span>
  );
};

const InputHiddenStyled = styled("input")({
  opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  cursor: "pointer",
});

export const InputHidden = ({ ...props }) => {
  return <InputHiddenStyled {...props} />;
};

export default TextField;
