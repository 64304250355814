import { Formik } from "formik";
import TextField, { TogglePassword } from "../../components/Forms/TextField";
import { Box } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";
import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import { signIn } from "aws-amplify/auth";
import useUser from "../../hooks/useUser";
import { useNotification } from "../../hooks/useNotifications";
import useQueryString from "../../hooks/useQueryString";
import authLoginSchema from "../../validations/auth/authLoginSchema";
import * as Yup from "yup";
import Tooltip from "../../components/Tooltip";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import AuthSocialMediaView from "./AuthSocialMediaView";

const AuthLoginView = ({ setPopupActive }) => {
  const [loading, setLoading] = useState(false);

  const { handleRefresh } = useUser();

  const { addNotification } = useNotification();

  const [error, setError] = useState(null);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const { removeQuery, addQuery } = useQueryString();

  const initialData = {
    email: "",
    password: "",
  };

  const singIn = async (values) => {
    setError(null);
    setLoading(true);
    try {
      const { isSignedIn } = await signIn({
        username: values.email,
        password: values.password,
      });

      if (isSignedIn) {
        setLoading(false);

        try {
          await handleRefresh();

          addNotification("success", "Login successful");

          removeQuery("auth-popup");
        } catch (err) {
          console.log("getCurrentUser error: ", err);
        }
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={singIn}
        initialValues={initialData}
        validationSchema={Yup.object().shape(authLoginSchema())}
      >
        {({ handleSubmit, values, handleBlur, handleChange, errors }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={2} display={"flex"} flexDirection={"column"} gap={1.5}>
              <TextField
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"email"}
                label={"E-mail"}
                fullWidth
                error={errors.email}
                slotProps={{
                  input: {
                    endAdornment: (
                      <>
                        {errors.email && (
                          <Tooltip arrow placement="left" title={errors.email}>
                            <FmdBadIcon color={"error"} />
                          </Tooltip>
                        )}
                      </>
                    ),
                  },
                }}
              />
              <TextField
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"password"}
                label={"Password"}
                fullWidth
                error={errors.password}
                type={passwordVisible ? "text" : "password"}
                slotProps={{
                  input: {
                    endAdornment: (
                      <>
                        {errors.password ? (
                          <Tooltip
                            arrow
                            placement="left"
                            title={errors.password}
                          >
                            <FmdBadIcon color={"error"} />
                          </Tooltip>
                        ) : (
                          <TogglePassword
                            passwordVisible={passwordVisible}
                            onToggle={() =>
                              setPasswordVisible((prevState) => !prevState)
                            }
                          />
                        )}
                      </>
                    ),
                  },
                }}
              />
              <ButtonPrimary loading={loading} type={"submit"}>
                Sign in
              </ButtonPrimary>
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Text
                onClick={() => setPopupActive("forgot")}
                text={"Forgot your password?"}
                sx={{ fontSize: 11, textTransform: "unset" }}
                variant={"overline"}
              />
            </Box>
            <Text text={error} variant={"body2"} color={"error"} />
          </form>
        )}
      </Formik>
      <AuthSocialMediaView />
    </>
  );
};

export default AuthLoginView;
