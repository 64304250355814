import TextField from "../../../components/Forms/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { Box, InputAdornment, useMediaQuery, useTheme } from "@mui/material";
import { debounce } from "lodash";
import Slider from "../../../components/Sliders/Slider";
import ButtonPrimary, {
  ButtonCircle,
} from "../../../components/Buttons/Button";
import { useFormikContext } from "formik";
import useConfig from "../../../hooks/useConfig";
import {
  AutocompleteProfile,
  DisplayItem,
  DisplayItems,
} from "../../../components/Autocompletes/AutocompleteProfile";
import ToggleSwitch from "../../../components/Forms/Toggle";
import useUser from "../../../hooks/useUser";
import useLoadGoogleMaps from "../../../hooks/useLoadGoogleMaps";
import AutocompleteLocation from "../../../components/Autocompletes/AutocompleteLocation";
import Tooltip from "../../../components/Tooltip";
import { TbAlertCircleFilled } from "react-icons/tb";
import Text from "../../../components/Typography/Text";
import GalleryView from "./GalleryView";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import usePlan from "../../../hooks/usePlan";
import useError from "../../../hooks/useError";
import { InformationIcon } from "../../../pages/account/edit";

const CommunicationWays = () => {
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext();

  const { user } = useUser();

  const configuration = [
    {
      key: "email",
      label: "Email",
      value: values.contact_methods.email || user.email,
      visible: values.contact_methods.email !== null,
    },
    {
      key: "phone",
      label: "Phone",
      value: values.contact_methods.phone,
      visible: values.contact_methods.phone !== null,
    },
    {
      key: "direct",
      label: "DIRECT MESSAGE ON CHAT",
      value: "",
      visible: values.contact_methods.direct || false,
    },
  ];

  const handleToggleChange = (key) => (e) => {
    setFieldValue(`contact_methods.${key}`, e.target.checked ? "" : null);
  };

  return (
    <Grid container spacing={2} mt={2}>
      {configuration.map((item, index) => (
        <Grid size={{ xs: 12, md: 4 }} position={"relative"} key={index}>
          <TextField
            value={item.value}
            name={item.key}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            disabled={!item.visible}
            label={item.label}
          />
          <Box
            position={"absolute"}
            top={"50%"}
            right={0}
            sx={{ transform: "translateY(-50%)" }}
          >
            <ToggleSwitch
              checked={item.visible}
              onChange={handleToggleChange(item.key)}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
const autocompleteService = { current: null };
const placesService = { current: null };
const Location = () => {
  const [location, setLocation] = useState(null);

  const [options, setOptions] = useState([]);

  const [value, setValue] = useState(null);

  const [inputValue, setInputValue] = useState("");

  const { values, setFieldValue } = useFormikContext();

  const { isLoaded, error } = useLoadGoogleMaps();

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    [],
  );

  useEffect(() => {
    if (!isLoaded || error) return;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = value ? [value] : [];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, isLoaded, error]);

  const handleSelect = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);

    if (newValue && window.google) {
      if (!placesService.current) {
        placesService.current = new window.google.maps.places.PlacesService(
          document.createElement("div"),
        );
      }

      placesService.current.getDetails(
        { placeId: newValue.place_id },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setLocation({
              name: newValue.description,
              radius: 25,
              lat: place.geometry.location.lat(),
              lon: place.geometry.location.lng(),
            });
          }
        },
      );
    }
  };

  const handleAddLocation = () => {
    setFieldValue(
      "locations",
      values.locations ? [...values.locations, location] : [location],
    );
    setValue(null);
    setLocation(null);
  };

  return (
    <>
      <AutocompleteLocation
        handleSelect={handleSelect}
        options={options}
        setInputValue={setInputValue}
        value={value}
      />
      {location && inputValue && (
        <>
          <Box mt={5}>
            <Slider
              defaultValue={25}
              onChange={(e) =>
                setLocation((prevState) => ({
                  ...prevState,
                  radius: e.target.value,
                }))
              }
            />
          </Box>
          <ButtonPrimary
            size={"md"}
            fullWidth
            onClick={handleAddLocation}
            variant={"contained"}
          >
            Add location
          </ButtonPrimary>
        </>
      )}
      {values.locations.length !== 0 && (
        <DisplayItems mt={2} name={"Your locations"}>
          {values.locations.map((location, index) => (
            <React.Fragment key={index}>
              <DisplayItem
                text={`${location.name} + ${location.radius} miles`}
                onRemove={() =>
                  setFieldValue(
                    "locations",
                    values.locations.filter(
                      (el) =>
                        el.lon !== location.lon && el.lat !== location.lat,
                    ),
                  )
                }
              />
            </React.Fragment>
          ))}
        </DisplayItems>
      )}
    </>
  );
};

const SocialMedia = () => {
  const { values, setFieldValue } = useFormikContext();

  const [socialMedia, setSocialMedia] = useState("");

  const { isProfileLimitReached } = usePlan();

  const theme = useTheme();

  const [error, setError] = useState();

  const addSocialMedia = () => {
    if (
      socialMedia.trim() === "" ||
      values.social.find((element) => element.trim() === socialMedia.trim())
    ) {
      setError("Social link required");
      return;
    } else if (values.social.length >= 5) {
      setError("You reached limit");
      return;
    }
    setFieldValue(
      "social",
      values.social.concat(`https://${socialMedia.toLowerCase()}`),
    );
    setSocialMedia("");
    setError(null);
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/^https?:\/\//, "");
    setSocialMedia(value);
  };

  return (
    <>
      <Box display={"flex"} gap={1}>
        <TextField
          label={"Links to social media profiles"}
          onChange={handleChange}
          fullWidth
          disabled={isProfileLimitReached("social", "social_media")}
          error={error}
          value={socialMedia}
          onEnter={(value) => {
            if (value.trim() !== "") {
              addSocialMedia();
            }
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">https://</InputAdornment>
              ),
              endAdornment: (
                <>
                  {isProfileLimitReached("social", "social_media") &&
                    !error && (
                      <Tooltip placement="left" title={"You reached limit"}>
                        <Box display="flex">
                          <LockOutlinedIcon color="primary" />
                        </Box>
                      </Tooltip>
                    )}
                  {error && (
                    <Tooltip placement="left" title={error}>
                      <Box display="flex">
                        <TbAlertCircleFilled color={theme.palette.error.main} />
                      </Box>
                    </Tooltip>
                  )}
                </>
              ),
            },
          }}
        />
        <ButtonCircle
          disabled={isProfileLimitReached("social", "social_media")}
          variant={"contained"}
          onClick={addSocialMedia}
        >
          <AddIcon />
        </ButtonCircle>
      </Box>
      {values.social && values.social.length !== 0 && (
        <DisplayItems name={"Social media"} mt={2}>
          {values.social.map((el, index) => (
            <React.Fragment key={index}>
              <DisplayItem
                text={el}
                onRemove={() => {
                  setFieldValue(
                    "social",
                    values.social.filter((_, i) => i !== index),
                  );
                }}
              />
            </React.Fragment>
          ))}
        </DisplayItems>
      )}
    </>
  );
};

const AboutMeView = () => {
  const { config } = useConfig();
  const { setFieldValue, errors, values, handleChange, handleBlur } =
    useFormikContext();

  const { detailsError } = useError();

  const { isProfileLimitReached } = usePlan();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tagsOptions = () => {
    if (values.categories) {
      let abc = [];
      values.categories.forEach((categorySlug) => {
        const test = config.categories.items.find(
          (cat) => cat.slug === categorySlug,
        );
        abc.push(test.tags);
      });
      return abc.flat();
    }
    return [];
  };

  return (
    <>
      {!isMobile && <GalleryView />}
      <Box display={"flex"} justifyContent={"space-between"} mb={1}>
        <Text text={"Basic data*"} variant={"h3"} />
        <Text text={"*required fields"} variant={"h5"} />
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label={"Name"}
          value={values.name}
          error={errors.name || detailsError.name}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {(errors.name || detailsError.name) && (
                    <Tooltip
                      placement="left"
                      title={errors.name || detailsError.name[0].errors}
                    >
                      <Box display="flex">
                        <TbAlertCircleFilled color={theme.palette.error.main} />
                      </Box>
                    </Tooltip>
                  )}
                </>
              ),
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          name={"name"}
        />
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label={"Description visible on the profile page"}
          multiline
          minRows={4}
          value={values.about}
          error={errors.about || detailsError.about}
          sx={{ borderRadius: 2 }}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {(errors.about || detailsError.about) && (
                    <Tooltip
                      placement="left"
                      title={errors.about || detailsError.about[0].errors}
                    >
                      <Box display="flex">
                        <TbAlertCircleFilled color={theme.palette.error.main} />
                      </Box>
                    </Tooltip>
                  )}
                </>
              ),
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          name={"about"}
        />
      </Box>
      <Box mb={2}>
        <SocialMedia />
      </Box>
      <Box mb={2}>
        <AutocompleteProfile
          title={"Categories*"}
          disabled={isProfileLimitReached("categories", "categories")}
          limitReached={isProfileLimitReached("categories", "categories")}
          limitText={"You reached limit"}
          options={config["categories"].items}
          getOptionLabel={(option) => option.title}
          error={errors.categories}
          value={config.categories.items.filter((el) =>
            values.categories.includes(el.slug),
          )}
          onChange={(event, newValue) => {
            setFieldValue(
              "categories",
              newValue.map((item) => item.title.toLowerCase()),
            );
          }}
          name={"categories"}
        />
      </Box>
      <Box mb={2}>
        <AutocompleteProfile
          disabled={isProfileLimitReached("tags", "tags")}
          limitReached={isProfileLimitReached("tags", "tags")}
          limitText={"You reached limit"}
          title={"Tags*"}
          options={tagsOptions()}
          getOptionLabel={(option) => option}
          value={values.tags}
          limit={3}
          ownValue={true}
          chipError={detailsError.tags}
          onChange={(event, newValue) => {
            setFieldValue(
              "tags",
              newValue.map((item) => item),
            );
          }}
          name={"tags"}
        />
      </Box>
      <Box mb={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text text={"Contact info*"} variant={"h3"} />
          <InformationIcon
            title={"Contact info"}
            text={
              "To activate your profile, you must select at least one contact method. You can choose from email, phone, or direct chat. Providing multiple contact options makes it easier for clients to reach you, increasing your chances of getting hired. You can disable or update any contact method at any time in your profile settings."
            }
          />
        </Box>
        <CommunicationWays />
      </Box>
      <Box>
        <Box
          mb={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text text={"Location"} variant={"h3"} />
          <InformationIcon
            title={"Location"}
            text={
              "To ensure clients can find your services, please set your location. This will help users in your area discover your profile. You can adjust your location and service radius anytime. Keep in mind that a more accurate location increases your visibility and the chances of getting local opportunities."
            }
          />
        </Box>
        <Location />
      </Box>
    </>
  );
};

export default AboutMeView;
