import React from "react";
import { Box, useTheme } from "@mui/material";

export const FooterPortalMobile = ({ isScrolled }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        justifyContent: "center",
        bottom: 0,
        transition: "all 200ms ease-in-out",
        transform: isScrolled ? "translateY(-72px)" : "translateY(0)",
      }}
      id={"app-footer-portal"}
    />
  );
};

export const FooterPortalDesktop = ({ isScrolled }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        bottom: 0,
        transition: "all 200ms ease-in-out",
        position: "fixed",
        zIndex: 9,
        right: {
          xs: 0,
          xl: `calc((100vw - ${theme.breakpoints.values.xl}px)/2)`,
        },
      }}
      id={"app-footer-portal"}
    />
  );
};
