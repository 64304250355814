import { useInstantSearch } from "react-instantsearch";
import useUser from "./useUser";
import { useEffect } from "react";

const useBrowse = () => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const searchParams = new URLSearchParams(window.location.search);
  const { geo, handleSetGeo } = useUser();

  useEffect(() => {
    if ("configure" in indexUiState) {
      if ("aroundLatLng" in indexUiState.configure) {
        handleSetGeo({
          selected: true,
          // Get the place from the query string
          name: searchParams.get("place"),
          // Get the lat and lng from the configure state
          lat: indexUiState.configure.aroundLatLng.split(",")[0],
          lng: indexUiState.configure.aroundLatLng.split(",")[1].trim(),
          radius: indexUiState.configure.aroundRadius / 1000,
        });
      }
    }
  }, []);

  const resetGeo = (onResetComplete = () => {}) => {
    handleSetGeo({
      radius: 50,
      lat: false,
      lng: false,
      name: "",
      selected: false,
    });
    onResetComplete();
  };

  const resetIndex = () => {
    setIndexUiState({
      configure: {
        filters: "",
      },
    });
    resetGeo();
  };

  return {
    geo,
    handleSetGeo,
    resetGeo,
    resetIndex,
  };
};

export default useBrowse;
