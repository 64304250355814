import Text from "../../../components/Typography/Text";
import React, { useState } from "react";
import { Box } from "@mui/material";
import ButtonPrimary from "../../../components/Buttons/Button";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modals/Modal";
import LogoutIcon from "@mui/icons-material/Logout";
import DevicesIcon from "@mui/icons-material/Devices";

const LogOutView = () => {
  const { handleLogout, handleGlobalLogout } = useUser();

  const [logoutAllModal, setLogoutAllModal] = useState(false);

  return (
    <>
      <Text text={"Logout"} variant={"h3"} mb={2} />
      <Box display="flex" flexDirection="column" gap={1}>
        <ButtonPrimary
          onClick={handleLogout}
          variant={"contained"}
          startIcon={<LogoutIcon />}
        >
          Logout from this device
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => setLogoutAllModal(true)}
          variant={"contained"}
          startIcon={<DevicesIcon />}
        >
          Log out from all devices
        </ButtonPrimary>
        <Modal open={logoutAllModal} onClose={() => setLogoutAllModal(false)}>
          <Text text={"Log out from all devices"} variant={"h3"} />
          <Text text={"Are you sure ;)"} mt={3} mb={3} variant={"body1"} />
          <Box display="flex" flexDirection="row" gap={1}>
            <ButtonPrimary
              fullWidth
              color={"info"}
              onClick={() => setLogoutAllModal(false)}
            >
              Cancel
            </ButtonPrimary>
            <ButtonPrimary
              onClick={handleGlobalLogout}
              variant={"contained"}
              fullWidth
            >
              Ok
            </ButtonPrimary>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default LogOutView;
