import { useEffect, useRef } from "react";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

export const api = (config = {}, headers = {}, signalRef = null) => {
  /**
   *
   * Create Axios instance
   *
   */

  const getInitialHeaders = ({ token }) => {
    return {
      "Content-Type": "application/json",
      Authorization: token,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    };
  };

  /**
   *
   * fetchData
   *
   */

  const request = async ({
    url,
    method = "get",
    body = null,
    onError = () => {},
    onSuccess = () => {},
  }) => {
    let accessToken = "";

    try {
      const { tokens } = await fetchAuthSession();
      accessToken = tokens.accessToken;
    } catch (err) {
      console.log("axios error: ", err);
    }

    const initialHeaders = getInitialHeaders({ token: accessToken });

    const initialConfig = {
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_API_HOST,
      timeout: 15000,
      headers: { ...initialHeaders, ...headers },
      signal: signalRef ? signalRef.current.signal : null,
    };

    const instance = axios.create({ ...initialConfig, ...config });

    try {
      const res = await instance[method](url, body);

      onSuccess(res.data);

      return res.data;
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        onError(err);
      }
    }
  };

  return { request };
};

const useApi = (config = {}, headers = {}) => {
  const ControllerRef = useRef(new AbortController());

  const cancel = () => {
    ControllerRef.current.abort("Request cancel by user");

    ControllerRef.current = new AbortController();
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  const apiClient = api({}, {}, ControllerRef);

  return {
    request: apiClient.request,
  };
};

export default useApi;
