import { Formik } from "formik";
import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import { Box } from "@mui/material";
import TextField, { TogglePassword } from "../../components/Forms/TextField";
import ButtonPrimary from "../../components/Buttons/Button";
import Tooltip from "../../components/Tooltip";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { signUp } from "aws-amplify/auth";
import authRegisterSchema from "../../validations/auth/authRegisterSchema";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import SocialMediaAuth from "./AuthSocialMediaView";

const AuthRegisterView = ({ setPopupActive }) => {
  const { t } = useTranslation();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const initialData = {
    name: "",
    email: "",
    password: "",
  };

  const singUp = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const { isSignUpComplete, nextStep } = await signUp({
        username: values.email,
        password: values.password,
        options: {
          userAttributes: {
            name: values.name,
          },
          autoSignIn: true,
        },
      });

      localStorage.setItem("email", values.email);

      setLoading(false);

      if (!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        setPopupActive("verify");
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <>
      <Formik
        onSubmit={singUp}
        initialValues={initialData}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={Yup.object().shape(authRegisterSchema(t))}
      >
        {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={2} display={"flex"} flexDirection={"column"} gap={1.5}>
              <TextField
                label={"Username"}
                name={"name"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
                slotProps={{
                  input: {
                    endAdornment: (
                      <>
                        {errors.name && (
                          <Tooltip arrow placement="left" title={errors.name}>
                            <FmdBadIcon color={"error"} />
                          </Tooltip>
                        )}
                      </>
                    ),
                  },
                }}
              />
              <TextField
                label={"E-mail"}
                name={"email"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email}
                autoComplete="username"
                slotProps={{
                  input: {
                    endAdornment: (
                      <>
                        {errors.email && (
                          <Tooltip arrow placement="left" title={errors.email}>
                            <FmdBadIcon color={"error"} />
                          </Tooltip>
                        )}
                      </>
                    ),
                  },
                }}
              />
              <TextField
                label={"Password"}
                name={"password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password}
                type={passwordVisible ? "text" : "password"}
                slotProps={{
                  input: {
                    endAdornment: (
                      <>
                        {errors.password ? (
                          <Tooltip
                            arrow
                            placement="left"
                            title={errors.password}
                          >
                            <FmdBadIcon color={"error"} />
                          </Tooltip>
                        ) : (
                          <TogglePassword
                            passwordVisible={passwordVisible}
                            onToggle={() =>
                              setPasswordVisible((prevState) => !prevState)
                            }
                          />
                        )}
                      </>
                    ),
                  },
                }}
              />
              <ButtonPrimary loading={loading} type={"submit"}>
                Join
              </ButtonPrimary>
            </Box>
            <Text text={error} variant={"body2"} color={"error"} />
          </form>
        )}
      </Formik>
      <SocialMediaAuth />
    </>
  );
};

export default AuthRegisterView;
