import InboxChatContent from "./InboxChatContent";
import InboxChatFooter from "./InboxChatFooter";
import { useEffect, useRef, useState } from "react";
import { useWebSocket } from "../../hooks/useWebSocket";
import InboxChatHeader from "./InboxChatHeader";
import { Box } from "@mui/material";

const initialMessagesState = {
  count: 0,
  items: [],
  last: null,
  pristine: true,
  state: "loading",
};

const InboxChat = ({ receiver = null }) => {
  const chatRef = useRef(null);
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState(initialMessagesState);
  const { register, unregister, connected, requests } = useWebSocket();

  // Set receiver from url
  useEffect(() => {
    setMessages(initialMessagesState);
    setConversation(null);
    if (receiver && connected) {
      requests.getConversation(receiver);
      requests.getConversationHistory(receiver);
    }
  }, [receiver, connected]);

  // register and unregister websocket events
  useEffect(() => {
    const handleConversation = (response) => {
      setConversation(response.data);
    };
    const handleHistory = (response) => {
      setMessages((prev) => ({
        count: prev.count + response.data.count,
        items: [...response.data.items, ...prev.items],
        last: response.data.last,
        pristine: false,
        state: "loaded",
      }));
    };
    const handleNewMessage = (response) => {
      if (response.data.conversationKey !== conversation.conversationKey)
        return;
      setMessages((prev) => {
        const isDuplicate = prev.items.some(
          (item) =>
            item.messageId === response.data.messageId ||
            (item.messageId === "tmp" &&
              item.text === response.data.text &&
              item.senderId === response.data.senderId),
        );

        const updatedItems = prev.items.map((item) =>
          item.messageId === "tmp" &&
          item.text === response.data.text &&
          item.senderId === response.data.senderId
            ? response.data
            : item,
        );

        return {
          ...prev,
          count: prev.count + 1,
          items: isDuplicate ? updatedItems : [...updatedItems, response.data],
        };
      });
    };
    register("history", handleHistory);
    register("conversation", handleConversation);
    register("newMessage", handleNewMessage);
    return () => {
      unregister("conversation", handleConversation);
      unregister("history", handleHistory);
      unregister("newMessage", handleNewMessage);
    };
  }, [receiver, conversation, connected]);

  if (!receiver) {
    return;
  }

  return (
    <Box
      sx={{
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxShadow: "0 10px 50px 10px rgba(150, 138, 186, 0.2)",
      }}
    >
      <InboxChatHeader conversation={conversation} />
      <InboxChatContent
        receiver={receiver}
        messages={messages}
        chatRef={chatRef}
      />
      <InboxChatFooter
        receiver={receiver}
        chatRef={chatRef}
        conversation={conversation}
        setMessages={setMessages}
      />
    </Box>
  );
};

export default InboxChat;
