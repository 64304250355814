import { useLocation, useNavigate } from "react-router";

export default function useQueryString() {
  const location = useLocation();
  const navigate = useNavigate();

  const updateQuery = (params) => {
    let searchParams = new URLSearchParams();

    let pathname = location.pathname;

    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        if (Array.isArray(params[key])) {
          params[key].forEach((item) => {
            searchParams.append(`${key}`, item);
          });
        } else {
          searchParams.append(key, params[key]);
        }
      }
    }

    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const addQuery = (key, value) => {
    let pathname = location.pathname;

    if (value === "") {
      removeQuery(key);
    } else {
      let searchParams = new URLSearchParams(location.search);

      searchParams.set(key, value);

      navigate({
        pathname: pathname,
        search: searchParams.toString(),
      });
    }
  };

  const removeQuery = (key) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const clearQuery = () => {
    navigate(window.location.pathname, { replace: true });
  };

  return {
    updateQuery,
    addQuery,
    removeQuery,
    clearQuery,
  };
}
