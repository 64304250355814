import { Box, Typography } from "@mui/material";
import { formatDate } from "../../utils/appUtils";

export const Message = ({ text, isWriter, sending = false, date }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: isWriter ? "row-reverse" : "row",
        justifyContent: isWriter ? "flex-start" : "flex-start",
        "&:hover > .date, &:focus-within > .date": {
          display: "block",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: 6,
          paddingX: 2,
          paddingY: 1,
          margin: 1,
          backgroundColor: isWriter ? "primary.main" : "info.main",
          opacity: sending ? 0.5 : 1,
          width: "fit-content",
        }}
      >
        <Typography fontSize={13} sx={{ color: isWriter ? "#fff" : "#000" }}>
          {text}
        </Typography>
      </Box>
      <Box className={"date"} sx={{ display: "none" }}>
        <Typography fontSize={11}>{formatDate(new Date(date))}</Typography>
      </Box>
    </Box>
  );
};
