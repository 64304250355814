import React, { useRef } from "react";
import {
  useConfigure,
  useGeoSearch,
  useInstantSearch,
  useRefinementList,
  useToggleRefinement,
} from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { Badge, Button, Form, ListGroup } from "react-bootstrap";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import useConfig from "../../../hooks/useConfig";
import SimpleBar from "simplebar-react";
import useUser from "../../../hooks/useUser";
import { Box, Switch, Typography } from "@mui/material";
import ToggleSwitch from "../../../components/Forms/Toggle";

/*
 *
 * VirtualRefinementList. Algolia will not render the component, but will use it to manage the state of the refinement list.
 *
 */
export function VirtualRefinementList(props) {
  useRefinementList(props);
  return null;
}

export function VirtualGeoSearch(props) {
  useGeoSearch(props);
  return null;
}
export const VirtualConfigure = (props) => {
  useConfigure(props);
  return null;
};
const CustomRefinementListItem = ({
  item,
  attribute,
  filters,
  handleChange,
}) => {
  const isChecked = !!(
    attribute in filters.refinementList &&
    filters.refinementList[attribute].includes(`${item.value}`)
  );

  return (
    <div className="filter-checkbox" onClick={() => handleChange(item.value)}>
      <div
        className={`filter-checkbox-icon ${isChecked ? "checked" : ""}`}
      ></div>
      <span>{item.label}</span>
      <Badge bg={""}>{item.count}</Badge>
    </div>
  );
};

export const CustomRefinementList = (props) => {
  const { attribute, title, filters, setFilters } = props;

  const { items } = useRefinementList({
    attribute:
      props.attribute === "languages.language" ? "languages" : props.attribute,
  });

  const { config } = useConfig();

  const handleChange = (val) => {
    setFilters((prev) => {
      if (!prev.refinementList[attribute]) {
        return {
          ...prev,
          refinementList: {
            ...prev.refinementList,
            [attribute]: [val],
          },
        };
      }

      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          [attribute]: prev.refinementList[attribute].includes(val)
            ? prev.refinementList[attribute].filter((item) => item !== val)
            : [...prev.refinementList[attribute], val],
        },
      };
    });
  };

  const allItems = config[attribute].items;

  const mapped = allItems.map((item) => {
    const algoliaItem = items.find((i) => i.value === `${item.key}`);

    if (algoliaItem) {
      return { ...algoliaItem, label: item.value };
    }

    return {
      count: 0,
      value: item.key,
      label: item.value,
      isRefined: false,
    };
  });

  return (
    <div className={"filters-group"}>
      {title && <div className={"title"}>{title}</div>}
      <SimpleBar style={{ maxHeight: "300px" }}>
        {mapped.map((item, index) => (
          <CustomRefinementListItem
            key={index}
            item={item}
            attribute={attribute}
            filters={filters}
            handleChange={handleChange}
          />
        ))}
      </SimpleBar>
    </div>
  );
};

export const FiltersLanguage = (props) => {
  const {
    attribute = "languages.language",
    title,
    filters,
    setFilters,
  } = props;

  const { items } = useRefinementList({ attribute: attribute, limit: 1000 });

  const { config } = useConfig();

  const handleChange = (val) => {
    setFilters((prev) => {
      if (!prev.refinementList[attribute]) {
        return {
          ...prev,
          refinementList: {
            ...prev.refinementList,
            [attribute]: [val],
          },
        };
      }

      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          [attribute]: prev.refinementList[attribute].includes(val)
            ? prev.refinementList[attribute].filter((item) => item !== val)
            : [...prev.refinementList[attribute], val],
        },
      };
    });
  };

  const configItems = config["languages"].items;

  const mapped = items.map((item) => {
    const configItem = configItems.find((i) => i.key === `${item.value}`);

    if (item) {
      return { ...item, label: configItem.value };
    }

    return item;
  });
  return (
    <div className={"filters-group"}>
      {title && <div className={"title"}>{title}</div>}
      <SimpleBar style={{ maxHeight: "300px" }}>
        {mapped.map((item, index) => (
          <CustomRefinementListItem
            key={index}
            item={item}
            attribute={attribute}
            filters={filters}
            handleChange={handleChange}
          />
        ))}
      </SimpleBar>
    </div>
  );
};

export const FiltersPremium = ({ filters, setFilters }) => {
  useToggleRefinement({ attribute: "isPremium" });

  const isChecked =
    "isPremium" in filters.toggle && filters.toggle.isPremium === true;

  const handleChangePremium = (event) => {
    setFilters((prev) => ({
      ...prev,
      toggle: {
        ...prev.toggle,
        isPremium: event.target.checked,
      },
    }));
  };

  return (
    <ToggleSwitch
      checked={isChecked}
      onChange={handleChangePremium}
      label="PREMIUM ONLY"
    />
  );
};

export const FilterEarnings = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const fromRef = useRef(null);

  const toRef = useRef(null);

  const handleChange = () => {
    const from =
      fromRef.current.value !== "" ? parseFloat(fromRef.current.value) : false;
    const to =
      toRef.current.value !== "" ? parseFloat(toRef.current.value) : false;

    setFilters((prev) => ({
      ...prev,
      configure: {
        ...prev.configure,
        filters: `salary_from >= ${from} AND salary_to <= ${to}`,
      },
    }));
  };

  return (
    <>
      <div className={"title"}>{t("browse.filters.earnings.title")}</div>
      <ListGroup as="ul" className="mt-2 d-flex flex-md-column flex-row">
        <Form.Control
          type={"number"}
          value={
            "filters" in filters.configure
              ? filters.configure.filters.split(" ")[2]
              : ""
          }
          placeholder="min."
          onChange={handleChange}
          ref={fromRef}
          className="mb-md-2 mb-0 me-md-0 me-2"
        />
        <Form.Control
          type={"number"}
          value={
            "filters" in filters.configure
              ? filters.configure.filters.split(" ")[6]
              : ""
          }
          placeholder="max."
          onChange={handleChange}
          ref={toRef}
        />
      </ListGroup>
    </>
  );
};

/*
 *
 * FiltersToggleButton
 *
 */

export const FiltersToggleButton = ({ setVisible }) => {
  const { t } = useTranslation();

  const { indexUiState } = useInstantSearch();

  const handleOpen = () => {
    setVisible((prev) => !prev);
  };

  // get count of filters
  const count =
    "refinementList" in indexUiState
      ? Object.keys(indexUiState.refinementList).length
      : 0;

  return (
    <div className="d-flex justify-content-start align-items-center me-3">
      <Button
        variant="light"
        className="filters-toggle align-items-center font-lg d-flex text-primary shadow-sm ps-4 pe-4 pe-md-3 ps-md-3 pt-3 pb-3 bg-white"
        onClick={handleOpen}
      >
        <p className="mb-0 me-1 ms-2 me-3 d-lg-block d-none">
          {t("jobs.browse.parts.filters.moreFilters")}
        </p>
        <PiSlidersHorizontalLight size={34} />
        {count !== 0 && <span>{count}</span>}
      </Button>
    </div>
  );
};

export const ClearFilters = ({ handleReset = () => {} }) => {
  const { setIndexUiState } = useInstantSearch();

  const { handleSetGeo } = useUser();

  const handleClick = (e) => {
    e.preventDefault();

    handleReset();

    setIndexUiState({
      configure: {
        filters: "",
      },
    });

    handleSetGeo({
      selected: false,
      radius: 50,
      lat: false,
      lng: false,
      name: "",
    });
  };

  const { t } = useTranslation();

  return (
    <Button
      variant={"light"}
      className={"w-100 mt-2 pt-2 pb-2"}
      onClick={handleClick}
    >
      {t("jobs.browse.parts.filters.filtersContents.clear")}
    </Button>
  );
};
