import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import InputCode from "../../components/Forms/InputCode";
import { Box } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";
import { autoSignIn, confirmSignUp } from "aws-amplify/auth";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router";
import { useNotification } from "../../hooks/useNotifications";

const AuthVerifyView = ({ setPopupActive, verifyCodeResend }) => {
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const { handleRefresh } = useUser();

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const confirmVerifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const currentLocation = location.pathname;
    if (code.length === 6) {
      try {
        const { isSignUpComplete, nextStep } = await confirmSignUp({
          username: localStorage.getItem("email"),
          confirmationCode: code,
        });
        if (
          isSignUpComplete &&
          nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN"
        ) {
          localStorage.removeItem("email");

          await autoSignIn();

          await handleRefresh();

          navigate(currentLocation);

          setLoading(false);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={confirmVerifyCode}>
      <Box>
        <Box mt={2} mb={10}>
          <InputCode code={code} setCode={setCode} />
          <Text mt={2} text={error} variant={"body2"} color={"error"} />
          <Text
            mt={2}
            text={verifyCodeResend.text}
            color={verifyCodeResend.isError ? "error" : "normal"}
            variant={"body2"}
          />
        </Box>
      </Box>
      <ButtonPrimary
        loading={loading}
        type={"submit"}
        fullWidth
        disabled={code.length !== 6}
      >
        Confirm
      </ButtonPrimary>
      <Box
        display={"flex"}
        justifyContent={"center"}
        mt={1}
        onClick={() => setPopupActive("register")}
      >
        <Text text={"back"} variant={"overline"} color={"#000"} />
      </Box>
    </form>
  );
};

export default AuthVerifyView;
