import React from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../../components/Buttons/Button";
import SearchIcon from "@mui/icons-material/Search";
import useUser from "../../../hooks/useUser";

export const ButtonClear = ({ label, setVisible = () => {} }) => {
  const { setIndexUiState } = useInstantSearch();

  const { handleSetGeo } = useUser();

  const handleClick = (e) => {
    e.preventDefault();

    setIndexUiState({
      configure: {
        filters: "",
      },
    });

    handleSetGeo({
      selected: false,
      radius: 50,
      lat: false,
      lng: false,
      name: "",
    });
    setVisible(false);
  };

  return (
    <ButtonPrimary
      color={"info"}
      onClick={handleClick}
      sx={{
        height: {
          xs: 40,
          md: 48,
        },
        width: {
          xs: "33.3333%",
          md: 48,
        },
        padding: 0,
        minWidth: 0,
      }}
    >
      {label}
    </ButtonPrimary>
  );
};

export const ButtonBrowse = ({ query, setVisible = () => {}, label = "" }) => {
  const { refresh } = useInstantSearch();
  const navigate = useNavigate();
  const searchBox = useSearchBox();

  const handleClick = () => {
    refresh();

    searchBox.refine(query);

    const currentParams = new URLSearchParams(location.search);

    navigate(`/?${currentParams.toString()}`);

    setVisible(false);
  };
  return (
    <ButtonPrimary
      onClick={handleClick}
      sx={{
        height: {
          xs: 40,
          md: 48,
        },
        width: {
          xs: "66.666%",
          md: 48,
        },
        padding: 0,
        minWidth: 0,
        marginLeft: {
          xs: 0,
          md: 1,
        },
      }}
    >
      {label}
      <SearchIcon
        sx={{
          marginLeft: label !== "" ? 1 : 0,
        }}
      />
    </ButtonPrimary>
  );
};
