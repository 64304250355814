import React, { useState } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import ButtonPrimary from "../Buttons/Button";
import Dropdown from "../Dropdown/Dropdown";
import { shortenEmail } from "../../utils/appUtils";

export const UserPanel = ({ user, links = [], handleLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <Button
        sx={{ cursor: "pointer" }}
        onClick={handleClick}
        disableElevation
        disableRipple
      >
        <Avatar
          src={user.avatar}
          alt={user.name}
          sx={{ width: 48, height: 48 }}
        />
      </Button>
      <Dropdown anchor={anchorEl} open={open} handleClose={handleClose}>
        <Box p={1} sx={{ maxWidth: 200, minWidth: 200 }}>
          <Box px={1} mb={1}>
            <Typography
              variant="h6"
              component="h5"
              sx={{ fontSize: "0.7rem", textAlign: "center" }}
            >
              <strong>{shortenEmail(user.email)}</strong>
            </Typography>
          </Box>
          <Box mb={1}>
            {links.map((item, index) => (
              <Link to={item.link} key={index}>
                <MenuItem onClick={handleClose}>{item.text}</MenuItem>
              </Link>
            ))}
          </Box>
          <ButtonPrimary
            fullWidth
            variant="outlined"
            sx={{ fontSize: "0.875rem" }}
            onClick={handleLogout}
          >
            Logout
          </ButtonPrimary>
        </Box>
      </Dropdown>
    </Box>
  );
};

export default UserPanel;
