import React from "react";
import Container from "../Page/Container";

export const BrowseLoop = ({ children }) => {
  return <div className="app-browse-loop">{children}</div>;
};

export const Browse = ({ children }) => {
  return (
    <div className={`app-browse main-page`}>
      <Container>
        <div className={"app-browse-inner"}>{children}</div>
      </Container>
    </div>
  );
};
