import { Box, Skeleton, useTheme } from "@mui/material";
import Text from "../../../components/Typography/Text";
import { SwitchWithoutLabel } from "../../../components/Forms/Toggle";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { RadioIcon } from "../../../components/Forms/Radio";
import { FaCheck } from "react-icons/fa6";
import ButtonPrimary from "../../../components/Buttons/Button";
import Modal, { ModalHelper } from "../../../components/Modals/Modal";
import Steps from "../../../components/Steps";
import { Formik } from "formik";
import TextField from "../../../components/Forms/TextField";
import * as Yup from "yup";
import settingsBillingDataSchema from "../../../validations/account/settings/settingsBillingDataSchema";
import Tooltip from "../../../components/Tooltip";
import { TbAlertCircleFilled } from "react-icons/tb";
import { LoaderComponent } from "../../../components/Loaders/Loader";
import stripeConfig from "../../../config/stripeConfig";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import { useNavigate } from "react-router";
import useQueryString from "../../../hooks/useQueryString";
import { formatDate, timestampToDate } from "../../../utils/appUtils";
import { usePayment } from "../../../hooks/usePayment";

const MONTHLY_KEY = process.env.REACT_APP_STRIPE_MONTHLY;
const ANNUAL_KEY = process.env.REACT_APP_STRIPE_ANNUAL;

const ChangePlanButton = ({ selected, handleClick }) => {
  return (
    <ButtonPrimary
      variant={selected ? "outlined" : "contained"}
      onClick={() => (!selected ? handleClick() : "")}
      fullWidth
      disabled={selected}
    >
      {selected ? "Current plan" : "Change a plan"}
    </ButtonPrimary>
  );
};

export const BillingData = ({ nextStep, pricingType, update }) => {
  const theme = useTheme();

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    postBillingAddress,
    addressData,
    getBillingAddress,
    getBillingSubscriptionPlanID,
  } = usePayment();

  useEffect(() => {
    getBillingAddress();
  }, []);

  const initialData = {
    billingName: addressData.data ? addressData.data.billing_name : "",
    taxID: addressData.data ? addressData.data.tax_id : "",
    line1: addressData.data ? addressData.data.address.line1 : "",
    line2: addressData.data ? addressData.data.address.line2 : "",
    state: addressData.data ? addressData.data.address.state : "",
    zipcode: addressData.data ? addressData.data.address.zipcode : "",
    city: addressData.data ? addressData.data.address.city : "",
    country: addressData.data ? addressData.data.address.country : "",
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    await postBillingAddress({
      billing_name: values.billingName,
      tax_id: values.taxID,
      address: {
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country,
      },
    });
    if (!update) {
      getBillingSubscriptionPlanID(
        pricingType === "annual" ? ANNUAL_KEY : MONTHLY_KEY,
      );
      nextStep();
    }
    setLoadingSubmit(false);
  };

  const formConfig = [
    {
      name: "billingName",
      label: "Billing / Company name",
      gridSize: 12,
    },
    {
      name: "taxID",
      label: "Tax ID",
      gridSize: 12,
    },
    {
      name: "line1",
      label: "Adress line 1",
      gridSize: 12,
    },
    {
      name: "line2",
      label: "Adress line 2",
      gridSize: 12,
    },
    {
      name: "state",
      label: "State",
      gridSize: 6,
    },
    {
      name: "zipcode",
      label: "Zip code",
      gridSize: 6,
    },
    {
      name: "city",
      label: "City",
      gridSize: 6,
    },
    {
      name: "country",
      label: "Country",
      gridSize: 6,
    },
  ];

  return (
    <Box mt={3}>
      {addressData.loading ? (
        <Box>
          <Grid container spacing={2}>
            {formConfig.map((item, index) => (
              <Grid size={item.gridSize} key={index}>
                <Skeleton variant="rounded" height={40} width={"100%"} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Formik
          initialValues={initialData}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape(settingsBillingDataSchema())}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, errors, handleBlur, values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {formConfig.map((item, index) => (
                  <Grid key={index} size={item.gridSize}>
                    <TextField
                      label={item.label}
                      name={item.name}
                      error={errors[item.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[item.name]}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <>
                              {errors[item.name] && (
                                <Tooltip
                                  placement="left"
                                  title={errors[item.name]}
                                >
                                  <Box display="flex">
                                    <TbAlertCircleFilled
                                      color={theme.palette.error.main}
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          ),
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Text
                color={"error"}
                variant="body2"
                text={addressData.error}
                mt={0.5}
              />
              <Box mt={3} display={"flex"} gap={1}>
                {!update && (
                  <ButtonPrimary color={"info"} fullWidth>
                    Cancel
                  </ButtonPrimary>
                )}
                <ButtonPrimary
                  loading={loadingSubmit}
                  type={"submit"}
                  fullWidth
                >
                  {update ? "Update" : "Ok"}
                </ButtonPrimary>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

const PaymentData = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();

  const subscribe = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/account/settings?tab-index=3`,
      },
    });

    if (
      error.type === "card_error" ||
      error.type === "validation_error" ||
      error.type === "invalid_request_error"
    ) {
      setError(error.message);
    } else {
      setError("An unexpected error occurred.");
    }
    setLoading(false);
  };

  return (
    <form onSubmit={subscribe}>
      <PaymentElement />
      <Text text={error} variant={"body2"} color={"error"} />
      <Box mt={4} display={"flex"} gap={1}>
        <ButtonPrimary color={"info"} fullWidth>
          Cancel
        </ButtonPrimary>
        <ButtonPrimary loading={loading} type={"submit"} fullWidth>
          Subscribe
        </ButtonPrimary>
      </Box>
    </form>
  );
};

const Summary = ({ handleClose }) => {
  const navigate = useNavigate();

  const { removeQuery } = useQueryString();

  useEffect(() => {
    removeQuery("redirect_status");
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={4}>
      <DiamondOutlinedIcon color={"secondary"} sx={{ width: 80, height: 72 }} />
      <Text
        text={"Your Premium subscription is active"}
        variant={"h3"}
        textAlign={"center"}
      />
      <Text
        mt={1}
        text={
          "From now on you can enjoy premium features. Thanks to you we can develop our system to become even better."
        }
        variant={"body1"}
        textAlign={"center"}
      />
      <Box display={"flex"} width={"100%"} mt={3} gap={1}>
        <ButtonPrimary
          fullWidth
          color={"info"}
          onClick={() => navigate("/account/edit")}
        >
          Edit profile
        </ButtonPrimary>
        <ButtonPrimary onClick={handleClose} fullWidth>
          Ok
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

const ChangePlanModal = ({
                           changePlanModalVisible,
                           setChangePlanModalVisible,
                           pricingType,
                           defaultStep,
                         }) => {
  const [activeStep, setActiveStep] = useState(defaultStep);
  const nextStep = () => setActiveStep((prevState) => prevState + 1);

  const steps = ["", "", ""];

  const { subscriptionData } = usePayment();

  const { clearQuery } = useQueryString();

  const { options, stripePromise } = stripeConfig(subscriptionData);

  const handleClose = () => {
    setChangePlanModalVisible(false);
    if (activeStep === 2) {
      clearQuery();
    }
  };

  const stepsConfig = [
    {
      step: 0,
      title: "Step 1 - Billing data",
      component: <BillingData nextStep={nextStep} pricingType={pricingType} />,
    },
    {
      step: 1,
      component: (
        <>
          {!subscriptionData.data ? (
            <LoaderComponent height={200} width={"100%"} />
          ) : (
            <Elements stripe={stripePromise} options={options}>
              <PaymentData />
            </Elements>
          )}
        </>
      ),
    },
    {
      step: 2,
      component: <Summary handleClose={handleClose} />,
    },
  ];

  const currentStep = stepsConfig.find((step) => step.step === activeStep);

  return (
    <Modal open={changePlanModalVisible} onClose={handleClose}>
      <Text variant={"h3"} text={stepsConfig[activeStep].title} mb={2} />
      <Steps activeStep={activeStep} steps={steps} />
      {currentStep ? currentStep.component : <p>Invalid step</p>}
    </Modal>
  );
};

const CancelSubscriptionModal = ({
                                   subscriptionData,
                                   cancelSubscriptionModalVisible,
                                   setCancelSubscriptionModalVisible,
                                 }) => {
  const [loading, setLoading] = useState(false);

  const { deleteBillingSubscriptionPlanID, getSubscriptionData } = usePayment();

  const cancelSubscription = async () => {
    setLoading(true);
    await deleteBillingSubscriptionPlanID(subscriptionData.data.id);
    await getSubscriptionData();
    setLoading(false);
    setCancelSubscriptionModalVisible(false);
  };

  return (
    <Modal
      open={cancelSubscriptionModalVisible}
      onClose={() => setCancelSubscriptionModalVisible(false)}
    >
      <Text variant={"h3"} text={"Subscription cancelation"} mb={3} />
      <Text
        variant={"body1"}
        text={
          "Are you sure you want to cancel your premium plan and back to free tier?"
        }
      />
      <Box display={"flex"} gap={1} mt={3}>
        <ButtonPrimary
          onClick={() => setCancelSubscriptionModalVisible(false)}
          fullWidth
          color={"info"}
        >
          Cancel
        </ButtonPrimary>
        <ButtonPrimary loading={loading} onClick={cancelSubscription} fullWidth>
          Ok
        </ButtonPrimary>
      </Box>
    </Modal>
  );
};

const PlansView = () => {
  const theme = useTheme();

  const [pricingType, setPricingType] = useState("monthly");

  const [changePlanModalVisible, setChangePlanModalVisible] = useState(false);

  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] =
    useState(false);

  const [packageSelected, setPackageSelected] = useState("free");

  const [searchParams] = useSearchParams();

  const handlePricingTypeChange = (type) => {
    setPricingType(type);
  };

  const { getSubscriptionData, subscriptionData } = usePayment();

  const freeBenefitsConfig = [
    "1 Video, 4 photo files",
    "One category",
    "3 tags",
    "2 months of chat history",
  ];

  const premiumStarterConfig = [
    "Up to 8 media files to your portfolio",
    "More categories for your profile (3 cat.)",
    "More tags to your profile (6 tags)",
    "Premium badge on profile",
    "Premium only” filter for premium users",
    "Unlimited Chat history",
    "Links to your portfolio on socialmedia",
  ];

  useEffect(() => {
    if (subscriptionData.data?.plan) {
      setPricingType(subscriptionData.data.plan.nickname?.toLowerCase());
      setPackageSelected("premiumStart");
    } else {
      setPricingType("monthly");
      setPackageSelected("free");
    }
  }, [subscriptionData, subscriptionData.loading]);

  useEffect(() => {
    if (searchParams.get("redirect_status") === "succeeded") {
      setChangePlanModalVisible(true);
    }
  }, []);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      await getSubscriptionData();
    };
    if (!subscriptionData.data) {
      fetchSubscriptionData();
    }
  }, []);

  return (
    <>
      {subscriptionData.loading ? (
        <>
          <Box display={"flex"} justifyContent={"space-between"} mb={2}>
            <Skeleton variant="text" sx={{ fontSize: 22 }} width={70} />
            <Skeleton variant="text" sx={{ fontSize: 22 }} width={180} />
          </Box>
          <Box
            display={"flex"}
            gap={2}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Skeleton
              variant="rounded"
              sx={{
                width: { xs: "100%", md: "50%" },
                height: { xs: "300px", md: "400px" },
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                width: { xs: "100%", md: "50%" },
                height: { xs: "300px", md: "400px" },
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItem={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Text mr={1} text={"Plans"} variant={"h3"} />
              <ModalHelper
                title={"Plans"}
                text={
                  "Plans section, you can choose your preferred subscription plan. The Free plan gives you basic access with limited features, while the Premium plan offers enhanced options such as unlimited chat history, additional media uploads, and more visibility for your profile. Select the plan that best suits your needs, and you can upgrade or downgrade at any time."
                }
              />
            </Box>
            {packageSelected !== "premiumStart" &&
              subscriptionData.data?.status !== "active" && (
                <Box display={"flex"} alignItems={"center"}>
                  <Text text={"MONTHLY"} variant={"h6"} mr={1} />
                  <SwitchWithoutLabel
                    checked={pricingType === "annual"}
                    onChange={(e) =>
                      handlePricingTypeChange(
                        e.target.checked ? "annual" : "monthly",
                      )
                    }
                  />
                  <Text text={"ANNUALY"} variant={"h6"} ml={1} />
                </Box>
              )}
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid
                onClick={() =>
                  subscriptionData.data?.status !== "canceled" &&
                  (packageSelected !== "free" ||
                    subscriptionData.data?.status === "active")
                    ? setCancelSubscriptionModalVisible(true)
                    : null
                }
                position="relative"
                borderRadius={3}
                p={3}
                item
                size={{ xs: 12, sm: 6 }}
                style={{
                  cursor:
                    packageSelected !== "free" ||
                    subscriptionData.data?.status === "active"
                      ? "pointer"
                      : "auto",
                }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                sx={{
                  border: 1,
                  borderColor:
                    packageSelected === "free" ||
                    subscriptionData.data?.status !== "active"
                      ? theme.palette.primary.main
                      : "#D9D9D9",
                }}
              >
                <Box>
                  <Box borderBottom={1} pb={2}>
                    <Text text={"Free"} variant={"h2"} />
                    <Text
                      text={"$0.00/month"}
                      variant={"h2"}
                      mb={1}
                      sx={{
                        fontWeight: theme.typography.fontWeightLight,
                      }}
                    />
                    <Text
                      text={"Use Job Multiverse as long as you want for free"}
                      variant={"body1"}
                    />
                    <RadioIcon
                      value="free"
                      checked={
                        packageSelected === "free" ||
                        subscriptionData.data?.status !== "active"
                      }
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: 0,
                      }}
                    />
                  </Box>
                  <Box pt={2}>
                    {freeBenefitsConfig.map((item, index) => (
                      <Box key={index} display={"flex"} alignItems={"center"}>
                        <FaCheck
                          sx={{ ml: 2 }}
                          color={theme.palette.primary.main}
                        />
                        <Text text={item} variant={"body1"} ml={1} />
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box mt={2}>
                  {subscriptionData.data?.status === "canceled" ? (
                    <ButtonPrimary
                      fullWidth
                      variant={"outlined"}
                      sx={{ cursor: "not-allowed" }}
                    >
                      {timestampToDate(
                        subscriptionData.data?.current_period_end,
                      )}
                    </ButtonPrimary>
                  ) : (
                    <ChangePlanButton
                      selected={
                        packageSelected === "free" ||
                        subscriptionData.data?.status !== "active"
                      }
                      handleClick={() =>
                        setCancelSubscriptionModalVisible(true)
                      }
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                onClick={() =>
                  packageSelected === "premiumStart" ||
                  subscriptionData.data?.status === "active"
                    ? null
                    : setChangePlanModalVisible(true)
                }
                position="relative"
                borderRadius={3}
                p={3}
                item
                size={{ xs: 12, sm: 6 }}
                style={{
                  cursor:
                    packageSelected === "premiumStart" ||
                    subscriptionData.data?.status === "active"
                      ? "auto"
                      : "pointer",
                }}
                sx={{
                  border: 1,
                  borderColor:
                    packageSelected === "premiumStart" &&
                    subscriptionData.data?.status === "active"
                      ? theme.palette.primary.main
                      : "#D9D9D9",
                }}
              >
                <Box borderBottom={1} pb={2}>
                  <Text text={"Premium Start"} variant={"h2"} />
                  <Box display={"flex"}>
                    <Text
                      text={
                        pricingType === "monthly"
                          ? "$29.99/month"
                          : "$299.9/year"
                      }
                      variant={"h2"}
                      sx={{
                        fontWeight: theme.typography.fontWeightLight,
                      }}
                    />
                    {pricingType !== "monthly" && (
                      <Box
                        backgroundColor={theme.palette.secondary.main}
                        display={"flex"}
                        alignItems={"center"}
                        pl={1.5}
                        pr={1.5}
                        ml={1}
                      >
                        <Text
                          text={"Save 17%"}
                          variant={"h4"}
                          sx={{ color: "#fff", textTransform: "uppercase" }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Text
                    text={"Pay for the entire year and get two months for free"}
                    variant={"body1"}
                  />
                  <RadioIcon
                    value="premiumStart"
                    checked={
                      packageSelected === "premiumStart" &&
                      subscriptionData.data?.status === "active"
                    }
                    sx={{ position: "absolute", top: 0, right: 0, margin: 0 }}
                  />
                </Box>
                <Box pt={2}>
                  {premiumStarterConfig.map((item, index) => (
                    <Box key={index} display={"flex"} alignItems={"center"}>
                      <FaCheck
                        sx={{ ml: 2 }}
                        color={theme.palette.primary.main}
                      />
                      <Text text={item} variant={"body1"} ml={1} />
                    </Box>
                  ))}
                </Box>
                <Box mt={2}>
                  <ChangePlanButton
                    selected={
                      packageSelected === "premiumStart" ||
                      subscriptionData.data?.status === "active"
                    }
                    handleClick={() => setChangePlanModalVisible(true)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <ChangePlanModal
        changePlanModalVisible={changePlanModalVisible}
        setChangePlanModalVisible={setChangePlanModalVisible}
        pricingType={pricingType}
        defaultStep={
          searchParams.get("redirect_status") === "succeeded" ? 2 : 0
        }
        subscriptionData={subscriptionData}
      />
      <CancelSubscriptionModal
        setCancelSubscriptionModalVisible={setCancelSubscriptionModalVisible}
        cancelSubscriptionModalVisible={cancelSubscriptionModalVisible}
        subscriptionData={subscriptionData}
      />
    </>
  );
};

export default PlansView;