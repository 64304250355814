import Text from "../../../components/Typography/Text";
import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import Tooltip from "../../../components/Tooltip";
import TextField from "../../../components/Forms/TextField";
import ButtonPrimary from "../../../components/Buttons/Button";
import {
  DisplayItem,
  DisplayItems,
} from "../../../components/Autocompletes/AutocompleteProfile";
import { useFormikContext } from "formik";
import { TbAlertCircleFilled } from "react-icons/tb";
import { InformationIcon } from "../../../pages/account/edit";

const ExperienceView = () => {
  const { values, setFieldValue } = useFormikContext();

  const [experience, setExperience] = useState({
    from: "",
    to: "",
    value: "",
  });

  const theme = useTheme();

  const [errors, setErrors] = useState({
    value: { hasError: false, message: "" },
  });

  const addExperience = () => {
    let newErrors = {
      value: { hasError: false, message: "" },
    };
    if (experience.value.length === 0) {
      newErrors.value = {
        hasError: true,
        message: 'The field "experience" is required',
      };
    }
    setErrors(newErrors);

    if (newErrors.value.hasError) {
      return;
    }
    setFieldValue(
      "experience",
      values.experience ? [...values.experience, experience] : [experience],
    );
    setExperience({
      from: "",
      to: "",
      value: "",
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mb={1}
        alignItems={"center"}
      >
        <Text text={"Experience"} variant={"h3"} />
        <InformationIcon
          title={"Experience"}
          text={
            "Add your professional experience to showcase your background and expertise. This helps potential clients understand your qualifications and see how you can meet their needs. Be sure to include relevant roles, responsibilities, and achievements. Updating your experience regularly will help you stand out and attract more opportunities."
          }
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1} mb={2}>
        <Box display={"flex"} gap={1}>
          <TextField
            fullWidth
            value={experience.from}
            label={"From"}
            onChange={(e) =>
              setExperience((prevState) => ({
                ...prevState,
                from: e.target.value,
              }))
            }
          />
          <TextField
            fullWidth
            label={"To"}
            value={experience.to}
            onChange={(e) =>
              setExperience((prevState) => ({
                ...prevState,
                to: e.target.value,
              }))
            }
          />
        </Box>
        <TextField
          label={"Position / Company etc."}
          error={errors.value.hasError}
          value={experience.value}
          onChange={(e) =>
            setExperience((prevState) => ({
              ...prevState,
              value: e.target.value,
            }))
          }
          fullWidth
          onEnter={addExperience}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {errors.value.hasError && (
                    <Tooltip placement="left" title={errors.value.message}>
                      <Box display="flex">
                        <TbAlertCircleFilled color={theme.palette.error.main} />
                      </Box>
                    </Tooltip>
                  )}
                </>
              ),
            },
          }}
        />
        <ButtonPrimary size={"md"} onClick={addExperience}>
          Add Experience
        </ButtonPrimary>
      </Box>
      {values.experience && values.experience.length !== 0 && (
        <DisplayItems name={"Your experience"}>
          {values.experience.map((el, index) => (
            <React.Fragment key={index}>
              <DisplayItem
                text={`${el.from.length === 0 && el.to.length === 0 ? "" : `${el.from} - ${el.to} | `} ${el.value}`}
                onRemove={() => {
                  setFieldValue(
                    "experience",
                    values.experience.filter((_, i) => i !== index),
                  );
                }}
              />
            </React.Fragment>
          ))}
        </DisplayItems>
      )}
    </>
  );
};

export default ExperienceView;
