import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  observations: [],
  loadingStates: {},
};

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setObserveLoading: (state, { payload }) => {
      const { uid, loading } = payload;
      state.loadingStates[uid] = loading;
    },
    setObservations: (state, action) => {
      state.observations = action.payload;
    },
    toggleObservation: (state, action) => {
      const { userId, status } = action.payload;
      if (status === "created") {
        state.observations.push(userId);
      } else {
        state.observations = state.observations.filter((id) => id !== userId);
      }
    },
  },
});

export const { setObserveLoading, toggleObservation, setObservations } =
  watchlistSlice.actions;
export default watchlistSlice.reducer;
