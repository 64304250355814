import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import checkedIcon from "../../assets/svg/checked.svg";
import React from "react";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  padding: 4,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
  "input:checked ~ &": {
    border: "1px solid #5100B9",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    content: '""',
    width: "100%",
    height: "100%",
    maskImage: `url(${checkedIcon})`,
    maskSize: "contain",
    maskPosition: "center",
    maskRepeat: "no-repeat",
    backgroundColor: "#5100B9",
  },
});

const BpRadio = React.forwardRef(function BpRadio(props, ref) {
  return (
    <Radio
      ref={ref}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
});

export const RadioIcon = ({ value, controlRef, ...props }) => {
  return (
    <FormControlLabel
      value={value}
      control={<BpRadio ref={controlRef} />}
      {...props}
    />
  );
};
