import {
  RelatedProducts,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch";
import React, { useState } from "react";
import Item from "../../components/Item/Item";
import useQueryString from "../../hooks/useQueryString";
import { X } from "react-feather";
import SliderPopup from "./SliderPopup/SliderPopup";
import useUser from "../../hooks/useUser";
import BrowseSkeleton from "../../components/Browse/BrowseSkeleton";

const SimilarItem = ({ item, hits, setIndex }) => {
  const { setIndexUiState } = useInstantSearch();

  const handleClick = (item) => {
    const findIndex = hits.findIndex((hit) => hit.uuid === item.uuid);

    if (findIndex !== -1) {
      setIndex(findIndex);
    } else {
      alert("unable to open popup, cannot find index");
    }
  };

  return (
    <>
      <Item
        item={item}
        handleClick={handleClick}
        setIndexUiState={setIndexUiState}
      />
    </>
  );
};

const HeaderSimilar = ({ setHits, items }) => {
  const { removeQuery } = useQueryString();

  setHits(items);

  return (
    <div
      className={
        "w-100 bg-primary position-relative d-flex justify-content-center pt-1 pb-1 rounded-4"
      }
    >
      <p className={"text-center mb-0 text-white"}>
        Search for similar results
      </p>
      <button
        onClick={() => removeQuery("similar")}
        className={
          "position-absolute end-0 bg-transparent p-0 border-0 me-2 top-50 translate-middle-y text-white"
        }
      >
        <X />
      </button>
    </div>
  );
};

const Related = ({ id }) => {
  const [index, setIndex] = useState(-1);
  const { showMore } = useInfiniteHits();
  const [hits, setHits] = useState([]);

  const { isMobile } = useUser();

  return (
    <>
      <RelatedProducts
        objectIDs={[`user-${id}`]}
        itemComponent={(props) => (
          <SimilarItem {...props} hits={hits} setIndex={setIndex} />
        )}
        headerComponent={(props) => (
          <HeaderSimilar {...props} setHits={setHits} />
        )}
        limit={50}
        emptyComponent={BrowseSkeleton}
        classNames={{
          item: `${isMobile ? "app-browse-loop-item is-normal" : ""}`,
          // container: 'app-browse-loop'
        }}
      />
      {index !== -1 && (
        <SliderPopup
          index={index}
          setIndex={setIndex}
          showMore={showMore}
          total={hits.length}
          items={hits}
        />
      )}
    </>
  );
};
export default Related;
