import ButtonPrimary from "../../../components/Buttons/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import Text from "../../../components/Typography/Text";
import useWatchlist from "../../../hooks/useWatchlist";
import { ShareModal } from "./ShareView";
import { ReportModal } from "./ReportView";
import { useInstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router";
import useUser from "../../../hooks/useUser";

const MoreOptionsView = ({ profile, options = ["close"] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const { setIndexUiState } = useInstantSearch();

  const navigate = useNavigate();

  const [reportModalVisible, setReportModalVisible] = useState(false);

  const uuid = profile.details.uuid || profile.details.userId;

  const { user } = useUser();

  const { observed, handleObserve, observedLoading } = useWatchlist(uuid);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionsConfig = [
    {
      key: "watchlist",
      text: `${observedLoading ? "Loading" : `${observed ? "Remove from" : "Add to"} watchlist`}`,
      hidden: user?.id === uuid,
      handleClick: () => handleObserve(uuid),
    },
    {
      key: "share",
      text: "Share",
      handleClick: () => setShareModalVisible(true),
    },
    {
      key: "report",
      text: "Report a violation",
      handleClick: () => setReportModalVisible(true),
    },
    {
      key: "similar",
      text: "Show similar",
      handleClick: () => {
        setIndexUiState([]);
        navigate(`/similar/${uuid}`);
      },
    },
    {
      key: "close",
      text: "Close",
      handleClick: () => handleClose(),
    },
  ];

  return (
    <Box position={"relative"}>
      <ButtonPrimary
        onClick={handleClick}
        color={"info"}
        sx={{ minWidth: "2px", width: "2px" }}
      >
        <MoreVertIcon />
      </ButtonPrimary>
      <Dropdown anchor={anchorEl} open={open} handleClose={handleClose}>
        <Box p={2}>
          {optionsConfig.map((option, index) => (
            <>
              {options.includes(option.key) && !option.hidden && (
                <Text
                  mb={1}
                  pb={1}
                  sx={{
                    cursor: "pointer",
                    borderBottom: `1px solid ${theme.palette.border.input}`,
                  }}
                  variant={"body1"}
                  text={option.text}
                  key={index}
                  onClick={option.handleClick}
                />
              )}
            </>
          ))}
        </Box>
      </Dropdown>
      <ShareModal
        shareModalVisible={shareModalVisible}
        setShareModalVisible={setShareModalVisible}
        profile={profile}
      />
      <ReportModal
        reportModalVisible={reportModalVisible}
        setReportModalVisible={setReportModalVisible}
        profile={profile}
      />
    </Box>
  );
};

export default MoreOptionsView;
