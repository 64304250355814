import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useSavePreviousLocation from "./hooks/useSavePreviousLocation";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  useWebSocket,
  useWebSocketConnector,
  useWebSocketNotifications,
} from "./hooks/useWebSocket";
import useUser from "./hooks/useUser";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import GoToTop from "./old_components/Parts/GoToTop";
import { Box } from "@mui/material";
import { WifiOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import useError from "./hooks/useError";
import Container from "./components/Page/Container";
import Text from "./components/Typography/Text";

const RequireSeller = () => {
  const { user } = useUser();

  if (user.account_type === "searcher") {
    return <Navigate to={"/account/settings"} />;
  }

  return <Outlet />;
};

const SocketOfflineIcon = () => {
  return (
    <Box
      position="fixed"
      bottom={16}
      right={16}
      padding={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="white.main"
      borderRadius={2}
      boxShadow={10}
      zIndex={99}
    >
      <Box sx={{ mr: 1, color: "#D0D6DE" }}>
        <AutorenewIcon sx={{ fontSize: "32px" }} />
      </Box>
      <Box>
        <Text
          sx={{ color: "#D0D6DE" }}
          text={"Please wait, reconnecting..."}
          variant={"h4"}
        />{" "}
      </Box>
    </Box>
  );
};

const RequireAuth = () => {
  const { user } = useUser();

  if (!user) return <Navigate to={"/?auth-popup=register"} />;

  return <Outlet />;
};

const RequireValidUserOrQuest = () => {
  useSavePreviousLocation();
  const { isLost } = useWebSocketConnector();
  useWebSocketNotifications();

  /**
   *
   * Get unread messages count from server, only for logged in users
   *
   */

  return (
    <>
      <GoToTop />
      {isLost && <SocketOfflineIcon />}
      <Outlet />
    </>
  );
};

const RequireAccountWrapper = () => {
  const dispatch = useDispatch();

  const { handleSetIndexStatusValue, handleFetchProfileStatus } = useError();

  const { register, unregister } = useWebSocket();

  useEffect(() => {
    handleFetchProfileStatus();
  }, []);

  useEffect(() => {
    const handleUserVerificationCompleted = (data) => {
      handleSetIndexStatusValue(data.index_status);
      handleFetchProfileStatus();
    };

    register("user_verification_completed", handleUserVerificationCompleted);

    return () => {
      unregister(
        "user_verification_completed",
        handleUserVerificationCompleted,
      );
    };
  }, [dispatch]);

  return (
    <>
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export {
  RequireAuth,
  RequireValidUserOrQuest,
  RequireSeller,
  RequireAccountWrapper,
};
