import { styled, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";

const SelectStyled = styled(Autocomplete)(({ disabled }) => ({
  ".MuiChip-root": {
    backgroundColor: "transparent",
    border: "1px solid #DFDFDF",
    color: "#4444444",
    height: "30px",
  },
  ".MuiAutocomplete-inputRoot": {
    paddingTop: "2px",
    paddingBottom: "2px",
    borderRadius: 8,
    backgroundColor: disabled ? "#f5f5f5" : "transparent",
    minHeight: "40px",
  },
  ".MuiInputLabel-root": {
    fontSize: "13px",
    transform: "translate(14px, 11px)",
    color: "#AEAEAE",
    "&:focus-within": {
      color: "#fff",
    },
  },
  "& .MuiInputLabel-shrink": {
    color: "#AEAEAE",
    transform: "translate(14px, -9px) scale(0.77)",
  },
}));
export const Select = ({ options, label, ...props }) => {
  return (
    <SelectStyled
      disablePortal
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      {...props}
    />
  );
};
