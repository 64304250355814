import { Container as MuiContainer } from "@mui/material";

const Container = ({ children, sx, ...props }) => {
  return (
    <MuiContainer
      disableGutters
      maxWidth={"xl"}
      sx={{ px: 2, ...sx }}
      {...props}
    >
      {children}
    </MuiContainer>
  );
};

export default Container;
