import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ButtonClose } from "../Buttons/Button";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Text from "../Typography/Text";
import Grow from "@mui/material/Grow";

const style = {
  position: "relative",
  borderRadius: 3,
  bgcolor: "background.paper",
  border: "unset",
  outline: "none",
  boxShadow: 24,
  m: 2,
};

export const ModalContent = ({ open, onClose, children, ...props }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      {...props}
    >
      <Grow in={open}>
        <Box
          pt={3}
          pb={3}
          pl={2}
          pr={2}
          sx={style}
          p={props.boxPadding}
          width={props.boxWidth ? props.boxWidth : 400}
        >
          <ButtonClose
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={onClose}
          />
          <Box>{children}</Box>
        </Box>
      </Grow>
    </Modal>
  );
};

export const ModalHelper = ({ text, title }) => {
  const [modalInformationVisible, setModalInformationVisible] = useState(false);

  return (
    <>
      <HelpOutlineOutlinedIcon
        color={"primary"}
        cursor={"pointer"}
        onClick={() => {
          setModalInformationVisible(true);
        }}
      />
      <ModalContent
        open={modalInformationVisible}
        onClose={() => setModalInformationVisible(false)}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} alignItems={"center"}>
            <HelpOutlineOutlinedIcon color={"primary"} />
            <Text ml={1} text={title} variant={"h3"} />
          </Box>
          <Text text={text} mt={2} variant={"body1"} />
        </Box>
      </ModalContent>
    </>
  );
};

export default ModalContent;
