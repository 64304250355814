import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Page, PageHeader } from "../views/Page";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import useUser from "../hooks/useUser";
import "../old_components/Browse/Browse.scss";
import { BrowseInfiniteHits } from "./home";
import BrowseSkeleton from "../components/Browse/BrowseSkeleton";

const Observations = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { setIndexUiState } = useInstantSearch();

  const [ready, setReady] = useState(false);

  const observations = user.observations;

  const observationsListObjectID = observations.map(
    (el) => "objectID:user-" + el,
  );

  const { handleSetGeo } = useUser();

  useEffect(() => {
    handleSetGeo({
      selected: false,
      radius: 50,
      lat: false,
      lng: false,
      name: "",
    });

    setIndexUiState((prev) => {
      return {
        ...prev,
        configure: {
          ...prev.configure,
          filters: observationsListObjectID.join(" OR "),
        },
      };
    });

    setReady(true);
  }, []);

  return (
    <Page title={t("pageTitles.observations")}>
      <section className="profile">
        <Container>
          <PageHeader text={t("profile.observations.title")} />
          <div className={"mt-4"}>
            {ready ? <BrowseInfiniteHits /> : <BrowseSkeleton />}
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default Observations;
