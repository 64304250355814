import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";

const CloseOutside = ({ refs, handleClose, children, ...props }) => {
  const outsideRef = useRef(null);

  const handleClickOutside = (event) => {
    if (outsideRef.current) {
      let close = true;

      const ignoredRefs = Array.isArray(refs)
        ? [outsideRef, ...refs]
        : [outsideRef];

      ignoredRefs.forEach((el) => {
        if (el.current && el.current.contains(event.target)) {
          close = false;
        }
      });

      if (close) {
        handleClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideRef]);

  return (
    <Box ref={outsideRef} {...props}>
      {children}
    </Box>
  );
};

export default CloseOutside;
