import axios from "axios";

const s3upload = async (
  url,
  file,
  onSuccess = () => {},
  onProgress = () => {},
) => {
  try {
    const response = await axios.put(url, file, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        onProgress(percentCompleted);
      },
    });
    onSuccess(response);
  } catch (error) {
    alert("Error during file upload");
  }
};

export default s3upload;
