import * as Yup from "yup";

const authRegisterSchema = (t) => ({
  name: Yup.string().required("Username is required"),
  email: Yup.string()
    .required(t("auth.register.registerForm.validation.email.required"))
    .email(t("auth.register.registerForm.validation.email.email")),
  password: Yup.string()
    .required(t("auth.register.registerForm.validation.password.required"))
    .min(8, t("auth.register.registerForm.validation.password.min"))
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1})((?=.*\d){1}).*$/,
      t("auth.register.registerForm.validation.password.matches"),
    ),
});

export default authRegisterSchema;
