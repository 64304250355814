import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ButtonPrimary from "../../../components/Buttons/Button";
import useWatchlist from "../../../hooks/useWatchlist";
import StarIcon from "@mui/icons-material/Star";
import useUser from "../../../hooks/useUser";

const AddToWatchlistView = ({ profile }) => {
  const uuid = profile.details.userId;

  const { user } = useUser();

  if (user?.id === uuid) {
    return;
  }

  const { observed, handleObserve, observedLoading } = useWatchlist(uuid);

  return (
    <>
      <ButtonPrimary
        onClick={() => handleObserve(uuid)}
        color={"info"}
        sx={{ minWidth: "40px", width: "40px" }}
        loading={observedLoading}
      >
        {observedLoading ? (
          ""
        ) : (
          <>
            {observed ? (
              <StarIcon color={"warning"} />
            ) : (
              <StarBorderOutlinedIcon />
            )}
          </>
        )}
      </ButtonPrimary>
    </>
  );
};

export default AddToWatchlistView;
