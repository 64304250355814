import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const LoaderComponent = ({ width, height }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        width: width,
        height: height,
        borderRadius: 3,
        backgroundColor: "info.main",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
