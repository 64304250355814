import { Slider as MuiSlider } from "@mui/material";

const Slider = ({ ...props }) => {
  return (
    <MuiSlider
      max={50}
      valueLabelDisplay="on"
      valueLabel
      valueLabelFormat={(value) => `+ ${value} miles`}
      sx={{
        "& .MuiSlider-valueLabel": {
          backgroundColor: "#444444",
          color: "#FFFFFF",
          fontWeight: 400,
          fontSize: "11px",
          padding: 1,
        },
      }}
      {...props}
    />
  );
};

export default Slider;
