import * as Yup from "yup";

const taxIdRegex = /^\d{9,}$/;

const settingsBillingDataSchema = (t) => ({
  taxID: Yup.string()
    .matches(taxIdRegex, "Invalid Tax ID format")
    .required("Tax ID is required"),
  billingName: Yup.string().required("Billing name is required"),
  line1: Yup.string().required("Address line1 is required"),
  state: Yup.string().required("State is required"),
  zipcode: Yup.string().required("Zipcode is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
});

export default settingsBillingDataSchema;
