import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import useConfig from "../../../hooks/useConfig";
import {
  useInstantSearch,
  useRefinementList,
  useSearchBox,
} from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import SearchLocation from "./SearchLocation";
import TextField from "../../../components/Forms/TextField";
import { ButtonClose } from "../../../components/Buttons/Button";
import {
  SearchHeader,
  SearchHeaderDropdown,
  SearchHeaderDropdownGroup,
  SearchHeaderDropdownMobile,
} from "../../../components/Page/SearchHeader";
import { Box, ClickAwayListener, useTheme } from "@mui/material";
import { ButtonBrowse, ButtonClear } from "./SearchButtons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import useUser from "../../../hooks/useUser";
import SearchIcon from "@mui/icons-material/Search";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import { InputGhost } from "../../../components/Forms/Input";

const SearchInput = ({ query, setQuery, type = "ghost" }) => {
  const { t } = useTranslation();

  const searchBox = useSearchBox();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchBox.refine(query);
      const currentParams = new URLSearchParams(location.search);
      navigate(`/?${currentParams.toString()}`);
      e.preventDefault();
      e.target.blur();
    }
  };

  if (type === "solid") {
    return (
      <TextField
        placeholder={t("jobs.browse.search.query.placeholder")}
        onKeyDown={handleKeyDown}
        value={query}
        onChange={handleChange}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-input": {
            height: 48,
          },
          "& .MuiOutlinedInput-root": {
            height: 48,
            borderRadius: 24,
          },
        }}
      />
    );
  }

  return (
    <InputGhost
      placeholder={t("jobs.browse.search.query.placeholder")}
      onKeyDown={handleKeyDown}
      value={query}
      onChange={handleChange}
      sx={{
        width: "auto",
        flex: 1,
        height: 32,
      }}
    />
  );
};

const SearchActiveFilters = ({ shorted = false }) => {
  const { t } = useTranslation();
  const { config } = useConfig();
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const navigate = useNavigate();

  const handleRemove = (item) => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    setIndexUiState((prev) => {
      if (item.attribute === "query") {
        const { query, ...rest } = prev;

        return rest;
      }

      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          [item.attribute]: prev.refinementList?.[item.attribute].filter(
            (i) => i !== item.id,
          ),
        },
        page: 1,
      };
    });
  };

  const getActiveItems = (refinementList, attribute, configItems = []) =>
    (refinementList ?? []).map((item) => ({
      id: item,
      title: configItems.find((cat) => cat.slug === item)?.title || item,
      attribute,
    }));

  const active = [
    ...getActiveItems(
      indexUiState.refinementList?.categories,
      "categories",
      config.categories.items,
    ),
    ...getActiveItems(indexUiState.refinementList?.tags, "tags"),
    ...(indexUiState.query
      ? [{ id: "query", title: indexUiState.query, attribute: "query" }]
      : []),
  ];

  if (active.length === 0) {
    return <></>;
  }
  const theme = useTheme();
  if (shorted === true) {
    return (
      <Box
        sx={{
          fontSize: "0.72rem",
          fontFamily: '"Open Sans", sans-serif',
          fontWeight: 400,
          borderRadius: "1rem",
          margin: "0.1rem",
          padding: "0.3rem 0.6rem",
          textTransform: "uppercase",
          lineHeight: "1rem",
          display: "block",
          width: "auto",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        }}
      >
        <FilterAltIcon fontSize={"9px"} /> {active.length}
      </Box>
    );
  } else {
    return (
      <SearchHeaderDropdownGroup
        title={t("jobs.browse.search.autocomplete.selected")}
        items={active}
        active={true}
        handleClick={handleRemove}
      />
    );
  }
};

const SearchRefinementList = ({ query, attribute, limit = 99999 }) => {
  const { items } = useRefinementList({ attribute: attribute, limit: limit });

  const { t } = useTranslation();

  const { config } = useConfig();

  const { indexUiState, setIndexUiState } = useInstantSearch();

  /**
   *
   * Filter categories
   *
   */

  let filtered = items;
  if (attribute === "categories") {
    filtered = config.categories.items.map((item) => ({
      title: item.title,
      id: item.slug,
    }));
    filtered = filtered.filter((item) =>
      item?.title.toLowerCase().includes(query.toLowerCase()),
    );
  } else {
    filtered = filtered.map((item) => ({ title: item.label, id: item.value }));
  }

  filtered = filtered.filter(
    (item) =>
      !(indexUiState.refinementList?.[attribute] ?? []).includes(item.id),
  );

  const navigate = useNavigate();

  const handleClick = (item) => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    setIndexUiState((prev) => {
      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          [attribute]: [...(prev.refinementList?.[attribute] ?? []), item.id],
        },
      };
    });
  };

  if (filtered.length === 0) {
    return null;
  }

  return (
    <SearchHeaderDropdownGroup
      title={t(`browse.top.taxonomy.title.${attribute}`)}
      items={filtered.map((item) => ({ ...item, attribute: attribute }))}
      handleClick={handleClick}
    />
  );
};

export const SearchDesktop = ({ query, setQuery }) => {
  const [visible, setVisible] = useState(false);
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <Box sx={{ position: "relative" }}>
        <SearchHeader onClick={() => setVisible(true)} open={visible}>
          <SearchActiveFilters shorted={true} />
          <SearchInput query={query} setQuery={setQuery} />
        </SearchHeader>
        <SearchHeaderDropdown open={visible} handleClose={handleClose}>
          <SearchActiveFilters />
          <SearchRefinementList query={query} attribute={"categories"} />
          <SearchRefinementList query={query} attribute={"tags"} limit={15} />
        </SearchHeaderDropdown>
      </Box>
    </ClickAwayListener>
  );
};

export const SearchMobile = ({ query, setQuery }) => {
  const [visible, setVisible] = useState(false);
  const { geo } = useUser();
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <SearchHeader onClick={() => setVisible(true)} open={visible}>
        <SearchActiveFilters shorted={true} />
        <SearchInput query={query} setQuery={setQuery} />
        <Box sx={{ position: "absolute", right: 8 }}>
          <SearchIcon color="primary" />
          {geo.selected && <WhereToVoteIcon color="primary" />}
        </Box>
      </SearchHeader>
      <SearchHeaderDropdownMobile open={visible} handleClose={handleClose}>
        <ButtonClose onClick={handleClose} additionalStyles="mt-3 me-3" />
        <SearchInput query={query} setQuery={setQuery} type="solid" />
        <SearchLocation />
        <Box sx={{ marginTop: 1 }}>
          <SearchActiveFilters />
          <SearchRefinementList query={query} attribute={"categories"} />
          <SearchRefinementList query={query} attribute={"tags"} limit={15} />
          <Box
            sx={{
              position: "fixed",
              padding: 2,
              bottom: 0,
              left: 0,
              right: 0,
              background: "#fff",
              display: "flex",
              boxShadow: "0 0px 40px 0 rgba(0,0,0,0.1)",
              gap: 2,
            }}
          >
            <ButtonClear setVisible={setVisible} label={"Clear Filters"} />
            <ButtonBrowse
              query={query}
              setVisible={setVisible}
              label={"Show Results"}
            />
          </Box>
        </Box>
      </SearchHeaderDropdownMobile>
    </>
  );
};
