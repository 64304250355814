import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Slide,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import Avatar from "../../components/Avatar";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FooterDrawer from "./FooterDrawer";
import ButtonPrimary from "../../components/Buttons/Button";
import usePopup from "../../hooks/usePopup";
import ButtonStack from "../../components/Buttons/ButtonStack";
import { FooterPortalMobile } from "./FooterPortal";
import useQueryString from "../../hooks/useQueryString";

const FooterMobileNavigationWrapperButtons = ({ handleToggleDrawer }) => {
  const theme = useTheme();

  const styles = {
    explore: {
      marginRight: "auto",
      flexDirection: "row",
      gap: 1,
      "-webkit-flex": "none",
      flex: "none",
      marginLeft: 1,
      svg: {
        color: theme.palette.primary.main,
        fontSize: 26,
      },
      ".MuiBottomNavigationAction-label": {
        fontSize: "0.875rem",
        color: theme.palette.primary.main,
      },
      "&.Mui-selected": {
        svg: {
          color: theme.palette.secondary.main,
        },
        ".MuiBottomNavigationAction-label": {
          color: theme.palette.secondary.main,
        },
      },
    },
    icon: {
      maxWidth: "72px",
      minWidth: "72px",
      svg: {
        color: theme.palette.primary.main,
        fontSize: 32,
      },
      ".MuiBottomNavigationAction-label": {
        color: theme.palette.primary.main,
      },
      "&.Mui-selected": {
        svg: {
          color: theme.palette.secondary.main,
        },
      },
    },
  };

  const { user } = useUser();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const handleClickExplore = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/");
  };
  const handleClickAvatar = () => {
    if (user) {
      handleToggleDrawer();
    } else {
      navigate("/?auth-popup=register");
    }
  };
  const AvatarIcon = () => {
    if (user) {
      return (
        <Avatar
          src={user.avatar}
          alt={user.name}
          sx={{ width: 30, height: 30 }}
        />
      );
    }
    return <AccountCircleIcon sx={{ fontSize: 30 }} />;
  };
  return (
    <BottomNavigation
      showLabels
      sx={{ height: 72 }}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="Explore"
        icon={<GridViewOutlinedIcon />}
        sx={styles.explore}
        onClick={handleClickExplore}
      />
      <BottomNavigationAction
        showLabel={false}
        icon={<StarOutlineIcon />}
        sx={styles.icon}
        onClick={() => navigate("/observations")}
        selected={location.pathname.startsWith("/observations")}
      ></BottomNavigationAction>
      <BottomNavigationAction
        showLabel={false}
        icon={<ChatBubbleOutlineIcon />}
        sx={styles.icon}
        onClick={() => navigate("/messages")}
        selected={location.pathname.startsWith("/messages")}
      ></BottomNavigationAction>
      <BottomNavigationAction
        showLabel={false}
        icon={<AvatarIcon />}
        sx={styles.icon}
        onClick={handleClickAvatar}
      ></BottomNavigationAction>
    </BottomNavigation>
  );
};

const FooterMobileNavigationWrapper = () => {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  return (
    <>
      {user && <FooterDrawer open={open} setOpen={setOpen} />}
      <FooterMobileNavigationWrapperButtons
        handleToggleDrawer={() => setOpen((prev) => !prev)}
      />
    </>
  );
};

const FooterMobileNavigationGuestButtons = ({ isScrolled }) => {
  const { addQuery } = useQueryString();

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        justifyContent: "center",
        bottom: 0,
        transition: "all 200ms ease-in-out",
        transform: isScrolled ? "translateY(-72px)" : "translateY(0)",
      }}
      p={2}
    >
      <ButtonStack>
        <ButtonPrimary
          onClick={() => addQuery("auth-popup", "login")}
          size={"large"}
          sx={{ minWidth: 100, fontWeight: 200 }}
        >
          Sign in
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => addQuery("auth-popup", "register")}
          color={"secondary"}
          size={"large"}
          sx={{ minWidth: 100, fontWeight: 300 }}
        >
          Join
        </ButtonPrimary>
      </ButtonStack>
    </Box>
  );
};

const FooterMobileNavigation = () => {
  const { user } = useUser();
  const footerRef = useRef(null);
  const [showSlide, setShowSlide] = useState(true);
  const trigger = useScrollTrigger({
    threshold: 0,
  });
  useEffect(() => {
    const handleScroll = () => {
      setShowSlide(window.scrollY === 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {!user && (
        <FooterMobileNavigationGuestButtons
          isScrolled={trigger || (showSlide && !trigger)}
        />
      )}
      {/* Footer Portal */}
      <FooterPortalMobile isScrolled={trigger || (showSlide && !trigger)} />
      <Slide
        appear={false}
        direction="up"
        in={trigger || (showSlide && !trigger)}
      >
        <Box
          ref={footerRef}
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 20,
            backgroundColor: "#fff",
            boxShadow: "0 -10px 45px 0px rgba(150, 138, 186, 0.2)",
          }}
        >
          <FooterMobileNavigationWrapper />
        </Box>
      </Slide>
    </>
  );
};

export default FooterMobileNavigation;
