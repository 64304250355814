import Container from "../components/Page/Container";
import ProfileHeader from "../views/Profile/ProfileHeader";
import React, { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import ProfileGallery from "../views/Profile/ProfileGallery";
import ProfileContent from "../views/Profile/ProfileContent";
import { Box, Skeleton } from "@mui/material";
import SingleSpeedDial from "../views/Account/Parts/SingleSpeedDial";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Text from "../components/Typography/Text";
import ButtonPrimary from "../components/Buttons/Button";
import { useNavigate } from "react-router";

const SkeletonLoading = () => {
  return (
    <Grid container spacing={4} mt={2}>
      <Grid size={{ md: 4, sm: 12, xs: 12 }}>
        <Skeleton
          variant="rounded"
          width={"100%"}
          sx={{ paddingTop: "100%" }}
        />
        <Skeleton
          sx={{ marginTop: 2 }}
          variant="rounded"
          width={"100%"}
          height={40}
        />
        <Box mt={2} display={"flex"} gap={1}>
          <Skeleton
            variant="rounded"
            width={"33%"}
            sx={{ paddingTop: "33%" }}
          />
          <Skeleton
            variant="rounded"
            width={"33%"}
            sx={{ paddingTop: "33%" }}
          />
          <Skeleton
            variant="rounded"
            width={"33%"}
            sx={{ paddingTop: "33%" }}
          />
        </Box>
      </Grid>
      <Grid size={{ md: 8, sm: 12, xs: 12 }}>
        <Skeleton variant="rounded" width={150} height={20} />
        <Skeleton
          variant="rounded"
          width={250}
          height={20}
          sx={{ marginTop: 1 }}
        />
        <Box mt={1} display={"flex"} gap={1}>
          <Skeleton variant="rounded" width={"10%"} height={20} />
          <Skeleton variant="rounded" width={"25%"} height={20} />
          <Skeleton variant="rounded" width={"10%"} height={20} />
          <Skeleton variant="rounded" width={"35%"} height={20} />
          <Skeleton variant="rounded" width={"20%"} height={20} />
        </Box>
        <Box mt={1} display={"flex"} gap={1}>
          <Skeleton variant="rounded" width={"30%"} height={20} />
          <Skeleton variant="rounded" width={"5%"} height={20} />
          <Skeleton variant="rounded" width={"15%"} height={20} />
          <Skeleton variant="rounded" width={"35%"} height={20} />
          <Skeleton variant="rounded" width={"15%"} height={20} />
        </Box>
        <Skeleton
          sx={{ marginTop: 2 }}
          variant="rounded"
          width={"100%"}
          height={200}
        />
        <Box mt={2}>
          <Skeleton variant="rounded" width={150} height={20} />
          <Box mt={1} display={"flex"} gap={1}>
            <Skeleton variant="rounded" width={"20%"} height={30} />
            <Skeleton variant="rounded" width={"35%"} height={30} />
            <Skeleton variant="rounded" width={"30%"} height={30} />
            <Skeleton variant="rounded" width={"15%"} height={30} />
          </Box>
        </Box>
        <Box mt={2}>
          <Skeleton variant="rounded" width={150} height={20} />
          <Box mt={1} display={"flex"} gap={1}>
            <Skeleton variant="rounded" width={"15%"} height={30} />
            <Skeleton variant="rounded" width={"40%"} height={30} />
            <Skeleton variant="rounded" width={"25%"} height={30} />
            <Skeleton variant="rounded" width={"20%"} height={30} />
          </Box>
        </Box>
        <Box mt={2}>
          <Skeleton variant="rounded" width={150} height={20} />
          <Box mt={1} display={"flex"} gap={1}>
            <Skeleton variant="rounded" width={"40%"} height={30} />
            <Skeleton variant="rounded" width={"30%"} height={30} />
            <Skeleton variant="rounded" width={"30%"} height={30} />
          </Box>
        </Box>
        <Box mt={2}>
          <Skeleton variant="rounded" width={150} height={20} />
          <Box mt={1} display={"flex"} gap={1}>
            <Skeleton variant="rounded" width={"10%"} height={30} />
            <Skeleton variant="rounded" width={"20%"} height={30} />
            <Skeleton variant="rounded" width={"20%"} height={30} />
            <Skeleton variant="rounded" width={"40%"} height={30} />
            <Skeleton variant="rounded" width={"10%"} height={30} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const Profile = () => {
  let { id } = useParams();

  const { request } = useApi();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [profileData, setProfileData] = useState();

  useEffect(() => {
    setLoading(true);

    const controller = new AbortController();

    request({
      method: "get",
      url: id ? `/user/${id}` : "/profile/preview",
      onSuccess: (data) => {
        setProfileData(data);
      },
      onError: () => {
        setProfileData(null);
      },
    }).finally(() => {
      setLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [id]);

  return (
    <Container sx={{ marginTop: 3 }}>
      {loading ? (
        <SkeletonLoading />
      ) : (
        <>
          {profileData ? (
            <>
              <ProfileHeader profile={profileData} />
              <Grid container spacing={4} mt={2}>
                <Grid size={{ md: 4, sm: 12 }}>
                  <ProfileGallery profile={profileData} />
                </Grid>
                <Grid size={{ md: 8, sm: 12 }}>
                  <ProfileContent details={profileData.details} />
                </Grid>
              </Grid>
              <SingleSpeedDial item={profileData.details} />
            </>
          ) : (
            <Box
              mb={5}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <ErrorOutlineOutlinedIcon
                color={"primary"}
                sx={{ width: 80, height: 80 }}
              />
              <Text
                text={"User not found"}
                variant={"body1"}
                sx={{ fontSize: 32 }}
                mb={1}
              />
              <ButtonPrimary onClick={() => navigate("/")}>
                Back to homepage
              </ButtonPrimary>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default Profile;
