import React, { useState } from "react";
import Config from "../../../config/appConfig";
import useUser from "../../../hooks/useUser";
import { ButtonInput } from "../../../components/Buttons/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Box, MenuItem } from "@mui/material";

const getRanges = (key = null) => {
  if (key !== null) {
    return Config.ranges[key];
  }

  return Config.ranges;
};

const SearchRadius = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { geo, handleSetGeo } = useUser();

  const handleClickRange = (radius) => {
    handleSetGeo({ ...geo, radius: radius });
  };

  const mappedRadius = Object.keys(getRanges());

  if (geo.lat == null) {
    return null;
  }

  return (
    <Box {...props}>
      <ButtonInput size={"large"} onClick={handleClick}>
        <span>+ {geo.radius} miles</span>
      </ButtonInput>
      <Dropdown anchor={anchorEl} open={open} handleClose={handleClose}>
        {mappedRadius.map((radius, index) => (
          <MenuItem key={index} onClick={() => handleClickRange(radius)}>
            {" "}
            {radius} {radius === "1" ? "mile" : "miles"}
          </MenuItem>
        ))}
      </Dropdown>
    </Box>
  );
};

export default SearchRadius;
