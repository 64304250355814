import { useDispatch, useSelector } from "react-redux";
import {
  createPopup,
  hidePopup,
  popupVisible,
  showPopup,
} from "../services/redux/slices/popupSlice";

const usePopup = () => {
  const dispatch = useDispatch();

  const popupContent = useSelector((state) => state.popup.popupContent);
  const popupProps = useSelector((state) => state.popup.popupProps);
  const popupsContent = useSelector((state) => state.popup.popupsContent);

  const addPopup = (component, name) => {
    dispatch(createPopup({ component, name }));
  };

  const openPopup = (component, props) => {
    dispatch(showPopup({ component, props }));
  };

  const changeVisible = (name, visible) => {
    dispatch(popupVisible({ name, visible }));
  };

  const closePopup = () => {
    dispatch(hidePopup());
  };

  return {
    popupContent,
    popupProps,
    popupsContent,
    addPopup,
    openPopup,
    closePopup,
    changeVisible,
  };
};

export default usePopup;
