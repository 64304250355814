import { Menu, useTheme } from "@mui/material";

const Dropdown = ({ children, anchor, handleClose, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableScrollLock
      sx={{
        "& .MuiPaper-root": {
          marginTop: theme.spacing(1),
          borderRadius: theme.spacing(2),
          boxShadow: "0 10px 50px 10px rgba(150, 138, 186, 0.2)",
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default Dropdown;
