import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAuthSession } from "aws-amplify/auth";
import { formatDate } from "../../../utils/appUtils";

const initialState = {
  pristine: true,
  connected: null,
  handlers: {},
  socket: null,
  error: null,
};

export const connectWebSocket = createAsyncThunk(
  "webSocket/connect",
  async (_, { dispatch, getState }) => {
    let accessToken = "";

    try {
      const { tokens } = await fetchAuthSession();
      accessToken = tokens.accessToken;

      const socket = new WebSocket(
        `${process.env.REACT_APP_CHAT_SOCKET}?token=${accessToken}`,
      );

      let pingInterval;

      socket.onopen = () => {
        console.log("WebSocket connected at time:", formatDate());
        //
        pingInterval = setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ action: "ping" }));
          }
        }, 1000 * 30);
        //
        dispatch(setOnline());
      };

      socket.onclose = () => {
        console.error("WebSocket disconnected at time:", formatDate());
        //
        clearInterval(pingInterval);
        if (socket.readyState !== WebSocket.CLOSED) {
          socket.close();
        }
        //
        dispatch(setOffline());
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        dispatch(setOffline());
        dispatch(setError(error.message));
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const actionHandlers = getState().webSocket.handlers[data.action] || [];
        actionHandlers.forEach((handler) => handler(data));
      };

      return socket;
    } catch (err) {
      console.log("Websocket inactive");
      dispatch(setOffline());

      return null;
    }
  },
);

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setOffline(state) {
      state.connected = false;
      state.pristine = false;
      state.socket = null;
    },
    setOnline(state) {
      state.connected = true;
      state.pristine = false;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSocket(state, action) {
      state.socket = action.payload;
    },
    registerHandler(state, action) {
      const { actionType, handler } = action.payload;
      if (!state.handlers[actionType]) {
        state.handlers[actionType] = [];
      }
      state.handlers[actionType].push(handler);
    },
    unregisterHandler(state, action) {
      const { actionType, handler } = action.payload;
      if (state.handlers[actionType]) {
        state.handlers[actionType] = state.handlers[actionType].filter(
          (h) => h !== handler,
        );
      }
    },
    sendAction(state, action) {
      const message = action.payload;
      if (state.socket && state.socket.readyState === WebSocket.OPEN) {
        state.socket.send(message);
      } else {
        console.error(
          "WebSocket is not open. Unable to send message:",
          message,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectWebSocket.fulfilled, (state, action) => {
      state.socket = action.payload;
    });
    builder.addCase(connectWebSocket.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const {
  setOffline,
  setOnline,
  setError,
  registerHandler,
  unregisterHandler,
  sendAction,
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
