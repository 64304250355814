import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import ButtonPrimary, { ButtonClose } from "../../components/Buttons/Button";

const FooterDrawerContentLink = ({ item, setOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(item.link);

    setTimeout(() => {
      setOpen(false);
    }, 100);
  };
  return (
    <ListItem disablePadding onClick={handleClick}>
      <ListItemButton>
        <ListItemText
          primary={item.text}
          sx={{
            textAlign: "right",
            ".MuiListItemText-primary": {
              fontSize: theme.typography.lg,
              color: theme.palette.primary.main,
              marginY: 1,
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const FooterDrawerContentLogout = ({ setOpen }) => {
  const { handleLogout } = useUser();

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    await handleLogout();

    setLoading(false);
  };

  return (
    <ButtonPrimary
      variant={"outlined"}
      fullWidth
      onClick={handleClick}
      loading={loading}
    >
      Log out
    </ButtonPrimary>
  );
};

const FooterDrawerContent = ({ open, setOpen }) => {
  const { user, handleLogout } = useUser();
  const links = {
    seller: [
      {
        text: "Preview profile",
        link: "account",
      },
      {
        text: "Edit profile",
        link: "account/edit",
      },
      {
        text: "Account type",
        link: "account/settings",
      },
      {
        text: "Security",
        link: "account/settings?tab-index=1",
      },
      {
        text: "Hidden profiles",
        link: "account/settings?tab-index=2",
      },
      {
        text: "Plans",
        link: "account/settings?tab-index=3",
      },
      {
        text: "Billing",
        link: "account/settings?tab-index=4",
      },
    ],
    buyer: [
      {
        text: "Settings",
        link: "account/settings",
      },
    ],
  };

  return (
    <Box
      sx={{ width: 250, height: "100%" }}
      role="presentation"
      display={"flex"}
      flexDirection={"column"}
    >
      <ButtonClose
        sx={{ position: "absolute", top: 12, right: 12 }}
        size={"sm"}
        onClick={() => setOpen(false)}
      />
      <Box mt={5}>
        <List>
          {(user.account_type === "searcher" ? links.buyer : links.seller).map(
            (item, index) => (
              <FooterDrawerContentLink
                key={index}
                item={item}
                setOpen={setOpen}
              />
            ),
          )}
        </List>
      </Box>
      <Divider />
      <Box p={2} mt={"auto"}>
        <FooterDrawerContentLogout setOpen={setOpen} />
      </Box>
    </Box>
  );
};

const FooterDrawer = ({ open = false, setOpen = () => {} }) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"}>
      <FooterDrawerContent open={open} setOpen={setOpen} />
    </Drawer>
  );
};

export default FooterDrawer;
