import Image from "../../old_components/Browse/Thumbnail/Image";
import Video from "../../old_components/Browse/Thumbnail/Video/Video";
import placeholderImage from "../../assets/images/placeholder.jpg";
import React, { useState } from "react";
import ButtonPrimary from "../../components/Buttons/Button";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Lightbox from "../Account/Parts/Lightbox";

const Thumbnail = ({ autoplay, controls, fit, muted, loop, thumbnail }) => {
  if (thumbnail.source === null || thumbnail.source === "") {
    return (
      <Box>
        <img
          width={"100%"}
          style={{ borderRadius: "8px" }}
          src={placeholderImage}
          alt={placeholderImage}
        />
      </Box>
    );
  }

  if (
    ["image/jpg", "image/jpeg", "image/png", "image/webp"].includes(
      thumbnail.resource_type,
    )
  ) {
    return <Image src={thumbnail.source} />;
  }

  return (
    <Video
      src={thumbnail.source}
      autoplay={autoplay}
      loop={loop}
      muted={muted}
      duration={thumbnail.duration}
      controls={controls}
    />
  );
};

const Contact = ({ profile }) => {
  const contactMethods = profile.details.contact_methods;

  const [contactVisible, setContactVisible] = useState(false);

  if (
    !contactMethods.direct &&
    !contactMethods.phone &&
    !contactMethods.email
  ) {
    return;
  }

  return (
    <Box mt={2}>
      <ButtonPrimary
        variant={contactVisible ? "outlined" : "contained"}
        onClick={() => setContactVisible((prevState) => !prevState)}
        fullWidth
      >
        Contact
      </ButtonPrimary>
      {contactVisible && (
        <Box mt={1} display={"flex"} flexDirection={"column"} gap={1}>
          {contactMethods.direct && (
            <ButtonPrimary variant={"outlined"} fullWidth>
              Direct message
            </ButtonPrimary>
          )}
          {contactMethods.phone && (
            <ButtonPrimary variant={"outlined"} fullWidth>
              Phone: {contactMethods.phone}
            </ButtonPrimary>
          )}
          {contactMethods.email && (
            <ButtonPrimary variant={"outlined"} fullWidth>
              Email: {contactMethods.email}
            </ButtonPrimary>
          )}
        </Box>
      )}
    </Box>
  );
};

const Gallery = ({ gallery }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleImageClick = (image) => {
    setOpened(true);
    setSelectedImage(image);
  };

  return (
    <>
      <Grid container spacing={2} mt={2}>
        {gallery.map((image, index) => (
          <Grid size={4} key={index} onClick={() => handleImageClick(image)}>
            <img
              style={{ borderRadius: "8px" }}
              width={"100%"}
              src={image.imageLink}
            />
          </Grid>
        ))}
      </Grid>
      {selectedImage && (
        <Lightbox
          images={gallery}
          initialIndex={gallery.indexOf(selectedImage)}
          opened={opened}
          onClose={() => setOpened(false)}
        />
      )}
    </>
  );
};

const ProfileGallery = ({ profile }) => {
  return (
    <>
      <Thumbnail thumbnail={profile.thumbnail} autoplay={false} />
      <Contact profile={profile} />
      <Gallery gallery={profile.gallery} />
    </>
  );
};

export default ProfileGallery;
