import { AutocompleteProfile } from "../../../components/Autocompletes/AutocompleteProfile";
import React from "react";
import useConfig from "../../../hooks/useConfig";
import { useFormikContext } from "formik";
import { Box } from "@mui/material";
import useError from "../../../hooks/useError";
import Text from "../../../components/Typography/Text";
import { InformationIcon } from "../../../pages/account/edit";

const AttributesView = () => {
  const { config } = useConfig();

  const { values, setFieldValue } = useFormikContext();

  const { detailsError } = useError();

  return (
    <>
      <Box mb={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          mb={1}
          alignItems={"center"}
        >
          <Text text={"Character Traits"} variant={"h3"} />
          <InformationIcon
            title={"Attributes"}
            text={
              "In this section, you can add your character traits and personal interests to give clients a better sense of who you are. Your traits help highlight your work ethic, personality, and how you approach tasks. Including your interests allows clients to connect with you on a more personal level, creating a stronger relationship. Both sections are optional but can help make your profile more engaging and relatable."
            }
          />
        </Box>
        <AutocompleteProfile
          options={config.character.items}
          getOptionLabel={(option) => option}
          value={values.character}
          limit={3}
          chipError={detailsError.character}
          ownValue={true}
          onChange={(event, newValue) => {
            setFieldValue(
              "character",
              newValue.map((item) => item),
            );
          }}
          name={"character"}
        />
      </Box>
      <AutocompleteProfile
        title={"Interests"}
        options={config.interests.items}
        getOptionLabel={(option) => option}
        value={values.interests}
        limit={3}
        chipError={detailsError.interests}
        ownValue={true}
        onChange={(event, newValue) => {
          setFieldValue(
            "interests",
            newValue.map((item) => item),
          );
        }}
        name={"interests"}
      />
    </>
  );
};

export default AttributesView;
