import { useNavigate } from "react-router-dom";
import React from "react";
import useUser from "../../hooks/useUser";
import usePopup from "../../hooks/usePopup";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Navigation, NavigationItem } from "../../components/Page/Navigation";
import { Stack } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";
import { UserPanel } from "../../components/Page/UserPanel";
import useQueryString from "../../hooks/useQueryString";

const GuestPanel = () => {
  const { addQuery } = useQueryString();

  return (
    <Stack direction={"row"} spacing={1}>
      <ButtonPrimary
        variant={"outlined"}
        onClick={() => addQuery("auth-popup", "login")}
        sx={{ minHeight: 44, paddingX: 2 }}
      >
        SIGN IN
      </ButtonPrimary>
      <ButtonPrimary
        variant={"contained"}
        onClick={() => addQuery("auth-popup", "register")}
        sx={{ minHeight: 44, paddingX: 2 }}
      >
        JOIN
      </ButtonPrimary>
    </Stack>
  );
};

const HeaderUserPanel = () => {
  const { user, handleLogout } = useUser();

  const links = {
    buyer: [
      {
        text: "Settings",
        link: "account/settings",
      },
    ],
    seller: [
      {
        text: "Preview profile",
        link: "account",
      },
      {
        text: "Edit Profile",
        link: "account/edit",
      },
      {
        text: "Account type",
        link: "account/settings",
      },
      {
        text: "Security",
        link: "account/settings?tab-index=1",
      },
      {
        text: "Hidden profiles",
        link: "account/settings?tab-index=2",
      },
      {
        text: "Plans",
        link: "account/settings?tab-index=3",
      },
      {
        text: "Billing",
        link: "account/settings?tab-index=4",
      },
    ],
  };

  if (!user) {
    return <GuestPanel />;
  }

  return (
    <UserPanel
      user={user}
      links={user.account_type === "provider" ? links.seller : links.buyer}
      handleLogout={handleLogout}
    />
  );
};

const HeaderNavigation = () => {
  const { user, unread } = useUser();
  const { addQuery } = useQueryString();
  const navigate = useNavigate();

  const links = [
    {
      icon: GridViewOutlinedIcon,
      text: "Explore",
      link: "/",
    },
    {
      icon: StarOutlineIcon,
      text: "Favorites",
      link: "/observations",
    },
    {
      icon: ChatBubbleOutlineIcon,
      text: "Inbox",
      link: "/messages",
    },
  ];

  const handleClick = (link) => {
    if (!user && link !== "/") {
      return addQuery("auth-popup", "login");
    }
    navigate(link);
  };

  return (
    <Navigation
      ml={"auto"}
      sx={{
        display: { xs: "none", sm: "none", md: "flex" },
      }}
    >
      {links.map((item, index) => (
        <NavigationItem
          key={index}
          onClick={() => handleClick(item.link)}
          Icon={item.icon}
          badge={item.link === "/messages" && unread.messages !== 0}
        >
          {item.text}
        </NavigationItem>
      ))}
      <HeaderUserPanel />
    </Navigation>
  );
};

export default HeaderNavigation;
