import { useDispatch, useSelector } from "react-redux";
import { connectWebSocket, registerHandler, sendAction, unregisterHandler } from "../services/redux/slices/webSocketSlice";
import { useEffect, useState } from "react";
import useUser from "./useUser";
import { useSearchParams } from "react-router-dom";
import notificationSound from "../assets/mp3/notification.mp3";
import { useTitleFlash } from "./useNotifications";

/**
 *
 * Hook for handling websocket connections
 *
 */
export const useWebSocket = () => {
  const dispatch = useDispatch();

  const { connected, socket, handlers, error, pristine } = useSelector(
    (state) => state.webSocket,
  );

  const connect = () => {
    dispatch(connectWebSocket());
  };

  const register = (actionType, handler) => {
    dispatch(registerHandler({ actionType, handler }));
  };

  const unregister = (actionType, handler) => {
    dispatch(unregisterHandler({ actionType, handler }));
  };

  const send = (message) => {
    dispatch(sendAction(message));
  };

  const requests = () => {
    const getConversations = (last) => {
      const params = {
        action: "getConversations",
        data: {
          last: last,
        },
      };
      send(JSON.stringify(params));
    };

    const getConversation = (receiver_id) => {
      const params = {
        action: "getConversation",
        data: {
          id: receiver_id,
        },
      };
      send(JSON.stringify(params));
    };

    const getConversationHistory = (receiver_id, last = null) => {
      const params = {
        action: "getConversationHistory",
        data: {
          id: receiver_id,
          last: last,
        },
      };
      send(JSON.stringify(params));
    };

    const archiveConversation = (receiver_id) => {
      const params = {
        action: "archiveConversation",
        data: {
          id: receiver_id,
        },
      };
      send(JSON.stringify(params));
    };

    const getUnreadCount = () => {
      const params = {
        action: "getUnreadCount",
      };
      send(JSON.stringify(params));
    };

    const sendMessage = (receiver_id, message) => {
      const params = {
        action: "sendMessage",
        data: {
          message: message,
          id: receiver_id,
        },
      };
      send(JSON.stringify(params));
    };

    const resetConversation = () => {
      const params = {
        action: "resetConversation",
      };
      send(JSON.stringify(params));
    };

    return {
      getConversations,
      getConversation,
      getConversationHistory,
      archiveConversation,
      getUnreadCount,
      sendMessage,
      resetConversation,
    };
  };

  return {
    pristine,
    connected,
    socket,
    handlers,
    error,
    connect,
    register,
    unregister,
    send,
    requests: requests(),
  };
};

/**
 *
 * Hook for connecting to the websocket
 * and handling reconnection
 *
 */
export const useWebSocketConnector = () => {
  const { connect, connected, pristine } = useWebSocket();
  const { user } = useUser();

  const MAX_RETRY_DELAY = 32000;
  const [retryDelay, setRetryDelay] = useState(1000);
  const [isLost, setIsLost] = useState(1000);

  useEffect(() => {
    if (!connected) {
      connect();
    }
  }, []);

  useEffect(() => {
    setIsLost(!connected && !pristine && user);
  }, [user, connected, pristine]);

  useEffect(() => {
    if (!connected) {
      const timeout = setTimeout(() => {
        setRetryDelay((prev) => Math.min(prev * 2, MAX_RETRY_DELAY));
        connect();
      }, retryDelay);

      return () => clearTimeout(timeout);
    } else {
      setRetryDelay(1000);
    }
  }, [retryDelay, connected]);

  return { isLost };
};

/**
 *
 * Hook for handling websocket notifications
 *
 */
export const useWebSocketNotifications = () => {
  const { user, handleSetUnread, unread } = useUser();
  const { register, unregister, connected, requests } = useWebSocket();
  const [searchParams] = useSearchParams();
  const sound = new Audio(`${notificationSound}`);
  const { setTitle } = useTitleFlash();

  useEffect(() => {
    if (!user) return;

    const handleUnreadConversations = (response) => {
      handleSetUnread({ messages: response.data.count });
    };

    register("unreadConversations", handleUnreadConversations);

    return () => {
      unregister("unreadConversations", handleUnreadConversations);
    };
  }, [user]);

  /**
   *
   * Get unread messages count from server when socket has been reconnected
   *
   */

  useEffect(() => {
    if (connected && user) {
      requests.getUnreadCount();
    }
  }, [connected, user]);
  /**
   *
   * Listen for new messages
   *
   */
  useEffect(() => {
    const handleNewMessage = (response) => {
      if (
        response.data.senderId !== searchParams.get("uid") &&
        response.data.senderId !== user.id
      ) {
        sound.play().catch(() => {});
        handleSetUnread({ messages: response.data.count });
        setTitle(`${unread.messages + 1} New messages`);
      }
    };
    register("newMessage", handleNewMessage);
    return () => {
      unregister("newMessage", handleNewMessage);
    };
  }, [connected, user, searchParams.get("uid")]);
};

export default useWebSocket;
