import React, { useState } from "react";

import { Box, CircularProgress, Paper, useTheme } from "@mui/material";

export const Image = ({ src, isError }) => {
  const [loading, setLoading] = useState(true);

  const theme = useTheme();

  return (
    <Box
      border={isError ? `1px solid ${theme.palette.error.main}` : ""}
      borderRadius={2}
    >
      {loading && (
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <CircularProgress size={40} />
        </Box>
      )}

      <img
        width={"100%"}
        src={src}
        style={{ borderRadius: "8px" }}
        alt={src}
        onLoad={() => setLoading(false)}
      />
    </Box>
  );
};

export default Image;
