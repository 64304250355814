import { useState } from "react";
import useUser from "./useUser";
import useApi from "./useApi";

const useHide = (uuid) => {
  const { request } = useApi();

  const [loading, setLoading] = useState();

  const { user, handleSetUser } = useUser() || {};

  const isHidden =
    user && "hidden_profiles" in user && user.hidden_profiles.includes(uuid);

  /**
   *
   * Handle change hide of item
   *
   */

  const handleHide = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    request({
      method: "post",
      url: `/profile/hide/${uuid}`,
      onSuccess: (data) => {
        handleSetUser({
          ...user,
          hidden_profiles: user.hidden_profiles
            .filter((el) => el !== uuid)
            .concat(data.status === "created" ? uuid : []),
        });
      },
    }).then(() => setLoading(false));
  };

  /**
   *
   * Display Hide Button
   *
   * @param item
   * @param useText
   * @param Hidden
   * @constructor
   *
   */

  return {
    loading,
    handleHide,
    isHidden,
  };
};

export default useHide;
