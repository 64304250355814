import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import InputCode from "../../components/Forms/InputCode";
import { Box } from "@mui/material";
import ButtonPrimary from "../../components/Buttons/Button";

const AuthForgotVerifyView = ({ verifyCodeResend, setPopupActive }) => {
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const verifyForgotCode = (e) => {
    setLoading(true);
    e.preventDefault();
    if (code.length === 6) {
      setLoading(false);
      localStorage.setItem("forgot-verify-code", code);
      setPopupActive("new-password");
    }
  };

  return (
    <form onSubmit={verifyForgotCode}>
      <Box mt={2} mb={10}>
        <InputCode code={code} setCode={setCode} />
        <Text
          mt={2}
          text={verifyCodeResend.text}
          color={verifyCodeResend.isError ? "error" : "normal"}
          variant={"body2"}
        />
      </Box>
      <ButtonPrimary
        loading={loading}
        type={"submit"}
        fullWidth
        disabled={code.length !== 6}
      >
        Confirm
      </ButtonPrimary>
      <Box
        display={"flex"}
        justifyContent={"center"}
        mt={1}
        onClick={() => setPopupActive("forgot")}
      >
        <Text text={"back"} variant={"overline"} color={"#000"} />
      </Box>
    </form>
  );
};

export default AuthForgotVerifyView;
