import Modal from "../../components/Modals/Modal";
import { Box, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import authImage1 from "../../assets/images/auth/1.webp";
import authImage2 from "../../assets/images/auth/2.webp";
import authImage3 from "../../assets/images/auth/3.webp";
import authImage4 from "../../assets/images/auth/4.webp";
import authImage5 from "../../assets/images/auth/5.webp";
import authImage6 from "../../assets/images/auth/6.webp";
import authImage7 from "../../assets/images/auth/7.webp";
import authImage8 from "../../assets/images/auth/8.webp";
import authImage9 from "../../assets/images/auth/9.webp";
import authImage10 from "../../assets/images/auth/10.webp";
import Grid from "@mui/material/Grid2";
import Text from "../../components/Typography/Text";
import { ReactComponent as LogoMobile } from "../../assets/svg/logo-mobile.svg";
import AuthLoginView from "./AuthLoginView";
import AuthRegisterView from "./AuthRegisterView";
import AuthVerifyView from "./AuthVerifyView";
import useCountdown from "../../hooks/useCountdown";
import { resendSignUpCode, resetPassword } from "aws-amplify/auth";
import { hideEmail } from "../../utils/appUtils";
import AuthForgotView from "./AuthForgotView";
import AuthForgotVerifyView from "./AuthForgotVerifyView";
import AuthNewPasswordView from "./AuthNewPasswordView";
import useQueryString from "../../hooks/useQueryString";
import useUser from "../../hooks/useUser";

const AuthContentWrapper = ({ title, subtitle, additionalText, children }) => {
  return (
    <Box mt={3}>
      <Text text={title} variant={"h3"} sx={{ fontSize: 28 }} />
      <Text color={"#000"} text={subtitle} variant={"body1"} />
      <Text
        text={additionalText}
        variant={"body2"}
        sx={{ fontWeight: 500, color: "#000" }}
      />
      {children}
    </Box>
  );
};

const LeftSide = () => {
  const theme = useTheme();

  const images = [
    authImage1,
    authImage2,
    authImage3,
    authImage4,
    authImage5,
    authImage6,
    authImage7,
    authImage8,
    authImage9,
    authImage10,
  ];
  const getRandomImage = () =>
    images[Math.floor(Math.random() * images.length)];

  return (
    <Box
      height={"100%"}
      width={"100%"}
      sx={{
        backgroundImage: `url("${getRandomImage()}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        position: "relative",
      }}
    >
      <Box
        backgroundColor={theme.palette.primary.main}
        height={"100%"}
        width={"100%"}
        sx={{
          opacity: 0.8,
          position: "absolute",
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          transform: "translate(-50%, -50%)",
        }}
        top={"50%"}
        left={"50%"}
      >
        <LogoMobile width={75} height={75} />
      </Box>
      <Box sx={{ position: "absolute" }} bottom={20} left={20}>
        <Text
          text={"Offer yours"}
          sx={{ textTransform: "uppercase" }}
          color={theme.palette.white.main}
          variant={"h3"}
        />
        <Text
          text={"or search"}
          sx={{ textTransform: "uppercase", fontWeight: 600 }}
          color={theme.palette.white.main}
          variant={"h3"}
        />
        <Text
          text={"for services"}
          sx={{ textTransform: "uppercase" }}
          color={theme.palette.white.main}
          variant={"h3"}
        />
      </Box>
    </Box>
  );
};

const RightSide = ({ popupActive, setPopupActive }) => {
  const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();

  const theme = useTheme();

  const [verifyCodeResend, setVerifyCodeResend] = useState({
    text: "",
    isError: false,
  });

  const verifyResendCode = async () => {
    if (!isTimeoutRemaining) return;

    try {
      await resendSignUpCode({ username: localStorage.getItem("email") });
      startCountdown(20);
      setVerifyCodeResend({
        text: `The code has been sent to your email: ${hideEmail(localStorage.getItem("email"))}`,
        isError: false,
      });
    } catch (error) {
      setVerifyCodeResend({
        text: error.message,
        isError: true,
      });
    }
  };

  const forgotVerifyResendCode = async () => {
    if (!localStorage.getItem("forgot-email")) {
      setVerifyCodeResend(false);
      return;
    }
    try {
      await resetPassword({ username: localStorage.getItem("forgot-email") });
      startCountdown(20);
      setVerifyCodeResend({
        status: false,
        text: `The code has been sent to your email: ${hideEmail(localStorage.getItem("forgot-email"))}`,
      });
    } catch (err) {
      setVerifyCodeResend({
        status: true,
        text: err.message,
      });
    }
  };

  const authConfig = [
    {
      key: "register",
      component: (
        <AuthContentWrapper
          title={"Create a new account"}
          subtitle={
            <>
              Already have an account{" "}
              <Text
                onClick={() => setPopupActive("login")}
                text={"Sign in"}
                color={"#000"}
                variant={"overline"}
              />
            </>
          }
          additionalText={"by email"}
        >
          <AuthRegisterView setPopupActive={setPopupActive} />
        </AuthContentWrapper>
      ),
    },
    {
      key: "login",
      component: (
        <AuthContentWrapper
          title={"Sign in"}
          subtitle={
            <>
              Don’t have an account?{" "}
              <Text
                onClick={() => setPopupActive("register")}
                text={"Join"}
                color={"#000"}
                variant={"overline"}
              />
            </>
          }
          additionalText={"by email"}
        >
          <AuthLoginView setPopupActive={setPopupActive} />
        </AuthContentWrapper>
      ),
    },
    {
      key: "verify",
      component: (
        <AuthContentWrapper
          title={"Confirm your account"}
          subtitle={
            <>
              {isTimeoutRemaining ? (
                <>
                  You didnt get the code?{" "}
                  <Text
                    onClick={verifyResendCode}
                    text={"Resend"}
                    color={"#000"}
                    variant={"overline"}
                  />
                </>
              ) : (
                `Resend code again in ${timeLeft}`
              )}
            </>
          }
          additionalText={"Your verification code:"}
        >
          <AuthVerifyView
            setPopupActive={setPopupActive}
            verifyCodeResend={verifyCodeResend}
          />
        </AuthContentWrapper>
      ),
    },
    {
      key: "forgot",
      component: (
        <AuthContentWrapper
          title={"Don't remember your password?"}
          subtitle={"Recover your account password by entering your email"}
          additionalText={"Enter email:"}
        >
          <AuthForgotView setPopupActive={setPopupActive} />
        </AuthContentWrapper>
      ),
    },
    {
      key: "forgot-verify",
      component: (
        <AuthContentWrapper
          title={"Confirm email (Reset Password)"}
          subtitle={
            <>
              {isTimeoutRemaining ? (
                <>
                  You didnt get the code?{" "}
                  <Text
                    onClick={forgotVerifyResendCode}
                    text={"Resend"}
                    variant={"overline"}
                    color={"#000"}
                  />
                </>
              ) : (
                `Resend code again in ${timeLeft}`
              )}
            </>
          }
          additionalText={"Your verification code:"}
        >
          <AuthForgotVerifyView
            verifyCodeResend={verifyCodeResend}
            setPopupActive={setPopupActive}
          />
        </AuthContentWrapper>
      ),
    },
    {
      key: "new-password",
      component: (
        <AuthContentWrapper
          title={"New password"}
          subtitle={"Enter new password"}
          additionalText={"new password:"}
        >
          <AuthNewPasswordView setPopupActive={setPopupActive} />
        </AuthContentWrapper>
      ),
    },
  ];

  return (
    <Box pt={2} pb={2} pl={3} pr={3} height={"100%"}>
      {authConfig.map((authItem, index) => {
        if (authItem.key !== popupActive) {
          return;
        }
        return (
          <Box
            height={"100%"}
            key={index}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Box>{authItem.component}</Box>
            <Text
              mt={5}
              color={theme.palette.dark.secondary}
              text={
                <>
                  By becoming a member, you are consenting to adhere to the
                  JobMultiverse{" "}
                  <a
                    href={"https://site.jobmultiverse.com/terms/"}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{
                      color: theme.palette.dark.secondary,
                      textDecoration: "underline",
                    }}
                  >
                    Terms of Service
                  </a>{" "}
                  and to occasionally receive correspondence from us. Kindly
                  review our{" "}
                  <a
                    href={"https://site.jobmultiverse.com/privacy/"}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{
                      color: theme.palette.dark.secondary,
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>{" "}
                  for insights into how we handle your personal information.
                </>
              }
              variant={"h5"}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const AuthView = () => {
  const location = useLocation();

  const { removeQuery } = useQueryString();

  const { user } = useUser();

  const queryParams = new URLSearchParams(location.search);
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    if (queryParams.get("auth-popup") && !user) {
      setPopupActive(queryParams.get("auth-popup"));
    }
  }, [location.search]);

  const handleClose = () => {
    removeQuery("auth-popup");
    setPopupActive(false);
  };

  return (
    <Modal
      boxPadding={0}
      open={popupActive}
      boxWidth={800}
      onClose={handleClose}
    >
      <Grid container sx={{ height: "550px" }}>
        <Grid size={{ xs: 0, sm: 5 }} display={{ xs: "none", sm: "block" }}>
          <LeftSide />
        </Grid>
        <Grid size={{ xs: 12, sm: 7 }}>
          <RightSide
            setPopupActive={setPopupActive}
            popupActive={popupActive}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AuthView;
