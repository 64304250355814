import React, { useRef } from "react";
import Grid from "@mui/material/Grid2";
import { TextField } from "@mui/material";

const InputCode = ({ code, setCode }) => {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (e, index) => {
    const input = e.target;
    const prevInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    const newCode = [...code];
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(""));

    if (input.value === "") {
      if (prevInput?.current) {
        prevInput.current.focus();
      }
    } else if (nextInput?.current) {
      nextInput.current.focus();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleKeyDown = (e, index) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1),
      );
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      {Array.from({ length: 6 }).map((_, index) => (
        <Grid item key={index}>
          <TextField
            id={`verification-input-${index}`}
            value={code[index] || ""}
            onChange={(e) => handleChange(e, index)}
            inputProps={{ maxLength: 1 }}
            inputRef={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            variant="outlined"
            fullWidth={false}
            sx={{
              "& .MuiOutlinedInput-root": {
                width: 40,
                height: 64,
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: 22,
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InputCode;
