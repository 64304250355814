import React, { Suspense, useEffect } from "react";
import "./assets/main.scss";
import "simplebar-react/dist/simplebar.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Amplify } from "aws-amplify";
import AmplifyConfig from "./config/amplifyConfig";
import routes from "./routes";
import AlgoliaConfig from "./config/algoliaConfig";
import { InstantSearch } from "react-instantsearch";
import useConfig from "./hooks/useConfig";
import useUser from "./hooks/useUser";
import { Head } from "./views/Page";
import { AppLoader } from "./old_components/Parts/Loaders";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  VirtualConfigure,
  VirtualGeoSearch,
} from "./old_components/Browse/Filters/FiltersParts";
import { Offline } from "./views/Layouts/Offline";
import { NotificationProvider } from "./hooks/useNotifications";

Amplify.configure(AmplifyConfig);

const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL,
});

const Wrapper = ({ children }) => {
  const config = useConfig();
  const user = useUser();

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 991) {
      user.handleSetIsMobile(true);
    } else {
      user.handleSetIsMobile(false);
    }
  };

  useEffect(() => {
    config.loadConfig();

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    /**
     * Handle user if page is not offline and config loading is false
     */
    if (!config.offline || !config.loading) {
      user.handleRefresh();
    }
  }, [config.loading]);

  /**
   *
   */

  if (config.loading || user.loading) {
    return <AppLoader />;
  }

  /**
   *
   */

  if (config.offline) {
    return (
      <Offline Message={"Page offline"} Refresh={() => config.loadConfig()} />
    );
  }

  return (
    <>
      <Head />
      <div className={`main-page`}>{children}</div>
      <div id={`app-portal`}></div>
    </>
  );
};

const App = () => {
  return (
    <Suspense fallback={<AppLoader />}>
      <Wrapper>
        <NotificationProvider>
          <ToastContainer />
          <InstantSearch
            stalledSearchDelay={3000}
            indexName={AlgoliaConfig.index.main}
            searchClient={AlgoliaConfig.client}
            routing={AlgoliaConfig.routing}
          >
            <VirtualConfigure />
            <VirtualGeoSearch />
            <RouterProvider router={router} />
          </InstantSearch>
        </NotificationProvider>
      </Wrapper>
    </Suspense>
  );
};

export default App;
