import { Paper, Popper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/lab";

export const AutocompleteStyled = styled(Autocomplete)(
  ({ theme, disabled }) => ({
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
      {
        paddingRight: "10px",
      },
    "& .MuiAutocomplete-inputRoot": {
      padding: "2px 20px",
      borderRadius: "24px",
      backgroundColor: disabled ? "#f5f5f5" : "transparent",
      minHeight: "48px",
      minWidth: "15vw",
      borderWidth: "0px",
      ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: "13px",
      transform: "translate(14px, 15px)",
      color: "#AEAEAE",
    },
    "& .MuiInputLabel-shrink": {
      color: "#AEAEAE",
      transform: "translate(14px, -9px) scale(0.77)",
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
  }),
);

export const AutocompleteHeaderLocationPaper = (props) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);
  return (
    <Paper
      sx={{
        backgroundColor: "#fff",
        border: "none !important",
        borderRadius: 4,
        boxShadow: "0 10px 50px 10px #968aba33 !important",
        minHeight: "3rem",
        marginTop: 1,
        // animation
        transition: "all .3s linear, opacity .1s linear",
        opacity: visible ? 1 : 0,
        transform: visible ? "translateY(0)" : "translateY(-0.5rem)",
      }}
      {...props}
    >
      {props.children}
    </Paper>
  );
};

export const AutocompleteHeaderLocationPopper = (props) => {
  return <Popper {...props}>{props.children}</Popper>;
};
export default { AutocompleteHeaderLocationPaper };
