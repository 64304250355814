import React, { useEffect, useState } from "react";
import SearchLocation from "./SearchLocation";
import { useInstantSearch } from "react-instantsearch";
import { Box } from "@mui/material";
import { useDevice } from "../../../utils/appUtils";
import { ButtonBrowse } from "./SearchButtons";
import { SearchDesktop, SearchMobile } from "./SearchParts";

const Search = () => {
  const { indexUiState } = useInstantSearch();
  const { isMobile } = useDevice();
  const [query, setQuery] = useState(
    "query" in indexUiState && indexUiState.query !== undefined
      ? indexUiState.query
      : "",
  );

  useEffect(() => {
    setQuery(
      "query" in indexUiState && indexUiState.query !== undefined
        ? indexUiState.query
        : "",
    );
  }, [indexUiState]);

  if (isMobile) {
    return (
      <Box
        display="flex"
        sx={{
          flexDirection: "column",
          width: "100%",
        }}
      >
        <SearchMobile query={query} setQuery={setQuery} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      sx={{
        flexDirection: "row",
      }}
    >
      <SearchDesktop query={query} setQuery={setQuery} />
      <SearchLocation />
      <ButtonBrowse setQuery={setQuery} query={query} />
    </Box>
  );
};

export default Search;
