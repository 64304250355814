import React, { createContext, useContext, useState, useCallback } from "react";
import { api } from "./useApi";

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const { request } = api();

  const createState = () => ({
    data: null,
    error: null,
    loading: true,
  });

  const [subscriptionData, setSubscriptionData] = useState(createState());
  const [addressData, setAddressData] = useState(createState());
  const [invoicesData, setInvoicesData] = useState(createState());

  const handleRequest = useCallback(
    async (url, setData = null, method = "get", body = null) => {
      if (setData) {
        setData((prevState) => ({ ...prevState, loading: true }));
      }
      try {
        const payload = {
          url,
          method,
          onSuccess: (data) => {
            if (setData) {
              setData({
                data,
                error: null,
                loading: false,
              });
            }
          },
          onError: (error) => {
            if (setData) {
              setData({
                data: null,
                error,
                loading: false,
              });
            }
          },
        };
        if (body !== null) {
          payload.body = body;
        }

        await request(payload);
      } catch (err) {
        if (setData) {
          setData((prevState) => ({
            ...prevState,
            error: err.message,
            loading: false,
          }));
        }
      }
    },
    [request],
  );

  const getSubscriptionData = useCallback(
    () => handleRequest(`/billing/subscription/`, setSubscriptionData),
    [handleRequest],
  );

  const getBillingSubscriptionPlanID = useCallback(
    (planID) =>
      handleRequest(`/billing/subscription/${planID}`, setSubscriptionData),
    [handleRequest],
  );

  const postBillingPayment = useCallback(
    (data) =>
      handleRequest("/billing/payment", setAddressData, "post", {
        ...data,
      }),
    [handleRequest],
  );

  const getBillingAddress = useCallback(
    () => handleRequest(`/billing/address`, setAddressData),
    [handleRequest],
  );

  const postBillingAddress = useCallback(
    (data) => handleRequest("/billing/address", setAddressData, "post", data),
    [handleRequest],
  );

  const getBillingInvoices = useCallback(
    () => handleRequest(`/billing/invoices`, setInvoicesData),
    [handleRequest],
  );

  const deleteBillingSubscriptionPlanID = useCallback(
    (planID) =>
      handleRequest(
        `/billing/subscription/${planID}`,
        setSubscriptionData,
        "delete",
      ),
    [handleRequest],
  );

  return (
    <PaymentContext.Provider
      value={{
        subscriptionData,
        addressData,
        invoicesData,
        getSubscriptionData,
        getBillingSubscriptionPlanID,
        postBillingPayment,
        getBillingAddress,
        postBillingAddress,
        getBillingInvoices,
        deleteBillingSubscriptionPlanID,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePayment must be used within a PaymentProvider");
  }
  return context;
};
