import useError from "../../../hooks/useError";
import { Box, useTheme } from "@mui/material";
import Text from "../../../components/Typography/Text";
import { useState } from "react";
import Modal from "../../../components/Modals/Modal";

const ProfileStatus = () => {
  const theme = useTheme();
  const { indexStatusValue } = useError();

  const [modalVisible, setModalVisible] = useState(false);

  const alertConfig = {
    rejected: {
      text: "rejected",
      background: theme.palette.error.main,
      modalText:
        "Your profile has been rejected during the verification process. This may be due to incomplete information, inappropriate content, or other policy violations. Please review your profile, make the necessary updates, and resubmit for approval. If you need assistance, contact our support team.",
    },
    visible: {
      text: "approved",
      background: theme.palette.secondary.main,
      modalText:
        "Congratulations! Your profile has been approved and is now visible to potential clients. Make sure to keep it up to date to attract more opportunities.",
    },
    initial: {
      text: "pending",
      background: theme.palette.info.main,
      modalText:
        "Your profile is currently under review. It will remain hidden until approved by our team. The verification process typically takes up to 5 minutes. You’ll receive a notification once your profile is approved or if updates are needed.",
    },
    pending: {
      text: "pending",
      background: theme.palette.info.main,
      modalText:
        "Your profile is currently under review. It will remain hidden until approved by our team. The verification process typically takes up to 5 minutes. You’ll receive a notification once your profile is approved or if updates are needed.",
    },
    disabled: {
      text: "hidden",
      background: theme.palette.info.main,
      modalText:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent congue sem ut malesuada pellentesque. Ut tincidunt fringilla posuere. Duis quam mauris, commodo nec porta dictum, sodales id velit. Suspendisse nec lacinia lectus. Morbi pharetra tempor sem, ac consectetur risus pellentesque vitae. Nullam at eros vitae ipsum vestibulum scelerisque sed sit amet lectus. Quisque consequat velit quis ex consectetur, nec pellentesque tellus consequat.",
    },
  };
  return (
    <>
      <Box
        onClick={() => setModalVisible(true)}
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        sx={{ cursor: "pointer" }}
        borderRadius={3}
        backgroundColor={alertConfig[indexStatusValue].background}
      >
        <Text
          color={"#fff"}
          variant={"h5"}
          sx={{ textTransform: "uppercase" }}
          text={alertConfig[indexStatusValue].text}
        />
      </Box>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Text
          variant={"h3"}
          mb={2}
          text={`Profile ${alertConfig[indexStatusValue].text}`}
          color={alertConfig[indexStatusValue].background}
        />
        <Text
          variant={"body1"}
          text={alertConfig[indexStatusValue].modalText}
        />
      </Modal>
    </>
  );
};

export default ProfileStatus;
