import { useNavigate } from "react-router-dom";
import { Slugify } from "../utils/appUtils";
import { useTranslation } from "react-i18next";
import useConfig from "./useConfig";
import useUser from "./useUser";

const useItem = (item = null, setIndexUiState) => {
  const navigate = useNavigate();

  const { config } = useConfig();

  const { t } = useTranslation();

  const { geo, handleSetGeo } = useUser();

  const setTag = (tag) => {
    setIndexUiState((prev) => {
      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          tags: [tag],
        },
      };
    });

    navigate("/");
  };

  const setCategory = (category) => {
    setIndexUiState((prev) => {
      return {
        ...prev,
        refinementList: {
          ...prev.refinementList,
          categories: [category],
        },
      };
    });

    navigate("/");
  };

  /**
   *
   * Get item category
   *
   * @type {{name: string, slug: null, url: null}}
   *
   */

  let categories = [];

  let tags = [];

  let locations = [];

  if (item !== undefined && item !== null && item.categories && config) {
    item.categories.forEach((el) => {
      const categoryConfig = config?.categories?.items.find(
        (category) => category.slug === el,
      );
      if (!categoryConfig) {
        return;
      }
      categories.push({
        slug: categoryConfig.slug,
        name: categoryConfig.title,
      });
    });
  }

  if (item !== undefined && item !== null && item.tags) {
    item.tags.forEach((el) => {
      tags.push({
        name: el,
        slug: el,
      });
    });
  }

  if (item !== undefined && item !== null && item.locations) {
    item.locations.forEach((el) => {
      locations.push({
        name: el.name,
        range: el.radius,
        lat: el.lat,
        lng: el.lon,
      });
    });
  }

  const displayLocation = async (location) => {
    handleSetGeo({
      ...geo,
      radius: location.range,
      lat: location.lat,
      lng: location.lng,
      name: location.name,
      selected: true,
    });
    navigate("/");
  };

  /**
   *
   * Item link
   *
   * @param withHost
   * @returns {`${string|string}/${*}/${string}/${string}`|`${string|string}/profile/${string}/${string}`}
   * @constructor
   *
   */

  return {
    categories,
    tags,
    displayLocation,
    locations,
    setTag,
    setCategory,
  };
};

export const itemLink = (withHost = false, id = null, name = null) => {
  const host = withHost ? process.env.REACT_APP_URL : "/";

  const slug = Slugify(name || "profile");

  return `${host}profile/${id}/${slug}`;
};

export default useItem;
