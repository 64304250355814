import React from "react";
import { BrowseLoop } from "./Browse";

const Item = () => {
  return (
    <div className="app-browse-loop-item">
      <div className={"thumbnail skeleton"}>
        <div className={"thumbnail-img skeleton"}></div>
      </div>
      <h4 className={"skeleton skeleton-text"}>&nbsp;</h4>
      <h3 className={"skeleton skeleton-text"}>&nbsp;</h3>
    </div>
  );
};
const BrowseSkeleton = ({ count = 8 }) => {
  return (
    <BrowseLoop>
      {[...Array(count)].map((x, i) => {
        return <Item key={i} />;
      })}
    </BrowseLoop>
  );
};

export default BrowseSkeleton;
