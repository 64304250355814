import React from "react";
import { Share2 } from "react-feather";
import ShareWindow from "./ShareWindow";
import useUser from "../../../hooks/useUser";
import usePopup from "../../../hooks/usePopup";

const Share = ({ item, size = 24 }) => {
  const { openPopup } = usePopup();
  const { isIOS } = useUser();
  return (
    <button
      title="Udostępnij"
      className={"ms-1 share icon-button"}
      onTouchStart={() =>
        isIOS ? openPopup(ShareWindow, { item }) : undefined
      }
      onClick={() => (!isIOS ? openPopup(ShareWindow, { item }) : undefined)}
    >
      <div className={"share-icon"}>
        <Share2 size={size} />
      </div>
    </button>
  );
};

export default Share;
