import React, { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { Box } from "@mui/material";
import { Message } from "../../components/Typography/Message";
import {
  InboxChatContentHistoryTrigger,
  InboxChatContentScrollDown,
  InboxChatContentWrapperSkeleton,
  InboxChatMessageDivider,
} from "./InboxParts";
import useWebSocket from "../../hooks/useWebSocket";
import Alert from "@mui/material/Alert";

const InboxChatMessage = ({ message, messageIndex, messages, isWriter }) => {
  return (
    <>
      <Message
        text={message.text}
        isWriter={isWriter}
        sending={message.messageId === "tmp"}
        date={message.time}
      />
      <InboxChatMessageDivider
        messages={messages.items}
        messageIndex={messageIndex}
      />
    </>
  );
};
const offset = 5;

const InboxChatContentMessages = ({ messages }) => {
  const { user } = useUser();

  // display skeleton if messages are loading
  if (messages.items.length === 0 && messages.state === "loading") {
    return <InboxChatContentWrapperSkeleton />;
  }

  // display no message alert
  if (messages.items.length === 0) {
    return <Alert variant={"standard"}>No messages</Alert>;
  }

  return (
    <Box>
      {messages.items?.map((message, index) => (
        <InboxChatMessage
          key={index}
          message={message}
          messageIndex={index}
          messages={messages}
          isWriter={message.senderId === user.id}
        />
      ))}
    </Box>
  );
};

const InboxChatContent = ({ messages, receiver, chatRef }) => {
  const initial = {
    scrollTop: 0,
    scrollBottom: 0,
    clientHeight: 0,
    newMessages: 0,
    pristine: true,
  };
  const [scrollData, setScrollData] = useState(initial);
  const { user } = useUser();
  const { register, unregister, connected } = useWebSocket();
  /**
   * Handle scroll event
   */
  const handleScroll = () => {
    if (chatRef.current) {
      const scrollTop = chatRef.current.scrollTop;
      const scrollHeight = chatRef.current.scrollHeight;
      const clientHeight = chatRef.current.clientHeight;
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      setScrollData((prev) => ({
        ...prev,
        scrollTop: chatRef.current.scrollTop,
        scrollBottom: scrollBottom,
        scrollHeight: scrollHeight,
        newMessages:
          scrollHeight - scrollTop - clientHeight < 5 ? 0 : prev.newMessages,
      }));
    }
  };
  /**
   * Scroll to bottom
   */
  const handleScrollDown = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };
  /**
   * Reset scroll data on receiver change
   */
  useEffect(() => {
    setScrollData(initial);
  }, [receiver]);
  /**
   * Handle new message, increase new message count
   */
  useEffect(() => {
    const handleNewMessage = (response) => {
      const { senderId, receiverId } = response.data;
      if (
        senderId === receiver ||
        (senderId === user.id && receiverId === receiver)
      ) {
        setScrollData((prev) => ({
          ...prev,
          newMessages: prev.newMessages + 1,
        }));
      }
    };
    register("newMessage", handleNewMessage);
    return () => {
      unregister("newMessage", handleNewMessage);
    };
  }, [receiver, connected]);

  // Scroll to bottom
  useEffect(() => {
    if (scrollData.scrollBottom < offset) {
      handleScrollDown();
      // set pristine to false after 500ms
      if (scrollData.pristine) {
        const timeout = setTimeout(() => {
          setScrollData((prev) => ({
            ...prev,
            pristine: false,
          }));
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [messages, receiver]);
  return (
    <Box sx={{ position: "relative", flexGrow: 1 }}>
      <Box
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          p: 2,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        ref={chatRef}
        onScroll={handleScroll}
      >
        {/* History trigger */}
        {messages.items.length > 0 && (
          <InboxChatContentHistoryTrigger
            receiver={receiver}
            messages={messages}
            scrollData={scrollData}
            chatRef={chatRef}
          />
        )}
        {/* Messages */}
        <InboxChatContentMessages receiver={receiver} messages={messages} />
      </Box>
      {/* Scroll down button */}
      {scrollData.scrollBottom > offset && (
        <InboxChatContentScrollDown
          amount={scrollData.newMessages}
          onClick={handleScrollDown}
        />
      )}
    </Box>
  );
};

export default InboxChatContent;
