import React, { useEffect, useRef, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const ProgressBar = ({ duration, forwardRef, hoverActive }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [progress, setProgress] = useState(0);

  const [focused, setFocused] = useState(0);

  const [touchMoveActive, setTouchMoveActive] = useState(false);

  const progressRef = useRef();

  /**
   *
   *
   * Update progressbar
   *
   */

  useEffect(() => {
    const video = forwardRef.current;

    if (duration == null) {
      duration = video.duration;
    }

    const updateTime = () => {
      const value = (100000 / duration) * video.currentTime;

      setProgress(value);
    };

    forwardRef.current.addEventListener("timeupdate", updateTime);

    return () => {
      if (forwardRef.current !== null) {
        forwardRef.current.removeEventListener("timeupdate", updateTime);
      }
    };
  }, [focused]);

  /**
   *
   * @param event
   */

  const handleClick = (event) => {
    if (touchMoveActive) {
      return;
    }

    const element = progressRef.current;

    if (element) {
      const newPointer = event.clientX - element.getBoundingClientRect().left;

      const percentOfClick = newPointer / element.offsetWidth;

      forwardRef.current.currentTime = (duration / 1000) * percentOfClick;

      setProgress(percentOfClick * 100);
    }
  };

  const handleTouchMove = (event) => {
    setTouchMoveActive(true);
    const element = progressRef.current;
    if (element) {
      const left =
        event.changedTouches[0].pageX - element.getBoundingClientRect().left;

      const percentOfClick = left / element.offsetWidth;

      forwardRef.current.currentTime = duration * percentOfClick;

      setProgress(percentOfClick * 100);
    }
  };

  const handleTouchEnd = () => {
    setTouchMoveActive(false);
    setFocused(false);
  };

  return (
    <>
      <Box
        width={"100%"}
        height={isMobile ? 8 : hoverActive ? 6 : 3}
        backgroundColor={theme.palette.border.input}
        sx={{ position: "relative", cursor: "pointer" }}
        onClick={handleClick}
        ref={progressRef}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Box
          width={`${progress}%`}
          top={0}
          left={0}
          bottom={0}
          backgroundColor={theme.palette.primary.main}
          sx={{
            position: "absolute",
            transition: !focused ? "width 0.25s linear" : "",
            cursor: "pointer",
          }}
        >
          <Box
            right={0}
            width={isMobile ? 12 : 12}
            height={isMobile ? 12 : hoverActive ? 12 : 0}
            backgroundColor={theme.palette.secondary.main}
            borderRadius={20}
            sx={{
              position: "absolute",
              transform: `translate(${isMobile ? "4px" : "3px"}, -3px)`,
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
};
export default ProgressBar;
