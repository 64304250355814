import ReactDOM from "react-dom";
import { SpeedDial } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export const FooterSpeedDial = ({ actions = [] }) => {
  return ReactDOM.createPortal(
    <SpeedDial
      ariaLabel="Footer Menu"
      sx={{
        position: "absolute",
        alignItems: "flex-end",
        bottom: 16,
        right: 16,
        ".MuiSpeedDialAction-staticTooltipLabel": {
          position: "static",
        },
        ".MuiSpeedDial-actions": {
          alignItems: "flex-end",
        },
      }}
      icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<CloseIcon />} />}
    >
      {actions.map((action) => {
        if (!action.show) return null;
        return (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
            tooltipOpen
          />
        );
      })}
    </SpeedDial>,
    document.getElementById("app-footer-portal"),
  );
};
