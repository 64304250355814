import Text from "../../../components/Typography/Text";
import React, { useState } from "react";
import TextField, { TogglePassword } from "../../../components/Forms/TextField";
import { Formik } from "formik";
import ButtonPrimary from "../../../components/Buttons/Button";
import { Box, useTheme } from "@mui/material";
import useUser from "../../../hooks/useUser";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
  updatePassword,
  updateUserAttribute,
} from "aws-amplify/auth";
import Modal, { ModalHelper } from "../../../components/Modals/Modal";
import * as Yup from "yup";
import Tooltip from "../../../components/Tooltip";
import { TbAlertCircleFilled } from "react-icons/tb";
import { useNavigate } from "react-router";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import settingsChangeEmailSchema from "../../../validations/account/settings/settingsChangeEmailSchema";
import InputCode from "../../../components/Forms/InputCode";
import { useNotification } from "../../../hooks/useNotifications";
import settingsChangePasswordSchema from "../../../validations/account/settings/settingsChangePasswordSchema";
import { setError } from "../../../services/redux/slices/webSocketSlice";
import useCountdown from "../../../hooks/useCountdown";

const ChangeEmailModal = ({ setChangeEmailModalVisible }) => {
  const theme = useTheme();

  const { user, handleSetUser } = useUser();

  const emailInitialData = {
    newEmail: "",
    emailConfirmation: "",
  };

  const { addNotification } = useNotification();

  const [confirmationCodeVisible, setConfirmationCodeVisible] = useState(false);

  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [output, setOutput] = useState(null);

  const [error, setError] = useState(null);

  const [resendError, setResendError] = useState(null);

  const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();

  const handleEmailChange = async (validateForm, values) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const result = await updateUserAttribute({
          userAttribute: { attributeKey: "email", value: values.newEmail },
        });
        setOutput(result);
        setConfirmationCodeVisible(true);
        setLoading(false);
        handleSetUser({ ...user, email: values.newEmail });
      } catch (err) {
        if (err.name === "AliasExistsException") {
          setError(
            "The email address you entered is already registered in our system. Please provide a different email address.",
          );
        } else {
          setError(err.message);
        }
        setLoading(false);
      }
    }
  };

  const changeEmailWithConfirm = async () => {
    if (!output) return;

    const { nextStep } = output;
    try {
      switch (nextStep.updateAttributeStep) {
        case "CONFIRM_ATTRIBUTE_WITH_CODE":
          await confirmUserAttribute({
            userAttributeKey: "email",
            confirmationCode: code,
          });
          addNotification("success", "The mail has been received");
          setChangeEmailModalVisible(false);
          break;
        case "DONE":
          break;
      }
    } catch (err) {
      addNotification("error", err.message);
    }
  };

  const resendCode = async () => {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: "email",
      });
      startCountdown(20);
    } catch (err) {
      setResendError(err.message);
    }
  };

  return (
    <Formik
      initialValues={emailInitialData}
      onSubmit={(values) => console.log(values)}
      validationSchema={Yup.object().shape(settingsChangeEmailSchema())}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, handleChange, handleBlur, errors, validateForm }) => (
        <form>
          <Text
            text={
              confirmationCodeVisible ? "Confirmation code" : "E-mail change"
            }
            variant={"h3"}
          />
          <>
            {confirmationCodeVisible ? (
              <>
                <Box mt={3}>
                  <InputCode code={code} setCode={setCode} />
                </Box>
                <Box
                  mt={6}
                  mb={6}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={1}
                >
                  <Text
                    text={
                      isTimeoutRemaining
                        ? "Didn't get the code?"
                        : "The code was sent back to the email"
                    }
                    variant={"h6"}
                    color={"primary"}
                  />
                  <Text
                    text={isTimeoutRemaining ? "Resend" : timeLeft}
                    variant={"overline"}
                    onClick={() => (isTimeoutRemaining ? resendCode() : null)}
                  />
                </Box>
                <Text text={resendError} variant={"body2"} color={"error"} />
                <ButtonPrimary
                  type={"button"}
                  onClick={() => changeEmailWithConfirm(values)}
                  fullWidth
                  disabled={code.length !== 6}
                >
                  Confirm
                </ButtonPrimary>
              </>
            ) : (
              <>
                <Box mt={3} display={"flex"} flexDirection={"column"} gap={1}>
                  <TextField
                    fullWidth
                    value={user.email}
                    disabled
                    label={"Current e-mail"}
                  />
                  <TextField
                    value={values.newEmail}
                    name={"newEmail"}
                    fullWidth
                    label={"New e-mail"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.newEmail}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <>
                            {errors.newEmail && (
                              <Tooltip placement="left" title={errors.newEmail}>
                                <Box display="flex">
                                  <TbAlertCircleFilled
                                    color={theme.palette.error.main}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </>
                        ),
                      },
                    }}
                  />
                  <TextField
                    value={values.emailConfirmation}
                    name={"emailConfirmation"}
                    fullWidth
                    label={"Repeat new password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.emailConfirmation}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <>
                            {errors.emailConfirmation && (
                              <Tooltip
                                placement="left"
                                title={errors.emailConfirmation}
                              >
                                <Box display="flex">
                                  <TbAlertCircleFilled
                                    color={theme.palette.error.main}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </>
                        ),
                      },
                    }}
                  />
                </Box>
                <Box display={"flex"} mt={2} gap={1}>
                  <ButtonPrimary
                    fullWidth
                    color={"info"}
                    onClick={() => setChangeEmailModalVisible(false)}
                  >
                    Cancel
                  </ButtonPrimary>
                  <ButtonPrimary
                    type={"button"}
                    onClick={() => handleEmailChange(validateForm, values)}
                    fullWidth
                    loading={loading}
                  >
                    Ok
                  </ButtonPrimary>
                </Box>
                <Text color={"error"} text={error} variant={"body2"} mt={1} />
              </>
            )}
          </>
        </form>
      )}
    </Formik>
  );
};

const ChangeEmail = () => {
  const { user } = useUser();

  const [changeEmailModalVisible, setChangeEmailModalVisible] = useState(false);

  return (
    <>
      <TextField
        fullWidth
        value={user.email}
        disabled
        label={"E-mail"}
        slotProps={{
          input: {
            endAdornment: (
              <>
                {user.isEmailAccount ? (
                  <ModeEditOutlineOutlinedIcon
                    cursor={"pointer"}
                    color={"primary"}
                    onClick={() => setChangeEmailModalVisible(true)}
                  />
                ) : (
                  <LockOutlinedIcon color={"primary"} />
                )}
              </>
            ),
          },
        }}
      />
      <Modal
        open={changeEmailModalVisible}
        onClose={() => setChangeEmailModalVisible(false)}
      >
        <ChangeEmailModal
          setChangeEmailModalVisible={setChangeEmailModalVisible}
        />
      </Modal>
    </>
  );
};

const ChangePasswordModal = () => {
  const passwordInitialData = {
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [confirmationCodeVisible, setConfirmationCodeVisible] = useState(false);

  const { addNotification } = useNotification();

  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [confirmationChangeLoading, setConfirmationChangeLoading] = useState();

  const { handleLogout } = useUser();

  const [error, setError] = useState(null);

  const [resendError, setResendError] = useState(null);

  const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();

  const navigate = useNavigate();

  const handlePasswordChange = async (validateForm) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        await sendUserAttributeVerificationCode({ userAttributeKey: "email" });
        setConfirmationCodeVisible(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const changePasswordWithConfirm = async (values) => {
    setConfirmationChangeLoading(true);
    try {
      await confirmUserAttribute({
        userAttributeKey: "email",
        confirmationCode: code,
      });

      try {
        await updatePassword({
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        });
        values.currentPassword = "";
        values.newPassword = "";
        values.passwordConfirmation = "";
        await handleLogout();
        navigate("/?auth-popup=login");
      } catch (err) {
        setConfirmationChangeLoading(false);
        setResendError(err.message);
      }
    } catch (err) {
      setConfirmationChangeLoading(false);
      setResendError(err.message);
    }
  };

  const resendCode = async () => {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: "email",
      });
      startCountdown(20);
    } catch (err) {
      setResendError(err.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={passwordInitialData}
        onSubmit={(values) => console.log(values)}
        validationSchema={Yup.object().shape(settingsChangePasswordSchema())}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, handleChange, handleBlur, errors, validateForm }) => (
          <form>
            <Text
              text={
                confirmationCodeVisible
                  ? "Confirmation code"
                  : "Password change"
              }
              variant={"h3"}
            />
            <>
              {confirmationCodeVisible ? (
                <>
                  <Box mt={3}>
                    <InputCode code={code} setCode={setCode} />
                  </Box>
                  <Box
                    mt={6}
                    mb={6}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                  >
                    <Text
                      text={
                        isTimeoutRemaining
                          ? "Didn't get the code?"
                          : "The code was sent back to the email"
                      }
                      variant={"h6"}
                    />
                    <Text
                      text={isTimeoutRemaining ? "Resend" : timeLeft}
                      variant={"overline"}
                      onClick={() => (isTimeoutRemaining ? resendCode() : null)}
                    />
                  </Box>
                  <Text text={resendError} variant={"body2"} color={"error"} />
                  <ButtonPrimary
                    type={"button"}
                    onClick={() => changePasswordWithConfirm(values)}
                    fullWidth
                    loading={confirmationChangeLoading}
                    disabled={code.length !== 6}
                  >
                    Confirm
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  <Box mt={3} display={"flex"} flexDirection={"column"} gap={1}>
                    <TextField
                      label={"Current Password"}
                      value={values.currentPassword}
                      name={"currentPassword"}
                      onChange={handleChange}
                      error={errors.currentPassword}
                      onBlur={handleBlur}
                      type={passwordVisible ? "text" : "password"}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <>
                              {errors.currentPassword ? (
                                <>
                                  <Tooltip
                                    placement="left"
                                    title={errors.currentPassword}
                                  >
                                    <Box display="flex">
                                      <TbAlertCircleFilled color={"red"} />
                                    </Box>
                                  </Tooltip>
                                </>
                              ) : (
                                <TogglePassword
                                  passwordVisible={passwordVisible}
                                  onToggle={() =>
                                    setPasswordVisible(
                                      (prevState) => !prevState,
                                    )
                                  }
                                />
                              )}
                            </>
                          ),
                        },
                      }}
                      fullWidth
                    />
                    <TextField
                      label={"New password"}
                      value={values.newPassword}
                      name={"newPassword"}
                      onChange={handleChange}
                      error={errors.newPassword}
                      onBlur={handleBlur}
                      type={passwordVisible ? "text" : "password"}
                      fullWidth
                      helperStyles={{
                        margin: "0",
                        color: "#D0D6DE",
                      }}
                      helperText={
                        "Min. 8 characters, 1 special, 1 number, 1 capital letter"
                      }
                      slotProps={{
                        input: {
                          endAdornment: (
                            <>
                              {errors.newPassword && (
                                <Tooltip
                                  placement="left"
                                  title={errors.newPassword}
                                >
                                  <Box display="flex">
                                    <TbAlertCircleFilled color={"red"} />
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          ),
                        },
                      }}
                    />
                    <TextField
                      label={"Repeat new password"}
                      value={values.passwordConfirmation}
                      name={"passwordConfirmation"}
                      onChange={handleChange}
                      error={errors.passwordConfirmation}
                      onBlur={handleBlur}
                      type={passwordVisible ? "text" : "password"}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <>
                              {errors.passwordConfirmation && (
                                <Tooltip
                                  placement="left"
                                  title={errors.passwordConfirmation}
                                >
                                  <Box display="flex">
                                    <TbAlertCircleFilled color={"red"} />
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          ),
                        },
                      }}
                    />
                  </Box>
                  <Box display={"flex"} mt={2} gap={1}>
                    <ButtonPrimary fullWidth color={"info"}>
                      Cancel
                    </ButtonPrimary>
                    <ButtonPrimary
                      type={"button"}
                      onClick={() => handlePasswordChange(validateForm)}
                      fullWidth
                      loading={loading}
                    >
                      Ok
                    </ButtonPrimary>
                  </Box>
                  <Text text={error} variant={"body2"} color={"error"} mt={1} />
                </>
              )}
            </>
          </form>
        )}
      </Formik>
    </>
  );
};

const ChangePassword = () => {
  const { user } = useUser();

  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);

  return (
    <Box mt={2}>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label={"Current password"}
          value={"........."}
          name={"currentPassword"}
          fullWidth
          disabled={true}
          type={"password"}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {user.isEmailAccount ? (
                    <ModeEditOutlineOutlinedIcon
                      color={"primary"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setChangePasswordModalVisible(true)}
                    />
                  ) : (
                    <LockOutlinedIcon color={"primary"} />
                  )}
                </>
              ),
            },
          }}
        />
      </Box>
      <Modal
        open={changePasswordModalVisible}
        onClose={() => setChangePasswordModalVisible(false)}
      >
        <ChangePasswordModal />
      </Modal>
    </Box>
  );
};

const SecurityView = () => {
  return (
    <>
      <Box mb={2} display={"flex"} alignItems={"center"}>
        <Text mr={1} text={"Account Security"} variant={"h3"} />
        <ModalHelper
          title={"Account Security"}
          text={
            "In the Security section, you can update your password and email address to keep your account secure. If you signed up using Google or Apple, please note that your email and password cannot be changed. You can update your login details through your Google or Apple account settings."
          }
        />
      </Box>
      <ChangeEmail />
      <ChangePassword />
    </>
  );
};

export default SecurityView;
