import { useDispatch, useSelector } from "react-redux";
import { fetchConfig } from "../services/redux/slices/configSlice";

const useConfig = () => {
  const dispatch = useDispatch();

  const { config, loading, offline, plans } = useSelector(
    (state) => state.config,
  );

  return {
    plans,
    config,
    loading,
    offline,
    loadConfig: () => dispatch(fetchConfig()),
  };
};

export default useConfig;
