import React, { useState } from "react";
import { useSortBy } from "react-instantsearch";
import { Col, Form, Row } from "react-bootstrap";
import CloseOutside from "../../../components/CloseOutside";
import AnimatedDropdown from "../../Parts/AnimatedDropdown";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsArrowDownUp } from "react-icons/bs";
const SortBy = (props) => {
  const { currentRefinement, options, refine } = useSortBy(props);

  const [isActive, setIsActive] = useState(false);

  const activeOption = options.filter(
    (el) => el.value === currentRefinement,
  )[0];

  const handleClickOption = (value) => {
    refine(value);
  };

  return (
    <div className={"sort-by"}>
      <button
        type={"button"}
        onClick={() => setIsActive((prevState) => !prevState)}
        className={"sort-by-button font-sm font-weight-sm"}
      >
        <BsArrowDownUp />
      </button>
      <div className="position-relative">
        <CloseOutside handleClose={() => setIsActive(false)}>
          <AnimatedDropdown isOpen={isActive} className="mt-2">
            <ul>
              {options.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleClickOption(option.value)}
                  className={
                    option.value === currentRefinement ? "text-primary" : ""
                  }
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </AnimatedDropdown>
        </CloseOutside>
      </div>
    </div>
  );
};

export default SortBy;
