import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import Grid from "@mui/material/Grid2";
import React from "react";
import TextField from "../Forms/TextField";
import {
  AutocompleteHeaderLocationPaper,
  AutocompleteHeaderLocationPopper,
  AutocompleteStyled,
} from "./Autocomplete";

const AutocompleteHeaderLocation = ({
  loading,
  handleSelect,
  Append = <></>,

  ...props
}) => {
  return (
    <AutocompleteStyled
      autoComplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      filterSelectedOptions
      onInputChange={(event, newInputValue) => {
        handleSelect(newInputValue);
      }}
      slots={{
        paper: AutocompleteHeaderLocationPaper,
        popper: AutocompleteHeaderLocationPopper,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <IconButton>
                <CircularProgress size={16} />
              </IconButton>
            ) : (
              Append
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ width: "100%", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...props}
    />
  );
};

export default AutocompleteHeaderLocation;
