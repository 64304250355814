import { useDispatch, useSelector } from "react-redux";
import {
  fetchProfileStatus,
  setIndexStatusValue,
} from "../services/redux/slices/errorSlice";

const useError = () => {
  const {
    galleryError,
    thumbnailError,
    detailsError,
    indexStatusValue,
    isValid,
  } = useSelector((state) => state.error);

  const dispatch = useDispatch();

  const handleSetIndexStatusValue = (status) =>
    dispatch(setIndexStatusValue(status));

  const handleFetchProfileStatus = () => dispatch(fetchProfileStatus());

  return {
    galleryError: indexStatusValue !== "rejected" ? [] : galleryError,
    thumbnailError: indexStatusValue !== "rejected" ? {} : thumbnailError,
    detailsError: indexStatusValue !== "rejected" ? {} : detailsError,
    indexStatusValue,
    isValid,
    handleSetIndexStatusValue,
    handleFetchProfileStatus,
  };
};

export default useError;
