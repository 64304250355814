import { Stack } from "@mui/material";

export const ButtonStack = ({ children, ...props }) => {
  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        ".MuiButton-root:first-child": {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
        ".MuiButton-root:last-child": {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        },
        ".MuiButton-root:not(:first-child):not(:last-child)": {
          borderRadius: 0,
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default ButtonStack;
