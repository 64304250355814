import Typography from "@mui/material/Typography";

const Text = ({ text, variant, ...otherProps }) => {
  return (
    <Typography variant={variant} {...otherProps}>
      {text}
    </Typography>
  );
};

export default Text;
