import { useMediaQuery, useTheme } from "@mui/material";

export const hideEmail = (email) => {
  return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
};

export const firstLetterUpper = (str) => {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
};
export const Slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const isValidUrl = (url) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;

  return regex.test(url);
};

export const stringToColor = (string = "") => {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name) => {
  const nameParts = name ? name.split(" ") : [];
  return {
    sx: {
      backgroundColor: stringToColor(name || ""),
    },
    children: `${nameParts[0] ? nameParts[0][0] : ""}${nameParts[1] ? nameParts[1][0] : ""}`,
  };
};

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export const shortenEmail = (email) => {
  const maxLength = 21;
  const [localPart, domain] = email.split("@");

  if (email.length <= maxLength) {
    return email;
  }

  const visibleLocalPart = localPart.slice(
    0,
    Math.max(0, maxLength - domain.length - 5),
  );
  return `${visibleLocalPart}...@${domain}`;
};

export const useDevice = () => {
  const theme = useTheme();
  return {
    isMobile: useMediaQuery(theme.breakpoints.down("md")),
  };
};

export const formatDate = (date = null) => {
  if (!date) {
    date = new Date();
  }
  const pad = (num) => String(num).padStart(2, "0");
  return `${pad(date.getHours())}:${pad(date.getMinutes())} ${pad(date.getMonth() + 1)}.${pad(date.getDate())}.${date.getFullYear()}`;
};

export const timestampToDate = (date) => {
  const newDate = new Date();
  const pad = (num) => String(num).padStart(2, "0");
  return `${pad(newDate.getHours())}:${pad(newDate.getMinutes())} ${pad(newDate.getMonth() + 1)}.${pad(newDate.getDate())}.${newDate.getFullYear()}`;
};
