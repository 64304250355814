import * as Yup from "yup";

const settingsChangeEmailSchema = () => ({
  newEmail: Yup.string()
    .required("New email required")
    .email("Not correct format of new email"),
  emailConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("newEmail"), null],
      "Email confirmation must be the same as new email",
    )
    .required("Email confirmation required"),
});

export default settingsChangeEmailSchema;
