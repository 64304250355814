import { BillingData } from "./PlansView";
import Text from "../../../components/Typography/Text";
import React, { useEffect, useState } from "react";
import TableDefault from "../../../components/Tables/TableDefault";
import { Alert, Box, Skeleton } from "@mui/material";
import { LoaderComponent } from "../../../components/Loaders/Loader";
import TextField from "../../../components/Forms/TextField";
import ButtonPrimary from "../../../components/Buttons/Button";
import Modal, { ModalHelper } from "../../../components/Modals/Modal";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stripeConfig from "../../../config/stripeConfig";
import { isObjectEmpty } from "../../../utils/appUtils";
import { usePayment } from "../../../hooks/usePayment";

const BillingHistorySkeletonLoading = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: 22 }} width={180} />
      {Array.from({ length: 5 }).map((_, index) => (
        <Box key={index} mt={1} display="flex" gap={1}>
          <Skeleton variant="rounded" height={50} width="15%" />
          <Skeleton variant="rounded" height={50} width="20%" />
          <Skeleton variant="rounded" height={50} width="30%" />
          <Skeleton variant="rounded" height={50} width="20%" />
          <Skeleton variant="rounded" height={50} width="15%" />
        </Box>
      ))}
    </>
  );
};

const InvoiceDetails = () => {
  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <Text text={"Invoice details"} variant={"h3"} />
        <ModalHelper
          title={"Billing details"}
          text={
            "In this section, you can view and manage your invoice details, payment method, and billing history. You can update your payment method, download past invoices, and review your payment transactions. Ensure your payment information is current to maintain uninterrupted access to your subscription and services."
          }
        />
      </Box>
      <BillingData update={true} />
    </>
  );
};

const BillingHistory = ({ invoicesData }) => {
  if (!invoicesData) {
    return <Alert severity={"info"}>No billing data</Alert>;
  }

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const columns = [
    { field: "date", headerName: "Date", width: 130 },
    { field: "fileType", headerName: "File Type", width: 130 },
    { field: "service", headerName: "Service", width: 500 },
    { field: "amount", headerName: "Amount", width: 130 },
    {
      field: "action",
      headerName: "Download invoice",
      width: 150,
      renderCell: (params) => (
        <a href={params.row.action} target="_blank" rel="noopener noreferrer">
          <Text text={"Download"} variant={"overline"} />
        </a>
      ),
    },
  ];

  const rows = invoicesData.map((invoice, index) => ({
    id: invoice.id,
    date: new Date(invoice.created * 1000).toLocaleDateString(),
    fileType: "Invoice",
    service: invoice.lines.data[0].description,
    amount: formatPrice(invoice.amount_paid),
    action: invoice.hosted_invoice_url,
  }));

  return (
    <>
      <TableDefault columns={columns} rows={rows} />
    </>
  );
};

const PaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    getSubscriptionData,
    postBillingPayment,
    subscriptionData,
    getBillingAddress,
  } = usePayment();

  const [changeCardModalVisible, setChangeCardModalVisible] = useState(false);

  const card = subscriptionData.data?.payment_method.card;

  const handleChangeCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setSubmitLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setSubmitLoading(false);
      return;
    }
    try {
      await postBillingPayment({
        paymentMethodId: paymentMethod.id,
        subscriptionId: subscriptionData.data.id,
      });
      await getBillingAddress();
    } catch (err) {
      console.log(err);
    }
    await getSubscriptionData();
    setChangeCardModalVisible(false);
  };

  return (
    <Box mt={3} mb={3}>
      <Text mb={2} text={"Payment method"} variant={"h3"} />
      <TextField
        helperStyles={{
          margin: "5px 0 0 0",
          color: "#40464E",
        }}
        helperText={`Expiration date: ${card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month}/${String(card.exp_year).slice(2)}`}
        label={"Card Number"}
        value={`**** **** **** ${card.last4}`}
        fullWidth
      />
      <Box onClick={() => setChangeCardModalVisible(true)} mt={2}>
        <ButtonPrimary fullWidth>Change payment method</ButtonPrimary>
      </Box>
      <Modal
        open={changeCardModalVisible}
        onClose={() => setChangeCardModalVisible(false)}
      >
        <form onSubmit={handleChangeCard}>
          <Text text={"Change Payment Method"} variant={"h3"} mb={2} />
          <CardElement className={"edit-card-element"} />
          <Box display={"flex"} mt={2} gap={1}>
            <ButtonPrimary
              onClick={() => setChangeCardModalVisible(false)}
              color={"info"}
              fullWidth
              type={"button"}
            >
              Cancel
            </ButtonPrimary>
            <ButtonPrimary loading={submitLoading} type={"submit"} fullWidth>
              Ok
            </ButtonPrimary>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

const BillingView = () => {
  const {
    getBillingInvoices,
    invoicesData,
    getSubscriptionData,
    subscriptionData,
  } = usePayment();

  const { options, stripePromise } = stripeConfig(subscriptionData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSubscriptionData();
        await getBillingInvoices();
      } catch (error) {
        console.error("Error fetching subscription data or invoices:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {subscriptionData.loading ? (
        <LoaderComponent height={400} />
      ) : (
        <>
          {subscriptionData.data && <InvoiceDetails />}
          {subscriptionData.data &&
            !isObjectEmpty(subscriptionData.data.payment_method) && (
              <Elements stripe={stripePromise} options={options}>
                <PaymentMethod />
              </Elements>
            )}
        </>
      )}
      <Box>
        {invoicesData.loading ? (
          <BillingHistorySkeletonLoading />
        ) : (
          <>
            <Text mb={2} text={"Billing History"} variant={"h3"} />
            <BillingHistory invoicesData={invoicesData.data} />
          </>
        )}
      </Box>
    </>
  );
};

export default BillingView;
