import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { setObservations } from "./watchlistSlice";
import { api } from "../../../hooks/useApi";

const initialState = {
  user: null,
  loading: true,
  indexStatus: "",
  isMobile: window.innerWidth <= 991,
  isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
  isAdmin: false,
  lang: localStorage.getItem("i18nextLng"),
  observations: [],
  thumbnail: "",
  avatar: "",
  geo: {
    selected: false,
    radius: 50,
    lat: false,
    lng: false,
    name: "",
  },
  unread: {
    messages: 0,
  },
};

export const handleRefresh = createAsyncThunk(
  "user/handleRefresh",
  async (_, { dispatch }) => {
    try {
      const session = await fetchAuthSession();
      if (session.userSub === undefined) {
        return null;
      } else {
        const user = await getCurrentUser();
        const loginType =
          user.username.split("_").length === 1
            ? "User"
            : user.username.split("_")[0];

        const { request } = api();

        const response = await request({
          url: `${process.env.REACT_APP_API_HOST}/profile`,
        });

        if (!response) {
          throw new Error("Error in request");
        }

        dispatch(setObservations(response.observations));

        return { ...response, loginType: loginType };
      }
    } catch (err) {
      throw new Error("Error in request");
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    setThumbnail(state, action) {
      state.user = { ...state.user, thumbnail: action.payload };
    },
    setAvatar(state, action) {
      state.user = { ...state.user, avatar: action.payload };
    },
    setGeo(state, action) {
      state.geo = action.payload;
    },
    setUnread(state, action) {
      state.unread = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleRefresh.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleRefresh.fulfilled, (state, action) => {
      state.user = action.payload;
      if (action.payload) {
        state.user["isEmailAccount"] =
          action.payload._username.split("_").length <= 1;
      }
      state.loading = false;
      state.indexStatus = action.payload?.index_status;
    });
  },
});

export const {
  setUser,
  setIsMobile,
  setLoading,
  setThumbnail,
  setAvatar,
  setGeo,
  setUnread,
} = userSlice.actions;

export default userSlice.reducer;
