import { Badge, Button, Stack, useTheme } from "@mui/material";
import React from "react";

export const Navigation = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        maxHeight: theme.spacing(6),
        "& > *": { ml: 1 },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};

export const NavigationItem = ({
  Icon,
  children,
  badge,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      disableRipple
      disableElevation
      sx={{
        position: "relative",
        padding: "0 0.2rem",
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        color: theme.palette.primary.main,
        flexDirection: "column",
        textTransform: "initial",
        maxHeight: theme.spacing(6),
        "&:hover": {
          backgroundColor: "transparent",
          "&::before": {
            opacity: 1,
          },
        },
        "&.account:hover::before": {
          opacity: 0,
        },
        "&::before": {
          content: '""',
          position: "absolute",
          bottom: "-0.5rem",
          left: 0,
          width: "100%",
          height: "4px",
          backgroundColor: "var(--app-primary)",
          borderTopRightRadius: "2rem",
          borderTopLeftRadius: "2rem",
          opacity: 0,
          transition: "0.3s",
        },
      }}
      onClick={onClick}
      {...props}
    >
      {badge === true ? (
        <Badge color="error" variant="dot" overlap="circular">
          <Icon sx={{ fontSize: 32 }} />
        </Badge>
      ) : (
        <Icon sx={{ fontSize: 32 }} />
      )}
      {children}
    </Button>
  );
};
