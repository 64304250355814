import React from "react";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router";
import SendIcon from "@mui/icons-material/Send";
import MailIcon from "@mui/icons-material/Mail";
import { FooterSpeedDial } from "../../Footer/FooterSpeedDial";
import useQueryString from "../../../hooks/useQueryString";

const SingleSpeedDial = ({ item }) => {
  const { user } = useUser();
  const { addQuery } = useQueryString();
  const navigate = useNavigate();

  const handleSendMessage = () => {
    if (user === null) {
      return addQuery("auth-popup", "login");
    }
    navigate(`/messages?uid=${item.userId ? item.userId : item.userId}`);
  };

  const handleSendEmail = () => {
    if (user === null) {
      return addQuery("auth-popup", "login");
    }
    window.location.href = `mailto:${item.contact_methods.email}`;
  };

  const handleCallPhone = () => {
    if (user === null) {
      return addQuery("auth-popup", "login");
    }
    window.location.href = `tel:${item.contact_methods.phone}`;
  };

  const actions = [
    {
      show: item.contact_methods.direct !== null,
      icon: <SendIcon />,
      name: "Direct Message",
      onClick: handleSendMessage,
    },
    {
      show: item.contact_methods.email !== null,
      icon: <MailIcon />,
      name: "Send Email",
      onClick: handleSendEmail,
    },
    {
      show: item.contact_methods.phone !== null,
      icon: <MailIcon />,
      name: user ? `${item.contact_methods.phone}` : handleCallPhone,
      onClick: handleSendEmail,
    },
  ];

  return <FooterSpeedDial actions={actions} />;
};

export default SingleSpeedDial;
