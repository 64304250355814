import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import Link from "@mui/material/Link";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const linkSx = {
    fontSize: "0.8rem",
    borderRight: "1px solid #000",
    paddingRight: 1,
    color: "#333",
    "&:hover": {
      color: "#000",
    },
  };
  return (
    <>
      <Box
        sx={{
          border: "none",
          borderRadius: 0,
          position: "unset",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 4,
          borderTop: "1px solid #ccc",
        }}
      >
        <Stack direction={"row"} spacing={1} mb={2}>
          <Link
            underline={"hover"}
            href="https://site.jobmultiverse.com/privacy-policy/"
            sx={linkSx}
          >
            Privacy Policy
          </Link>
          <Link
            underline={"hover"}
            href="https://site.jobmultiverse.com/terms/"
            sx={linkSx}
          >
            Terms of Service
          </Link>
          <Link
            underline={"hover"}
            href="https://site.jobmultiverse.com/bug-report/"
            sx={{ ...linkSx, borderRight: "none", paddingRight: 0 }}
          >
            Bug Report
          </Link>
        </Stack>

        <Box>Copyright &copy; {currentYear}. jobmultiverse.com</Box>
      </Box>
    </>
  );
};

export default Footer;
