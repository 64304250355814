import React, { useEffect, useState } from "react";
import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import { useSearchParams } from "react-router-dom";

const CustomTab = styled(Tab)(({ theme, selected, hasErrors }) => ({
  padding: "12px 24px",
  fontSize: 13,
  fontWeight: 400,
  backgroundColor: selected ? theme.palette.primary.main : "transparent",
  color: selected
    ? "#fff"
    : hasErrors
      ? theme.palette.error.main
      : theme.palette.primary.main,
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: "transparent",
    color: hasErrors ? theme.palette.error.main : theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: "#D0D6DE",
  },
  [theme.breakpoints.down("sm")]: {
    border: `1px solid ${hasErrors ? theme.palette.error.main : theme.palette.primary.main}`,
    borderRadius: "25px",
    "&.Mui-selected": {
      backgroundColor: hasErrors
        ? theme.palette.error.main
        : theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  border: "none",
  [theme.breakpoints.down("sm")]: {
    margin: "10px 0",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      gap: 8,
    },
  },
}));

const TabsDefault = ({
  tabs,
  initialValue = 0,
  errorsConfig,
  syncWithUrl = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(
    syncWithUrl && searchParams.get("tab-index")
      ? +searchParams.get("tab-index")
      : initialValue,
  );

  useEffect(() => {
    if (syncWithUrl) {
      const tabIndex = searchParams.get("tab-index");
      if (tabIndex !== null && +tabIndex !== value) {
        setValue(+tabIndex);
      }
    }
  }, [searchParams, syncWithUrl]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (syncWithUrl) {
      setSearchParams({ "tab-index": newValue });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [value]);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 2 }}>
        <CustomTabs
          orientation={isMobile ? "horizontal" : "vertical"}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => {
            const hasErrors = tab.errors
              ? tab.errors.some((field) => errorsConfig?.[field])
              : false;

            return (
              <CustomTab
                key={index}
                label={tab.title}
                selected={value === index}
                hasErrors={hasErrors}
              />
            );
          })}
        </CustomTabs>
      </Grid>
      <Grid size={{ xs: 12, sm: 10 }}>
        <div>{tabs[value]?.components}</div>
      </Grid>
    </Grid>
  );
};

export default TabsDefault;
