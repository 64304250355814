import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";

const UseNotifications = React.createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((type, content) => {
    setNotifications((prev) => [...prev, { id: Date.now(), type, content }]);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  return (
    <UseNotifications.Provider value={{ addNotification }}>
      {children}
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open
          autoHideDuration={3000}
          onClose={() => removeNotification(notification.id)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            severity={notification.type}
            variant="filled"
            onClose={() => removeNotification(notification.id)}
          >
            <Box sx={{ whiteSpace: "pre-line" }}>{notification.content}</Box>
          </Alert>
        </Snackbar>
      ))}
    </UseNotifications.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(UseNotifications);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
};

export const useTitleFlash = () => {
  const originalTitle = useRef(document.title);
  const flashInterval = useRef(null);
  const timeout = useRef(null);

  const setTitle = useCallback((newMessageTitle) => {
    if (flashInterval.current) {
      clearInterval(flashInterval.current);
      flashInterval.current = null;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    let isOriginal = true;
    flashInterval.current = setInterval(() => {
      document.title = isOriginal ? newMessageTitle : originalTitle.current;
      isOriginal = !isOriginal;
    }, 1000);

    timeout.current = setTimeout(() => {
      clearInterval(flashInterval.current);
      flashInterval.current = null;
      document.title = originalTitle.current;
    }, 8000);
  }, []);

  useEffect(() => {
    return () => {
      if (flashInterval.current) {
        clearInterval(flashInterval.current);
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      document.title = originalTitle.current;
    };
  }, []);

  return { setTitle };
};
