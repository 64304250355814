import { useDispatch, useSelector } from "react-redux";
import {
  handleRefresh,
  setAvatar,
  setGeo,
  setIsMobile,
  setThumbnail,
  setUnread,
  setUser,
} from "../services/redux/slices/userSlice";
import { signOut } from "aws-amplify/auth";

const useUser = () => {
  const dispatch = useDispatch();

  const {
    user,
    indexStatus,
    isMobile,
    isIOS,
    isAdmin,
    lang,
    observations,
    thumbnail,
    avatar,
    loading,
    geo,
    unread,
  } = useSelector((state) => state.user);

  const handleLogout = async () => {
    await signOut();

    dispatch(handleRefresh());
  };

  const handleGlobalLogout = async () => {
    await signOut({ global: true });

    dispatch(handleRefresh());
  };

  return {
    user,
    indexStatus,
    isMobile,
    isIOS,
    isAdmin,
    lang,
    observations,
    thumbnail,
    avatar,
    loading,
    geo,
    unread,
    handleSetUnread: (unread) => dispatch(setUnread(unread)),
    handleSetGeo: (geo) => dispatch(setGeo(geo)),
    handleSetUser: (user) => dispatch(setUser(user)),
    handleSetIsMobile: (value) => dispatch(setIsMobile(value)),
    handleLogout,
    handleGlobalLogout,
    handleRefresh: () => dispatch(handleRefresh()),
    handleSetThumbnail: (thumbnail) => dispatch(setThumbnail(thumbnail)),
    handleSetAvatar: (avatar) => dispatch(setAvatar(avatar)),
  };
};

export default useUser;
