import * as Yup from "yup";

const settingsChangePasswordSchema = () => ({
  currentPassword: Yup.string().required("Current password required"),
  newPassword: Yup.string()
    .required("New password required")
    .min(8, "Minimum password length is 8 characters")
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/,
      "Special character and capital letter are required!",
    ),
  passwordConfirmation: Yup.string()
    .required("Password confirmation required")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Password confirmation must be the same as new password",
    ),
});

export default settingsChangePasswordSchema;
