import { Box, CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";

const LoaderOverlay = ({ active }) => {
  return (
    <Modal open={active}>
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default LoaderOverlay;
