import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { ReactComponent as WhiteIcon } from "../assets/svg/premium/thumbnail-white.svg";

export const BadgeText = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -13,
    top: 9,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontWeight: 300,
  },
}));

export const BadgePremium = ({ planID, additionalClass, marginRight }) => {
  if (planID !== "premium") {
    return;
  }
  return (
    <div
      style={{ marginRight: `${marginRight}rem` }}
      className={`bg-primary position-absolute z-3 end-0 top-0 pb-2 ps-2 pe-2 pt-2 ${additionalClass ? additionalClass : ""}`}
    >
      <WhiteIcon width={22} />
    </div>
  );
};
