import { useEffect, useRef } from "react";
import { Box, Slide, Stack, useScrollTrigger } from "@mui/material";
import Container from "../../components/Page/Container";

export const Header = ({ children, ...props }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.body.style.paddingTop = `${headerHeight}px`;
    }
    return () => {
      document.body.style.paddingTop = "0";
    };
  }, []);

  const trigger = useScrollTrigger({
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box
        ref={headerRef}
        sx={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 20,
          backgroundColor: "#fff",
          boxShadow: "0 16px 16px -10px rgba(150, 138, 186, 0.2)",
        }}
      >
        <Container maxWidth={"xl"}>
          <Stack direction="row" alignItems="center" py={2} w="100%" gap={1}>
            {children}
          </Stack>
        </Container>
      </Box>
    </Slide>
  );
};
export default Header;
