import Text from "../Typography/Text";
import { Autocomplete as AutocompleteMui } from "@mui/lab";
import {
  Box,
  Chip,
  createFilterOptions,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import { ButtonRemove } from "../Buttons/Button";
import { TbAlertCircleFilled } from "react-icons/tb";
import Tooltip from "../Tooltip";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { AutocompleteHeaderLocationPaper } from "./Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const StyledAutocomplete = styled(AutocompleteMui)(
  ({ disabled, error, theme }) => ({
    "& .MuiChip-root": {
      backgroundColor: "#fff",
      border: `1px solid #DFDFDF`,
      color: "#4444444",
      height: "30px",
      opacity: 1,
      "&.Mui-disabled": {
        opacity: 1,
        pointerEvents: "none",
      },
      "& .MuiChip-deleteIcon": {
        pointerEvents: disabled ? "auto" : "initial",
      },
    },
    "& .MuiChip-colorError": {
      border: "1px solid #FF0000",
      color: "red",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "2px",
      paddingBottom: "2px",
      borderRadius: 8,
      backgroundColor: disabled ? "#f5f5f5" : "transparent",
      minHeight: "40px",
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      ...(error && { borderColor: theme.palette.error.main }),
    },
  }),
);

const filter = createFilterOptions();

export const AutocompleteProfile = ({
  title,
  options,
  limit,
  limitText,
  chipError = null,
  limitReached = false,
  ownValue = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Text text={title} mb={1} variant={"h3"} />
      <StyledAutocomplete
        multiple
        id="tags-outlined"
        autoHighlight
        options={options}
        filterSelectedOptions
        filterOptions={
          ownValue
            ? (options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                if (inputValue !== "") {
                  filtered.unshift(`${inputValue}`);
                }

                return filtered;
              }
            : undefined
        }
        slots={{
          paper: AutocompleteHeaderLocationPaper,
        }}
        {...(ownValue && {
          renderOption: (props, option, state) => {
            const { key, ...optionProps } = props;

            return (
              <Box {...optionProps}>
                {state.index === 0 && state.inputValue.length !== 0 && (
                  <AddCircleIcon color={"primary"} />
                )}
                <Text ml={0.4} text={option} variant={"body1"} />
              </Box>
            );
          },
        })}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const chipHasError = chipError?.find(
              (error) => error.value === option.title || error.value === option,
            );

            return (
              <Chip
                icon={
                  <>
                    {chipHasError && (
                      <Tooltip
                        placement="left"
                        title={
                          <Box>
                            {chipHasError.errors.map((error, i) => (
                              <Text
                                key={i}
                                text={error}
                                variant="body2"
                                color="error"
                              />
                            ))}
                          </Box>
                        }
                      >
                        <Box display="flex" ml={1}>
                          <TbAlertCircleFilled color={"red"} />
                        </Box>
                      </Tooltip>
                    )}
                  </>
                }
                color={chipHasError ? "error" : "default"}
                key={index}
                {...getTagProps({ index })}
                label={option.title || option.value || option}
                deleteIcon={<ButtonRemove />}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            onKeyDown={(event) => {
              if (event.key === "," && params.inputProps.value.trim() !== "") {
                event.preventDefault();
                const inputValue = params.inputProps.value.trim();

                if (inputValue) {
                  props.onChange(event, [
                    ...props.value,
                    { value: inputValue, key: inputValue },
                  ]);
                }

                params.inputProps.onChange({ target: { value: "" } });
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {props.error ? (
                    <>
                      <Tooltip placement="left" title={props.error}>
                        <Box display="flex">
                          <TbAlertCircleFilled
                            color={theme.palette.error.main}
                          />
                        </Box>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {limitReached && (
                        <Tooltip
                          sx={{
                            position: "absolute",
                            right: 12,
                            backgroundColor: "#f5f5f5",
                          }}
                          placement="left"
                          title={limitText}
                        >
                          <Box display="flex">
                            <LockOutlinedIcon color="primary" />
                          </Box>
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              ),
            }}
          />
        )}
        {...props}
      />
    </Box>
  );
};

export const DisplayItems = ({ name, children, ...props }) => {
  return (
    <Box
      position={"relative"}
      display={"flex"}
      gap={1}
      flexWrap={"wrap"}
      border={"1px solid #DFDFDF"}
      p={1}
      borderRadius={2}
      {...props}
    >
      <Box
        sx={{ transform: "translateY(-50%)" }}
        backgroundColor={"#fff"}
        position={"absolute"}
        top={0}
      >
        <Text text={name} variant={"h5"} />
      </Box>
      {children}
    </Box>
  );
};

export const DisplayItem = ({ text, onRemove = false, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      {...props}
    >
      <Box
        position={"relative"}
        border={"1px solid #DFDFDF"}
        alignItem={"center"}
        pt={1}
        pb={1}
        pl={2}
        pr={onRemove ? 0 : 2}
        display={"inline-flex"}
        borderRadius={4}
      >
        <Text text={text} variant={"body1"} />
        {onRemove && (
          <Box mr={1} ml={1} display={"flex"} alignItems={"center"}>
            <ButtonRemove onClick={onRemove} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
