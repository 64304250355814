import algoliasearch from "algoliasearch/lite";
import { history } from "instantsearch.js/es/lib/routers";

export const statsTranslations = {
  rootElementText({ nbHits, processingTimeMS, nbSortedHits, areHitsSorted }) {
    return areHitsSorted && nbHits !== nbSortedHits
      ? `${nbSortedHits.toLocaleString()} matched results ${nbHits.toLocaleString()}`
      : `${nbHits.toLocaleString()} results`;
  },
};

export const HitsPerPage = 50;

const IndexName = process.env.REACT_APP_ALGOLIA_INDEX;

const refinementListMapper = [
  {
    key: "working_mode",
    simplified: "mode",
    separator: "-",
  },
  {
    key: "working_time",
    simplified: "time",
    separator: "-",
  },
  {
    key: "categories",
    simplified: "cat",
    separator: ",",
  },
  {
    key: "tags",
    simplified: "tag",
    separator: ",",
  },
  {
    key: "contract",
    simplified: "cn",
    separator: "-",
  },
  {
    key: "languages.language",
    simplified: "lang",
    separator: ",",
  },
  {
    key: "salary_from",
    simplified: "from",
  },
];

export default {
  client: algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  ),
  index: {
    main: `${IndexName}-main`,
    suggestions: `${IndexName}-suggestions`,
  },
  hierarchicalAttributes: ["categories", "tags"],
  sorting: [
    { label: "Matched", value: `${IndexName}-main` },
    { label: "New", value: `${IndexName}-recently-updated` },
  ],
  routing: {
    router: history({
      cleanUrlOnDispose: false,
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[`${IndexName}-main`];

        const searchParams = new URLSearchParams(window.location.search);

        const allParams = Object.fromEntries(searchParams.entries());

        let returnState = {
          ...allParams,
          query: indexUiState.query,
          sort: indexUiState?.sortBy,
          hierarchicalMenu: {},
          refinementList: {},
          toggle: {},
        };
        refinementListMapper.map((obj) => {
          if (
            indexUiState.refinementList &&
            obj.key in indexUiState.refinementList
          ) {
            returnState[obj.simplified] = indexUiState.refinementList[
              obj.key
            ].join(obj.separator);
          }
        });

        if ("toggle" in indexUiState) {
          if ("isPremium" in indexUiState.toggle) {
            returnState.premium = "true";
          }
        }

        const lat = indexUiState.configure?.aroundLatLng
          ? indexUiState.configure.aroundLatLng.split(",")[0]
          : null;
        const lng = indexUiState.configure?.aroundLatLng
          ? indexUiState.configure.aroundLatLng.split(",")[1].trim()
          : null;
        const radius = indexUiState.configure?.aroundRadius
          ? indexUiState.configure?.aroundRadius / 1000
          : null;

        if (lat && lng && radius) {
          returnState.lat = lat;
          returnState.lng = lng;
          returnState.radius = radius;
          // keep place in the query string
          if (searchParams.get("place")) {
            returnState.place = searchParams.get("place");
          }
        }

        const filters = indexUiState.configure?.filters;

        if (filters) {
          returnState.filters = filters;
        }

        return returnState;
      },
      routeToState(routeState) {
        let returnState = {
          refinementList: {},
          sortBy: routeState.sort,
          toggle: {},
          configure: {},
        };

        refinementListMapper.map((obj) => {
          if (obj.simplified in routeState) {
            returnState.refinementList[obj.key] = routeState[
              obj.simplified
            ].split(obj.separator);
          }
        });

        if ("premium" in routeState) {
          returnState.toggle.isPremium = true;
        }

        if (routeState.lat && routeState.lng && routeState.radius) {
          returnState.configure.aroundLatLng = `${routeState.lat}, ${routeState.lng}`;
          returnState.configure.aroundRadius = routeState.radius * 1000;
        }

        if (routeState.place) {
          returnState.place = `${routeState.place}`;
        }

        if (routeState.filters) {
          returnState.configure.filters = routeState.filters;
        }

        return {
          [`${IndexName}-main`]: returnState,
        };
      },
    },
  },

  initialUiState: {
    [`${IndexName}-main`]: {
      query: "",
      page: 1,
      hierarchicalMenu: {},
      hitsPerPage: 40,
      refinementList: {},
      toggle: {},
      configure: {},
    },
  },
};
