import React, { useEffect, useRef, useState } from "react";
import "../old_components/Browse/Browse.scss";
import { Page } from "../views/Page";
import BrowseHeader from "../old_components/Browse/BrowseHeader";
import { useInfiniteHits, useInstantSearch } from "react-instantsearch";
import Item from "../components/Item/Item";
import SliderPopup from "../old_components/Browse/SliderPopup/SliderPopup";
import useUser from "../hooks/useUser";
import { BrowseNoResults } from "../components/Browse/BrowseNoResults";
import { Browse } from "../components/Browse/Browse";
import BrowseSkeleton from "../components/Browse/BrowseSkeleton";

const DisplayItems = ({ hits, setIndex, showMore, isLastPage }) => {
  /**
   *
   * Handle click on item to open popup
   * @param item
   *
   */

  const { setIndexUiState } = useInstantSearch();

  const { user } = useUser();

  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const handleClick = (item) => {
    const findIndex = hits.findIndex((hit) => hit.uuid === item.uuid);

    if (findIndex !== -1) {
      setIndex(findIndex);
    } else {
      alert("unable to open popup, cannot find index");
    }
  };

  const parsedHits = user
    ? hits.filter((hit) => user.hidden_profiles.includes(hit.uuid) === false)
    : hits;

  return (
    <div className="app-browse-loop">
      {parsedHits.map((hit, index) => (
        <Item
          key={index}
          item={hit}
          handleClick={handleClick}
          setIndexUiState={setIndexUiState}
        />
      ))}
      <div ref={sentinelRef} aria-hidden="true"></div>
    </div>
  );
};

export const BrowseInfiniteHits = (props) => {
  const { showSlider = true } = props;

  const [pristine, setPristine] = useState(true);

  const { hits, isLastPage, showMore } = useInfiniteHits(props);

  const { status } = useInstantSearch();

  const [index, setIndex] = useState(-1);

  /**
   * Infinite scroll
   */

  useEffect(() => {
    setPristine(false);
  }, []);

  if (pristine || (hits.length === 0 && status === "loading")) {
    return <BrowseSkeleton count={20} loading={true} />;
  }

  /**
   * No results
   */

  if (hits.length === 0) {
    return <BrowseNoResults />;
  }

  return (
    <>
      <DisplayItems
        hits={hits}
        setIndex={setIndex}
        showMore={showMore}
        isLastPage={isLastPage}
      />
      {status !== "idle" && <BrowseSkeleton count={20} />}
      {showSlider && index !== -1 && (
        <SliderPopup
          index={index}
          setIndex={setIndex}
          showMore={showMore}
          total={hits.length}
          items={hits}
        />
      )}
    </>
  );
};

const BrowseRecords = () => {
  const [ready, setReady] = useState(false);

  const { setIndexUiState } = useInstantSearch();

  useEffect(() => {
    setIndexUiState((prev) => {
      return {
        ...prev,
        configure: {
          filters: "",
        },
      };
    });

    setReady(true);
  }, []);

  return ready ? <BrowseInfiniteHits /> : <BrowseSkeleton />;
};

const Home = () => {
  return (
    <Page title={`Find your worker`}>
      <Browse>
        <BrowseHeader setMapEnabled={false} />
        <BrowseRecords />
      </Browse>
    </Page>
  );
};

export default Home;
