import * as Yup from "yup";

const accountEditSchema = (t) => ({
  name: Yup.string()
    .required(t("profile.profile.edit.schemaValidation.name.required"))
    .min(3, "Enter at least 3 characters")
    .max("100", "Maximum length is 100 characters"),
  about: Yup.string()
    .required(t("profile.profile.edit.schemaValidation.about.required"))
    .min(100, "Enter at least 100 characters")
    .max("2000", "Maximum length is 2000 characters"),
  // contact_methods: Yup.object().shape({
  //   direct: Yup.boolean().test(
  //     "hasTrueOrOtherMethod",
  //     t("profile.profile.edit.schemaValidation.applicationWays.oneOf"),
  //     function (value) {
  //       return (
  //         value === true ||
  //         this.parent.quick === true ||
  //         this.parent.email !== null ||
  //         this.parent.phone !== null
  //       );
  //     },
  //   ),
  //   email: Yup.string()
  //     .nullable()
  //     .test(
  //       "hasValue",
  //       t("profile.profile.edit.schemaValidation.applicationWays.oneOf"),
  //       function (value) {
  //         return (
  //           this.parent.direct === true ||
  //           this.parent.phone !== null ||
  //           this.parent.quick === true ||
  //           value !== null
  //         );
  //       },
  //     )
  //     .test(
  //       "isEmail",
  //       t(
  //         "profile.profile.edit.schemaValidation.applicationWays.email.correct",
  //       ),
  //       function (value) {
  //         if (
  //           value !== null &&
  //           !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)
  //         ) {
  //           return false;
  //         }
  //         return true;
  //       },
  //     ),
  //   phone: Yup.string()
  //     .nullable()
  //     .test(
  //       "hasValue",
  //       "You must provide a phone number or choose another way of contact",
  //       function (value) {
  //         return (
  //           this.parent.direct === true ||
  //           this.parent.email !== null ||
  //           this.parent.quick === true ||
  //           value !== null
  //         );
  //       },
  //     )
  //     .test("isPhoneNumber", "Phone number is incorrect", function (value) {
  //       if (value) {
  //         const cleanedValue = value.replace(/[^0-9]/g, "");
  //
  //         const isValid =
  //           cleanedValue.length === 10 || cleanedValue.length === 11;
  //
  //         return isValid;
  //       }
  //       return true;
  //     }),
  // }),
  categories: Yup.array().min(
    1,
    t("profile.profile.edit.schemaValidation.category.required"),
  ),
  salary_from: Yup.number()
    .typeError("Salary from must be a number")
    .positive("Salary from must be greater than zero"),
  salary_to: Yup.number()
    .typeError("Salary to must be a number")
    .positive("Salary to must be greater than zero")
    .test(
      "is-greater-than-salary-from",
      "Salary to must be greater than Salary from",
      function (value) {
        const { salary_from } = this.parent;

        return (
          salary_from === undefined ||
          salary_from === null ||
          salary_from === "" ||
          value === undefined ||
          value === null ||
          value === "" ||
          value > salary_from
        );
      },
    ),
});

export default accountEditSchema;
