import { Box, Portal, Typography, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SellIcon from "@mui/icons-material/Sell";
import FolderIcon from "@mui/icons-material/Folder";

export const SearchHeader = ({ children, open, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minWidth: {
          xs: "100%",
          md: "20vw",
        },
        maxWidth: {
          xs: "100%",
          md: "20vw",
        },
        borderRadius: "26px",
        position: "relative",
      }}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          position: "relative",
          zIndex: open ? 6 : 1,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: "0 0.8rem",
          border: open
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid  ${theme.palette.border.input}`,
          borderRadius: "2rem",
          height: "48px",
          "&:hover": {
            border: `1px solid #000`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const SearchHeaderDropdown = ({ children, open, ...props }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        border: "none !important",
        borderRadius: {
          xs: "0",
          md: 4,
        },
        boxShadow: "0 10px 50px 10px #968aba33 !important",
        display: "block",
        minHeight: "3rem",
        height: {
          xs: "100vh",
          md: "auto",
        },
        opacity: open ? 1 : 0,
        padding: 2,
        paddingTop: {
          xs: "4.5rem",
          md: 2,
        },
        paddingBottom: {
          xs: "8rem",
          md: 2,
        },
        left: 0,
        top: {
          xs: 0,
          md: "100%",
        },
        position: {
          xs: "fixed",
          md: "absolute",
        },
        transition: "all .3s linear, opacity .1s linear",
        transform: {
          xs: null,
          md: open ? "translateY(0)" : "translateY(-0.5rem)",
        },
        visibility: open ? "visible" : "hidden",
        marginTop: {
          xs: 0,
          md: 1,
        },
        zIndex: {
          xs: 100,
          md: 5,
        },
        overflow: {
          xs: "auto",
          md: "hidden",
        },
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export const SearchHeaderDropdownMobile = ({ open, ...props }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  return (
    <Portal>
      <SearchHeaderDropdown open={open} {...props} />
    </Portal>
  );
};

export const SearchHeaderDropdownGroupBadge = ({
  item,
  handleClick = null,
  active,
}) => {
  const theme = useTheme();
  const conf = {
    tags: {
      icon: <SellIcon sx={{ fontSize: "12px", marginRight: "5px" }} />,
      bg: {
        initial: "#d6e2ed",
        active: "#42A2F0",
      },
      color: {
        initial: "#000",
        active: "#fff",
      },
      hover: {
        initial: {
          backgroundColor: "#42A2F0",
          color: "#fff",
        },
        active: {
          opacity: 0.8,
        },
      },
    },
    categories: {
      icon: <FolderIcon sx={{ fontSize: "12px", marginRight: "5px" }} />,
      bg: {
        initial: "#e7dbfb",
        active: theme.palette.primary.main,
      },
      color: {
        initial: theme.palette.primary.main,
        active: "#fff",
      },
      hover: {
        initial: {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
        active: {
          opacity: 0.8,
        },
      },
    },
    query: {
      icon: <SearchIcon sx={{ fontSize: "13px", marginRight: "5px" }} />,
      bg: {
        initial: "#ddd",
        active: "#000",
      },
      color: {
        initial: theme.palette.primary.main,
        active: "#fff",
      },
      hover: {
        initial: {
          backgroundColor: "#42A2F0",
          color: "#fff",
        },
        active: {
          opacity: 0.8,
        },
      },
    },
  };
  return (
    <Box
      sx={{
        fontSize: {
          xs: "1rem",
          md: "0.8rem",
        },
        backgroundColor: active
          ? conf[item.attribute].bg.active
          : conf[item.attribute].bg.initial,
        borderRadius: "50px",
        margin: "0.1rem",
        padding: {
          xs: "0.5rem 1rem",
          md: "0.2rem 0.6rem",
        },
        cursor: "pointer",
        fontWeight: 400,
        lineHeight: "17px",
        color: active
          ? conf[item.attribute].color.active
          : conf[item.attribute].color.initial,
        ":hover": active
          ? conf[item.attribute].hover.active
          : conf[item.attribute].hover.initial,
      }}
      onClick={() => handleClick && handleClick(item)}
    >
      {active && conf[item.attribute].icon}
      <span>{item.title}</span>
      {active && (
        <ClearIcon
          sx={{ fontSize: "15px", marginLeft: "3px", marginRight: "-4px" }}
        />
      )}
    </Box>
  );
};

export const SearchHeaderDropdownGroup = ({
  title,
  items,
  handleClick,
  active,
}) => {
  return (
    <Box>
      <Typography
        variant={"h5"}
        sx={{
          fontSize: "0.8rem",
          fontWeight: 300,
          color: "#999",
          marginBottom: 1,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          position: "relative",
          margin: "0 -0.1rem 0.5rem -0.1rem",
        }}
      >
        {items.map((item, index) => (
          <SearchHeaderDropdownGroupBadge
            active={active}
            key={index}
            item={item}
            handleClick={handleClick}
          />
        ))}
      </Box>
    </Box>
  );
};
