import React, { useState } from "react";
import { MoreVertical } from "react-feather";
import CloseOutside from "../../../components/CloseOutside";
import useUser from "../../../hooks/useUser";
import useHide from "../../../hooks/useHide";
import { useTranslation } from "react-i18next";
import usePopup from "../../../hooks/usePopup";
import ShareWindow from "./ShareWindow";
import ReportViolation from "./ReportViolation";
import AnimatedDropdown from "../AnimatedDropdown";
import useWatchlist from "../../../hooks/useWatchlist";
import { useInstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router";
import useQueryString from "../../../hooks/useQueryString";

const ActionHide = ({ item, setVisible }) => {
  const { user } = useUser();

  const { addQuery } = useQueryString();

  const { t } = useTranslation();

  const { handleHide, isHidden, loading } = useHide(item.uuid);

  if (isHidden) {
    return;
  }

  const handleClick = () => {
    if (user) {
      handleHide();
      setVisible(false);
    } else {
      addQuery("auth-popup", "login");
    }
  };

  return (
    <li className={`list-group-item bg-transparent'`} onClick={handleClick}>
      {loading
        ? "Please wait"
        : t("parts.misc.moreOptionsPopup.options.noMore.title")}
    </li>
  );
};

const ActionShare = ({ item, setVisible }) => {
  const { t } = useTranslation();

  const { openPopup } = usePopup();

  const handleClick = () => {
    openPopup(ShareWindow, { item });

    setVisible(false);
  };

  return (
    <>
      <li className={`list-group-item bg-transparent`} onClick={handleClick}>
        {t("parts.misc.moreOptionsPopup.options.share.title")}
      </li>
    </>
  );
};

const ActionObserve = ({ item, setVisible }) => {
  const { user } = useUser();

  const { observed, handleObserve } = useWatchlist(item.userId || item.uuid);

  if (user && (user.id === item.uid || user.id === item.id)) return null;

  return (
    <li
      className={`list-group-item bg-transparent`}
      onClick={() => handleObserve(item.userId || item.uuid)}
    >
      {observed ? "Cancel observation" : "Observe"}
    </li>
  );
};

const ActionSimilar = ({ item, setVisible }) => {
  const { t } = useTranslation();

  const { setIndexUiState } = useInstantSearch();

  const navigate = useNavigate();

  const showSimilar = () => {
    setIndexUiState([]);
    navigate(`/similar/${item.uuid || item.userId}`);
  };

  return (
    <li className={`list-group-item bg-transparent`} onClick={showSimilar}>
      {t("parts.misc.moreOptionsPopup.options.showSimilar.title")}
    </li>
  );
};

const ActionReport = ({ item, setVisible }) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { openPopup } = usePopup();

  const { addQuery } = useQueryString();

  const handleClick = () => {
    if (user) {
      openPopup(ReportViolation, { item });
    } else {
      addQuery("auth-popup", "login");
    }

    setVisible(false);
  };

  return (
    <>
      <li className={`list-group-item bg-transparent`} onClick={handleClick}>
        {t("parts.misc.moreOptionsPopup.options.report.title")}
      </li>
    </>
  );
};

const ActionClose = ({ item, setVisible }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setVisible(false);
  };

  return (
    <li className={`list-group-item bg-transparent`} onClick={handleClick}>
      {t("parts.misc.moreOptionsPopup.options.close.title")}
    </li>
  );
};

const MoreOptionsContent = ({ setVisible, item, options = [] }) => {
  return (
    <ul className={"p-0"}>
      {options.includes("Hide") && (
        <ActionHide item={item} setVisible={setVisible} />
      )}
      {options.includes("Share") && (
        <ActionShare item={item} setVisible={setVisible} />
      )}
      {options.includes("Observe") && (
        <ActionObserve item={item} setVisible={setVisible} />
      )}
      {options.includes("ShowSimilar") && (
        <ActionSimilar item={item} setVisible={setVisible} />
      )}
      {options.includes("Report") && (
        <ActionReport item={item} setVisible={setVisible} />
      )}
      {options.includes("Close") && (
        <ActionClose item={item} setVisible={setVisible} />
      )}
    </ul>
  );
};

const MoreOptions = ({ item, options }) => {
  const [visible, setVisible] = useState(false);

  const { isIOS } = useUser();

  const toggleVisible = () => setVisible((prevState) => !prevState);

  return (
    <CloseOutside handleClose={() => setVisible(false)}>
      <div className={"item-more-options"}>
        <button
          type="button"
          className={"more-options"}
          onClick={!isIOS ? toggleVisible : undefined}
          onTouchStart={isIOS ? toggleVisible : undefined}
        >
          <div title="Więcej opcji" className={"more-options-icon"}>
            <MoreVertical size={23} />
          </div>
        </button>
        <AnimatedDropdown
          isOpen={visible}
          className={"dropdown-more-options end-0"}
        >
          {visible && (
            <MoreOptionsContent
              setVisible={setVisible}
              item={item}
              options={options}
            />
          )}
        </AnimatedDropdown>
      </div>
    </CloseOutside>
  );
};

export default MoreOptions;
