import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import usePopup from "../../hooks/usePopup";
import useUser from "../../hooks/useUser";
import useBrowse from "../../hooks/useBrowse";
import { useMediaQuery, useTheme } from "@mui/material";
import Notifications from "../Header/Notifications";
import Header from "../Header/Header";
import Logo from "../../components/Page/Logo";
import Search from "../Header/Search/Search";
import FooterMobileNavigation from "../Footer/FooterMobileNavigation";
import Footer from "../Footer/Footer";
import HeaderNavigation from "../Header/HeaderNavigation";
import { FooterPortalDesktop } from "../Footer/FooterPortal";
import AuthView from "../Auth/AuthView";

const LayoutHeader = () => {
  const { resetIndex } = useBrowse();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  if (location.pathname !== "/" && isMobile) {
    return null;
  }

  return (
    <>
      <Notifications />
      <Header>
        <Link to="/" onClick={resetIndex}>
          <Logo />
        </Link>
        <Search />
        <HeaderNavigation />
      </Header>
    </>
  );
};

const LayoutFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isMobile ? <FooterMobileNavigation /> : <FooterPortalDesktop />}
      <Footer />
    </>
  );
};

const Default = () => {
  const { popupContent, popupProps, popupsContent } = usePopup();

  const { isMobile } = useUser();

  const ComponentToRender = popupContent;

  useEffect(() => {
    popupContent
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "scroll");
  }, [popupContent]);

  return (
    <>
      {popupContent && (
        <div className="app-popup visible">
          <ComponentToRender {...popupProps} />
        </div>
      )}
      {popupsContent.length !== 0 && isMobile && (
        <>
          {popupsContent.map((el, index) => {
            el.visible
              ? (document.body.style.overflowY = "hidden")
              : (document.body.style.overflowY = "scroll");
            const ComponentToRender = el.component;
            return (
              <div
                key={index}
                className={`app-popup ${el.visible ? "visible" : "hidden"}`}
              >
                <ComponentToRender {...popupProps} />
              </div>
            );
          })}
        </>
      )}
      <LayoutHeader />
      <Outlet />
      <AuthView />
      <LayoutFooter />
    </>
  );
};

export default Default;
