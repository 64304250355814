import { useFormikContext } from "formik";
import useUser from "./useUser";
import useConfig from "./useConfig";

const usePlan = () => {
  const { user } = useUser();

  const { plans } = useConfig();

  const { values } = useFormikContext();

  const isProfileLimitReached = (valueKey, limitKey) => {
    return values[valueKey].length >= plans[user.plan_id].limits[limitKey];
  };

  return {
    isProfileLimitReached,
  };
};

export default usePlan;
