import LoadingButton from "@mui/lab/LoadingButton";
import { Button, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ButtonLoadingStyled = styled(LoadingButton)(({
  theme,
  color,
  variant,
  size = "medium",
}) => {
  const sizes = {
    small: 32,
    medium: 40,
    large: 48,
  };
  return {
    fontWeight: 400,
    borderRadius: 48,
    height: sizes[size],
    fontFamily: "'Poppins', sans-serif",
    ...(variant === "outlined" && {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
      },
    }),
    ".MuiButton-endIcon": {
      position: "absolute",
      right: 14,
    },
    ".MuiButton-startIcon": {
      position: "absolute",
      left: 14,
    },
  };
});

export const ButtonPrimary = ({
  text,
  variant = "contained",
  children,
  ...props
}) => {
  return (
    <ButtonLoadingStyled disableElevation variant={variant} {...props}>
      {text}
      {children}
    </ButtonLoadingStyled>
  );
};

const ButtonCircleStyled = styled(LoadingButton)(
  ({ theme, variant, size, color }) => ({
    borderRadius: 100,
    width: 40,
    minWidth: 40,
    padding: 0,
    height: 40,
  }),
);

export const ButtonCircle = ({ children, variant, ...props }) => {
  return (
    <ButtonCircleStyled variant={variant} {...props}>
      {children}
    </ButtonCircleStyled>
  );
};

const ButtonCloseStyled = styled(Button)(({ theme, size }) => ({
  borderRadius: 50,
  minWidth: 28,
  height: 28,
  padding: 0,
  backgroundColor: "#D0D6DE",
}));

export const ButtonClose = ({ ...props }) => {
  return (
    <ButtonCloseStyled {...props}>
      <CloseIcon sx={{ fontSize: "20px", stroke: "#ffffff", strokeWidth: 2 }} />
    </ButtonCloseStyled>
  );
};

const ButtonRemoveStyled = styled(Button)({
  borderRadius: 50,
  minWidth: 20,
  height: 20,
  padding: 0,
  backgroundColor: "#D0D6DE",
  zIndex: 2,
  svg: {
    color: "#fff",
    transition: "0.3s",
    width: 16,
  },
});

export const ButtonRemove = ({ ...props }) => {
  return (
    <ButtonRemoveStyled {...props}>
      <CloseIcon />
    </ButtonRemoveStyled>
  );
};

const ButtonInfoStyled = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 20px",
  fontWeight: 400,
  border: "1px solid",
  backgroundColor: "#ccc",
  borderColor: "#ccc",
  color: "#fff",
  borderRadius: "30px",
  fontFamily: "'Poppins', sans-serif",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#ccc",
    boxShadow: "none",
    color: "#ccc",
  },
  "&:active": {
    backgroundColor: "transparent",
    borderColor: "#ccc",
    boxShadow: "none",
    color: "#ccc",
  },
});

export const ButtonInfo = ({ children, ...props }) => {
  return <ButtonInfoStyled {...props}>{children}</ButtonInfoStyled>;
};

const ButtonInputStyled = styled(LoadingButton)(({
  theme,
  size = "medium",
}) => {
  const sizes = {
    small: 32,
    medium: 40,
    large: 48,
  };
  return {
    fontWeight: 300,
    borderRadius: 48,
    height: sizes[size],
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.border.input}`,
    color: `#000`,
    fontSize: 13,
  };
});

export const ButtonInput = ({ text, children, ...props }) => {
  return (
    <ButtonInputStyled disableElevation color={"white"} {...props}>
      {text}
      {children}
    </ButtonInputStyled>
  );
};

export default ButtonPrimary;
