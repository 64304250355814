import React, { useEffect, useState } from "react";
import { Badge, Box, IconButton, Skeleton, Stack } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import useWebSocket from "../../hooks/useWebSocket";
import { formatDate } from "../../utils/appUtils";

export const InboxChatContentScrollDown = ({ onClick, amount = 1, sx }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        background:
          "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <IconButton onClick={onClick}>
        <Badge badgeContent={amount} color="danger">
          <ExpandCircleDownIcon sx={{ fontSize: 40, color: "primary.main" }} />
        </Badge>
      </IconButton>
    </Box>
  );
};

export const InboxConversationsSkeleton = () => {
  return (
    <Stack spacing={1} sx={{ maxHeight: 300, overflowY: "auto" }}>
      <Skeleton variant="rounded" width={"100%"} height={56} />
      <Skeleton variant="rounded" width={"100%"} height={56} />
      <Skeleton variant="rounded" width={"100%"} height={56} />
      <Skeleton variant="rounded" width={"100%"} height={56} />
      <Skeleton variant="rounded" width={"100%"} height={56} />
    </Stack>
  );
};

export const InboxChatContentWrapperSkeleton = () => {
  return (
    <Stack spacing={1} sx={{ maxHeight: 300, overflowY: "auto" }}>
      <Skeleton variant="rounded" width={"50%"} height={32} />
      <Skeleton
        variant="rounded"
        width={"30%"}
        height={32}
        sx={{ marginRight: 0, alignSelf: "flex-end" }}
      />
      <Skeleton
        variant="rounded"
        width={"50%"}
        height={32}
        sx={{ marginRight: 0, alignSelf: "flex-end" }}
      />
      <Skeleton variant="rounded" width={"20%"} height={32} />
      <Skeleton variant="rounded" width={"10%"} height={32} />
      <Skeleton
        variant="rounded"
        width={"50%"}
        height={32}
        sx={{ marginRight: 0, alignSelf: "flex-end" }}
      />
    </Stack>
  );
};

export const InboxChatContentHistoryTrigger = ({
  receiver,
  messages,
  scrollData,
  chatRef,
}) => {
  const [loading, setLoading] = useState(false);
  const { requests } = useWebSocket();
  const preventScroll = () => {
    if (chatRef.current) {
      const scroll = scrollData.scrollHeight - scrollData.scrollBottom + 10;
      chatRef.current.scrollTop = scroll;
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [messages]);
  // Load more history
  useEffect(() => {
    if (scrollData.scrollTop < 5 && !scrollData.pristine && messages.last) {
      preventScroll();
      if (!loading) {
        setLoading(true);
        requests.getConversationHistory(receiver, messages.last);
      }
    }
  }, [scrollData, loading]);
  return loading ? <InboxChatContentWrapperSkeleton /> : null;
};

export const InboxChatMessageDivider = ({ messages, messageIndex }) => {
  let value = "";

  const drawDateDivider = (index) => {
    if (messages[index + 1]) {
      const currentDate = new Date(Number(messages[index].time)).toDateString();
      const nextDate = new Date(
        Number(messages[index + 1].time),
      ).toDateString();

      if (currentDate !== nextDate) {
        value = formatDate(new Date(parseInt(messages[index + 1].time)));
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {drawDateDivider(messageIndex) && (
        <Box
          sx={{
            borderBottom: "1px solid #D0D6DE",
            width: "100%",
            textAlign: "center",
          }}
        >
          {value}
        </Box>
      )}
    </>
  );
};
