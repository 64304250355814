import useApi from "../../hooks/useApi";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { LoaderComponent } from "../../components/Loaders/Loader";
import AboutMeView from "../../views/Account/Edit/AboutMeView";
import GalleryView from "../../views/Account/Edit/GalleryView";
import ButtonPrimary from "../../components/Buttons/Button";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import SkillsView from "../../views/Account/Edit/SkillsView";
import AttributesView from "../../views/Account/Edit/AttributesView";
import RequirmentsView from "../../views/Account/Edit/RequirmentsView";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ExperienceView from "../../views/Account/Edit/ExperienceView";
import { PageHeader } from "../../views/Page";
import Tabs from "../../components/Page/Tabs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useError from "../../hooks/useError";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate } from "react-router";
import LoaderOverlay from "../../components/Loaders/LoaderOverlay";
import { isObjectEmpty } from "../../utils/appUtils";
import UnsavedChangesModal from "../../views/Account/Parts/UnsavedChangesModal";
import { useNotification } from "../../hooks/useNotifications";
import accountEditSchema from "../../validations/account/accountEditSchema";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import { SwitchWithoutLabel } from "../../components/Forms/Toggle";
import Text from "../../components/Typography/Text";
import Modal from "../../components/Modals/Modal";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ButtonVideoPlay from "../../components/Buttons/ButtonVideoPlay";
import { useSearchParams } from "react-router-dom";
import useQueryString from "../../hooks/useQueryString";

export const InformationIcon = ({ text, title }) => {
  const [modalInformationVisible, setModalInformationVisible] = useState(false);

  return (
    <>
      <HelpOutlineOutlinedIcon
        color={"primary"}
        cursor={"pointer"}
        onClick={() => {
          setModalInformationVisible(true);
        }}
      />
      <Modal
        open={modalInformationVisible}
        onClose={() => setModalInformationVisible(false)}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"}>
            <HelpOutlineOutlinedIcon color={"primary"} />
            <Text ml={1} text={title} variant={"h3"} />
          </Box>
          <Text text={text} mt={2} variant={"body1"} />
        </Box>
      </Modal>
    </>
  );
};

const Edit = () => {
  const { request } = useApi();

  const [data, setData] = useState();

  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [visible, setVisible] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const { handleSetIndexStatusValue } = useError();

  const { addNotification } = useNotification();

  const navigate = useNavigate();

  const [profileVisibleModal, setProfileVisibleModal] = useState(false);

  const [saveModal, setSaveModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const { addQuery } = useQueryString();

  const [searchParams] = useSearchParams();

  const value = +searchParams.get("tab-index");

  const configuration = [
    {
      key: "skills",
      section_id: "skills",
      title: "Skills",
      components: <SkillsView />,
      errors: ["skills"],
    },
    {
      key: "experience",
      section_id: "experience",
      title: "Experience",
      components: <ExperienceView />,
    },
    {
      key: "attributes",
      section_id: "attributes",
      title: "Attributes",
      components: <AttributesView />,
      errors: ["interests", "character"],
    },
    {
      key: "requirements",
      section_id: "requirements",
      title: "Requirements",
      components: <RequirmentsView />,
      errors: ["salary_from", "salary_to", "requirements"],
    },
  ];

  const configurationTabs = isMobile
    ? [
        {
          key: "about-me",
          section_id: "about-me",
          title: "About me",
          components: <AboutMeView />,
          errors: ["name", "about", "categories"],
        },
        {
          key: "gallery",
          section_id: "gallery",
          title: "Gallery",
          components: <GalleryView />,
          errors: ["thumbnail", "gallery"],
        },
        ...configuration,
      ]
    : [
        {
          key: "about-me",
          section_id: "about-me",
          title: "About me",
          components: <AboutMeView />,
          errors: ["name", "about", "categories", "thumbnail", "gallery"],
        },
        ...configuration,
      ];

  useEffect(() => {
    setLoading(true);
    request({
      method: "get",
      url: "/profile/details",
      onSuccess: (data) => {
        setVisible(data.is_visible);
        setData({
          ...data,
        });
      },
    }).finally(() => setLoading(false));
  }, []);

  const handleSubmit = async (values) => {
    const valuesCopy = { ...values };
    delete valuesCopy.isPristine;
    setSaveLoading(true);

    await request({
      url: `/profile/details`,
      method: "post",
      body: {
        ...valuesCopy,
        is_visible: visible,
        contact_methods: {
          direct: true,
          phone: null,
          email: null,
        },
      },
      onSuccess: (data) => {
        setData(data);
        handleSetIndexStatusValue("pending");
        addNotification("success", "The profile has been successfully saved");
        setSaveModal(true);
      },
      onError: (data) => {
        addNotification(
          "error",
          "Something went wrong while saving your profile",
        );
      },
    }).finally(() => {
      setSaveLoading(false);
    });
  };

  const { detailsError, thumbnailError, galleryError } = useError();

  return (
    <>
      <Box mb={2}>
        <PageHeader
          tabPercent={((value + 1) * 100) / configurationTabs.length - 1}
          text={"Profile"}
          profileStatus
          tooltipText={"Lorem ipsum"}
        >
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Text text={"HIDE"} variant={"h6"} />
            <SwitchWithoutLabel
              checked={visible}
              // onChange={(e) => setVisible(e.target.checked)}
              onChange={(e) => setProfileVisibleModal(true)}
            />
            <Text text={"SHOW"} variant={"h6"} />
          </Box>
        </PageHeader>
        <Modal
          open={profileVisibleModal}
          onClose={() => setProfileVisibleModal(false)}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <VisibilityOffOutlinedIcon
              sx={{ width: 96, height: 96 }}
              color={"secondary"}
            />
            <Text
              mt={1}
              text={"Your profile will not be visible"}
              variant={"h3"}
              textAlign={"center"}
            />
            <Text
              mt={1}
              text={
                "Want to hide your profile? Your profile will not appear in search results unless you disable this feature."
              }
              textAlign={"center"}
              variant={"body1"}
            />
            <Box display={"flex"} width={"100%"} gap={1} mt={3}>
              <ButtonPrimary
                onClick={() => setProfileVisibleModal(false)}
                fullWidth
                color={"info"}
              >
                Cancel
              </ButtonPrimary>
              <ButtonPrimary
                onClick={() => {
                  setVisible((prevState) => !prevState);
                  setProfileVisibleModal(false);
                }}
                fullWidth
              >
                Ok
              </ButtonPrimary>
            </Box>
          </Box>
        </Modal>
      </Box>
      {loading || !data ? (
        <LoaderComponent width={"100%"} height={400} />
      ) : (
        <>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={{
              ...data,
              isPristine: data.categories.length === 0,
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape(accountEditSchema(t))}
          >
            {({
              handleSubmit,
              errors,
              dirty,
              isValid,
              values,
              validateForm,
            }) => {
              const handleSaveClick = async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  addNotification(
                    "error",
                    `There are errors in the form. Please fix them: \n ${Object.entries(
                      errors,
                    )
                      .map(([key, value]) => `${key}: ${value}`)
                      .join("\n")}`,
                  );
                } else {
                  handleSubmit();
                }
              };

              return (
                <form onSubmit={handleSubmit}>
                  {dirty && (
                    <UnsavedChangesModal
                      when={dirty}
                      handleSubmit={handleSubmit}
                      text={t("parts.misc.unsavedChangesPopup.title")}
                      isValid={isValid}
                    />
                  )}
                  <Tabs
                    tabs={configurationTabs}
                    syncWithUrl={true}
                    errorsConfig={{
                      ...errors,
                      ...detailsError,
                      ...(isObjectEmpty(thumbnailError)
                        ? {}
                        : { thumbnail: thumbnailError }),
                      ...(galleryError.length === 0
                        ? {}
                        : { gallery: galleryError }),
                    }}
                  />
                  <Box mt={9} display={"flex"} gap={1}>
                    {value !== 0 && (
                      <ButtonPrimary
                        type="button"
                        startIcon={<ChevronLeftIcon />}
                        fullWidth
                        onClick={() => addQuery("tab-index", value - 1)}
                      >
                        Back
                      </ButtonPrimary>
                    )}
                    {!values.isPristine && (
                      <ButtonPrimary
                        type="button"
                        color={"secondary"}
                        fullWidth
                        onClick={handleSaveClick}
                      >
                        save
                      </ButtonPrimary>
                    )}
                    <ButtonPrimary
                      fullWidth
                      endIcon={
                        value === configurationTabs.length - 1 ? (
                          <CheckIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      }
                      onClick={() => {
                        if (value === configurationTabs.length - 1) {
                          handleSaveClick();
                        } else {
                          addQuery("tab-index", value + 1);
                        }
                      }}
                    >
                      {value === configurationTabs.length - 1
                        ? "Finish"
                        : "Next"}
                    </ButtonPrimary>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </>
      )}
      <Modal open={saveModal} onClose={() => setSaveModal(false)}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <HowToRegOutlinedIcon
            sx={{ width: 96, height: 96 }}
            color={"secondary"}
          />
          <Text
            mt={1}
            text={"Your profile has been successfully saved."}
            variant={"h3"}
            textAlign={"center"}
          />
          <Text
            mt={1}
            text={
              "Verification is ongoing, it may take about 5 minutes. We will notify you when it is finished."
            }
            textAlign={"center"}
            variant={"body1"}
          />
          <Box display={"flex"} width={"100%"} gap={1} mt={3}>
            <ButtonPrimary
              onClick={() => setSaveModal(false)}
              fullWidth
              color={"info"}
            >
              Back to profile
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() => {
                setSaveModal(false);
                navigate("/");
              }}
              fullWidth
            >
              Go to main page
            </ButtonPrimary>
          </Box>
        </Box>
      </Modal>
      <LoaderOverlay active={saveLoading} />
      <ButtonVideoPlay />
    </>
  );
};

export default Edit;
