import { Formik } from "formik";
import TextField from "../../components/Forms/TextField";
import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import ButtonPrimary from "../../components/Buttons/Button";
import { Box } from "@mui/material";
import authForgotSchema from "../../validations/auth/authForgotSchema";
import * as Yup from "yup";
import Tooltip from "../../components/Tooltip";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { resetPassword } from "aws-amplify/auth";

const AuthForgotView = ({ setPopupActive }) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const initialData = {
    email: "",
  };

  const sendRestartLink = async (values) => {
    setError(null);
    setLoading(true);
    try {
      const { isPasswordReset, nextStep } = await resetPassword({
        username: values.email,
      });
      if (
        nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE" &&
        !isPasswordReset
      ) {
        setPopupActive("forgot-verify");
        localStorage.setItem("forgot-email", values.email);
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={sendRestartLink}
      initialValues={initialData}
      validationSchema={Yup.object().shape(authForgotSchema())}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} mt={2} gap={1.5}>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              label={"E-mail"}
              name={"email"}
              error={errors.email}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <>
                      {errors.email && (
                        <Tooltip arrow placement="left" title={errors.email}>
                          <FmdBadIcon color={"error"} />
                        </Tooltip>
                      )}
                    </>
                  ),
                },
              }}
            />
            <ButtonPrimary type={"submit"} fullWidth loading={loading}>
              Send Code
            </ButtonPrimary>
          </Box>
          <Box
            onClick={() => setPopupActive("login")}
            display={"flex"}
            justifyContent={"center"}
            mt={1}
          >
            <Text text={"back"} variant={"overline"} color={"#000"} />
          </Box>
          <Text text={error} variant={"body2"} color={"error"} />
        </form>
      )}
    </Formik>
  );
};

export default AuthForgotView;
