import useConfig from "../../../hooks/useConfig";
import { useFormikContext } from "formik";
import { AutocompleteProfile } from "../../../components/Autocompletes/AutocompleteProfile";
import React from "react";
import { Box, InputAdornment, useTheme } from "@mui/material";
import Text from "../../../components/Typography/Text";
import TextField from "../../../components/Forms/TextField";
import { Select } from "../../../components/Forms/Select";
import useError from "../../../hooks/useError";
import { TbAlertCircleFilled } from "react-icons/tb";
import Tooltip from "../../../components/Tooltip";

const SalaryTypeConfig = () => {
  const { values } = useFormikContext();

  return (
    <InputAdornment position={"end"}>
      {+values.salary_type === 0 && "hour"}
      {+values.salary_type === 1 && "week"}
      {+values.salary_type === 2 && "month"}
    </InputAdornment>
  );
};

const RequirementsView = () => {
  const { config } = useConfig();

  const { values, setFieldValue, handleBlur, handleChange, errors } =
    useFormikContext();

  const theme = useTheme();

  const { detailsError } = useError();

  const getLabelForValue = (value, items) => {
    const selectedItem = items.find((item) => item.key === +value);
    return selectedItem ? selectedItem : value;
  };

  return (
    <>
      <Box mb={2}>
        <AutocompleteProfile
          title={"Type of contract"}
          options={config.contract.items}
          getOptionLabel={(option) => option.value}
          value={values.contract.map((value) =>
            getLabelForValue(value, config.contract.items),
          )}
          onChange={(event, newValue) => {
            setFieldValue(
              "contract",
              newValue.map((item) => item.key),
            );
          }}
          name={"contract"}
        />
      </Box>
      <Box mb={2}>
        <AutocompleteProfile
          title={"Working time"}
          options={config.working_time.items}
          getOptionLabel={(option) => option.value}
          value={
            values.working_time
              ? values.working_time.map((value) =>
                  getLabelForValue(value, config.working_time.items),
                )
              : []
          }
          onChange={(event, newValue) => {
            setFieldValue(
              "working_time",
              newValue.map((item) => item.key),
            );
          }}
          name={"working_time"}
        />
      </Box>
      <Box mb={2}>
        <AutocompleteProfile
          title={"Mode of work"}
          options={config.working_mode.items}
          getOptionLabel={(option) => option.value}
          value={
            values.working_mode
              ? values.working_mode.map((value) =>
                  getLabelForValue(value, config.working_mode.items),
                )
              : []
          }
          onChange={(event, newValue) => {
            setFieldValue(
              "working_mode",
              newValue.map((item) => item.key),
            );
          }}
          name={"working_mode"}
        />
      </Box>
      <Box mb={2}>
        <Text text={"Salary"} mb={1} variant={"h3"} />
        <Box display={"flex"} gap={1} mb={2}>
          <TextField
            fullWidth
            label={"From"}
            name={"salary_from"}
            error={errors.salary_from}
            value={values.salary_from}
            onChange={handleChange}
            onBlur={handleBlur}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <>
                    {errors.salary_from ? (
                      <Tooltip placement="left" title={errors.salary_from}>
                        <Box display="flex">
                          <TbAlertCircleFilled
                            color={theme.palette.error.main}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <SalaryTypeConfig />
                    )}
                  </>
                ),
              },
            }}
          />
          <TextField
            fullWidth
            label={"To"}
            error={errors.salary_to}
            name={"salary_to"}
            value={values.salary_to}
            onChange={handleChange}
            onBlur={handleBlur}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <>
                    {errors.salary_to ? (
                      <Tooltip placement="left" title={errors.salary_to}>
                        <Box display="flex">
                          <TbAlertCircleFilled
                            color={theme.palette.error.main}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <SalaryTypeConfig />
                    )}
                  </>
                ),
              },
            }}
          />
        </Box>
        <Select
          options={config.salary_type.items}
          getOptionLabel={(option) => option.value}
          value={getLabelForValue(values.salary_type, config.salary_type.items)}
          label={"Salary Type"}
          onChange={(event, newValue) => {
            setFieldValue("salary_type", newValue.key);
          }}
        />
      </Box>
      <AutocompleteProfile
        title={"Other preferences"}
        options={config.requirements.items}
        getOptionLabel={(option) => option}
        value={values.requirements}
        limit={3}
        chipError={detailsError.requirements}
        ownValue={true}
        onChange={(event, newValue) => {
          setFieldValue(
            "requirements",
            newValue.map((item) => item),
          );
        }}
        name={"requirements"}
      />
    </>
  );
};

export default RequirementsView;
