import { DataGrid } from "@mui/x-data-grid";

const TableDefault = ({ rows, columns, paginationModel }) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel } }}
      pageSizeOptions={[5, 10]}
      sx={{ border: 0 }}
      autosizeOptions
    />
  );
};

export default TableDefault;
