import { useDispatch, useSelector } from "react-redux";
import useApi from "./useApi";
import useUser from "./useUser";
import {
  setObserveLoading,
  toggleObservation,
} from "../services/redux/slices/watchlistSlice";
import usePopup from "./usePopup";
import { useNotification } from "./useNotifications";
import useQueryString from "./useQueryString";

const useWatchlist = (uid) => {
  const dispatch = useDispatch();
  const { user, handleSetUser } = useUser();
  const { request } = useApi();
  const { addNotification } = useNotification();
  const { addQuery } = useQueryString();

  const observedLoading = useSelector(
    (state) => state.watchlist.loadingStates[uid],
  );
  const observed = useSelector((state) =>
    state.watchlist.observations.includes(uid),
  );
  const setObservations = (observations) =>
    dispatch(setObservations(observations));

  const handleObserve = async (uid) => {
    if (!user) {
      addQuery("auth-popup", "login");
      return;
    }

    dispatch(setObserveLoading({ uid, loading: true }));

    await request({
      method: "post",
      url: `/profile/observations/${uid}`,
      onSuccess: (data) => {
        dispatch(toggleObservation({ userId: uid, status: data.status }));
        handleSetUser({
          ...user,
          observations: user.observations
            .filter((el) => el !== uid)
            .concat(data.status === "created" ? uid : []),
        });
        dispatch(setObserveLoading({ uid, loading: false }));
      },
      onError: () => {
        dispatch(setObserveLoading({ uid, loading: false }));
        addNotification("error", "Adding to watchlist failed: user not found.");
      },
    });
  };

  return {
    handleObserve,
    observed,
    observedLoading,
    setObservations,
  };
};

export default useWatchlist;
