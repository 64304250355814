import React from "react";
import useUser from "../../hooks/useUser";
import BecomeSeller from "./Parts/BecomeSeller";
import { Stats } from "react-instantsearch";
import Filters from "./Filters/Filters";
import AlgoliaConfig, { statsTranslations } from "../../config/algoliaConfig";
import FiltersActive from "./Filters/FiltersActive";
import SortBy from "./Parts/Sort";

const HeaderStats = () => {
  return (
    <div
      className={
        "d-flex ms-md-auto align-items-center justify-content-md-center align-self-md-center"
      }
    >
      <Stats className={"font-md"} translations={statsTranslations} />
      <div className={"ms-2"}>
        <SortBy items={AlgoliaConfig.sorting} />
      </div>
    </div>
  );
};

const HeaderDesktop = () => {
  return (
    <>
      <div className={"browse-header"}>
        <div className="d-flex align-items-center justify-content-md-start justify-content-between w-100">
          <Filters />
        </div>
        <BecomeSeller />
      </div>
      <div className="d-flex align-items-center mt-3 flex-md-column mb-lg-0 flex-column flex-lg-row">
        <FiltersActive />
        <HeaderStats />
      </div>
    </>
  );
};

const HeaderMobile = () => {
  return (
    <>
      <div className={"browse-header"}>
        <div className="d-flex align-items-center justify-content-md-start justify-content-between w-100">
          <Filters />
          <HeaderStats />
        </div>
      </div>
      <div className="d-flex align-items-center mt-3 flex-md-column mb-lg-0 flex-column flex-lg-row">
        <FiltersActive />
      </div>
      <BecomeSeller />
    </>
  );
};

const BrowseHeader = () => {
  const { isMobile } = useUser();

  return isMobile ? <HeaderMobile /> : <HeaderDesktop />;
};

export default BrowseHeader;
