import React from "react";
import "../old_components/Browse/Browse.scss";
import { Page } from "../views/Page";
import BrowseHeader from "../old_components/Browse/BrowseHeader";
import { useParams } from "react-router-dom";
import Related from "../old_components/Browse/Related";
import { Browse } from "../components/Browse/Browse";

const Similar = () => {
  let { id } = useParams();
  return (
    <Page title={`Similar records`}>
      <Browse>
        <BrowseHeader setMapEnabled={false} />
        <Related id={id} />
      </Browse>
    </Page>
  );
};

export default Similar;
