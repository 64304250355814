import React, { useEffect, useState } from "react";
import { useWebSocket } from "../../hooks/useWebSocket";
import { Stack } from "@mui/material";
import InboxConversationItem from "./InboxConversationItem";
import useUser from "../../hooks/useUser";
import { InboxConversationsSkeleton } from "./InboxParts";

const initialState = {
  count: 0,
  items: [],
  last: null,
};

const InboxConversations = ({ receiver }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState(initialState);
  const { register, unregister, connected, requests } = useWebSocket();

  // Reset unread count when conversation is opened
  useEffect(() => {
    if (receiver) {
      setConversations((prev) => ({
        ...prev,
        items: prev.items.map((item) =>
          item.receiverId === receiver ? { ...item, unreadCount: 0 } : item,
        ),
      }));
    }
  }, [receiver]);

  // Get conversations
  useEffect(() => {
    setConversations(initialState);
    if (connected) {
      requests.getConversations();
    }
  }, [connected]);

  // Register and unregister websocket events
  useEffect(() => {
    const handleConversations = (response) => {
      const sortedDataItems = response.data.items.sort(
        (x, y) => y.lastMessage - x.lastMessage,
      );
      setConversations((prev) => ({
        count: response.data.count + (prev?.count || 0),
        items: [...prev.items, ...sortedDataItems],
        last: response.data.last,
      }));
      setLoading(false);
    };
    const handleNewMessage = (response) => {
      setConversations((prev) => {
        const { senderId, receiverId, conversationKey } = response.data;
        const item = prev.items.find(
          (item) => conversationKey === item.conversationKey,
        );
        if (item === undefined || item === null) {
          setLoading(true);
          requests.getConversations();
          return initialState;
        }
        const isCurrent =
          (receiverId === user.id && senderId === receiver) ||
          (receiverId === receiver && senderId === user.id);
        return {
          ...prev,
          items: [
            {
              ...item,
              lastMessage: Date.now(),
              unreadCount: isCurrent ? 0 : item.unreadCount + 1,
            },
            ...prev.items.filter(
              (i) => i.conversationKey !== item.conversationKey,
            ),
          ],
        };
      });
    };
    const handleArchive = (data) => {
      setConversations((prev) => ({
        ...prev,
        items: prev.items.filter((item) => item.receiverId !== data.id),
      }));
    };
    register("conversations", handleConversations);
    register("newMessage", handleNewMessage);
    register("archived", handleArchive);
    return () => {
      unregister("conversations", handleConversations);
      unregister("newMessage", handleNewMessage);
      unregister("archived", handleArchive);
    };
  }, [receiver, connected]);

  if (loading || !connected) {
    return <InboxConversationsSkeleton />;
  }

  return (
    <Stack spacing={0} sx={{ maxHeight: "100%", overflowY: "auto" }}>
      {conversations.items.map((data, index) => (
        <InboxConversationItem key={index} data={data} current={receiver} />
      ))}
    </Stack>
  );
};

export default InboxConversations;
