import TooltipMui from "@mui/material/Tooltip";
import { useState } from "react";

const Tooltip = ({ title, children, ...otherProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <TooltipMui
      arrow
      title={title}
      open={open}
      onClose={() => setOpen(false)}
      onClick={() => setOpen(true)}
      onMouseEnter={() => setOpen(true)}
      {...otherProps}
    >
      {children}
    </TooltipMui>
  );
};

export default Tooltip;
