import { styled } from "@mui/material";
import InputMui from "@mui/material/Input";
import React from "react";

export const InputGhost = ({ label, variant, sx = {}, ...props }) => {
  return (
    <>
      <InputMui
        disableUnderline
        sx={{
          border: "none",
          background: "#fff",
          boxShadow: "none",
          lineHeight: "1rem",
          padding: "0.5rem 0.5rem",
          fontSize: "0.9rem",
          fontWeight: 300,
          outline: "none",
          ...sx,
        }}
        {...props}
      />
    </>
  );
};

const InputDefaultStyled = styled(InputMui)(({ theme }) => ({
  "&.MuiInput-root": {
    padding: 0,
  },
  ".MuiInput-input": {
    borderRadius: "30px",
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: 300,
    border: "1px solid #e0e0e0",
  },
  "&.Mui-focused .MuiInput-input": {
    borderColor: theme.palette.primary.main,
  },
}));
export const InputDefault = ({
  onPressEnter = null,
  variant,
  sx,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && onPressEnter && !event.shiftKey) {
      onPressEnter(event.target.value);
      event.preventDefault();
    }
  };
  return (
    <>
      <InputDefaultStyled
        onKeyDown={handleKeyDown}
        disableUnderline
        sx={sx}
        {...props}
      />
    </>
  );
};
