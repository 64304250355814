import { Formik } from "formik";
import Text from "../../components/Typography/Text";
import React, { useState } from "react";
import { Box } from "@mui/material";
import TextField, { TogglePassword } from "../../components/Forms/TextField";
import ButtonPrimary from "../../components/Buttons/Button";
import { confirmResetPassword } from "aws-amplify/auth";
import authNewPasswordSchema from "../../validations/auth/authNewPasswordSchema";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Tooltip from "../../components/Tooltip";
import FmdBadIcon from "@mui/icons-material/FmdBad";

const AuthNewPasswordView = ({ setPopupActive }) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const initialData = {
    password: "",
    passwordConfirmation: "",
  };

  const resetPassword = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await confirmResetPassword({
        username: localStorage.getItem("forgot-email"),
        confirmationCode: localStorage.getItem("forgot-verify-code"),
        newPassword: values.password,
      });
      setLoading(false);
      setPopupActive("login");
      localStorage.removeItem("forgot-email");
      localStorage.removeItem("forgot-verify-code");
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleBack = () => {
    setPopupActive("forgot-verify");
    localStorage.removeItem("forgot-verify-code");
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialData}
      onSubmit={resetPassword}
      validationSchema={Yup.object().shape(authNewPasswordSchema(t))}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={1.5} mt={2}>
            <TextField
              label={"Password"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              name={"password"}
              error={errors.password}
              type={passwordVisible ? "text" : "password"}
              slotProps={{
                input: {
                  endAdornment: (
                    <>
                      {errors.password ? (
                        <Tooltip arrow placement="left" title={errors.password}>
                          <FmdBadIcon color={"error"} />
                        </Tooltip>
                      ) : (
                        <TogglePassword
                          passwordVisible={passwordVisible}
                          onToggle={() =>
                            setPasswordVisible((prevState) => !prevState)
                          }
                        />
                      )}
                    </>
                  ),
                },
              }}
            />
            <TextField
              label={"Repeat password"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordConfirmation}
              name={"passwordConfirmation"}
              error={errors.passwordConfirmation}
              type={passwordVisible ? "text" : "password"}
              slotProps={{
                input: {
                  endAdornment: (
                    <>
                      {errors.passwordConfirmation && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={errors.passwordConfirmation}
                        >
                          <FmdBadIcon color={"error"} />
                        </Tooltip>
                      )}
                    </>
                  ),
                },
              }}
            />
            <ButtonPrimary loading={loading} type={"submit"}>
              Set new password
            </ButtonPrimary>
          </Box>
          <Box
            onClick={handleBack}
            display={"flex"}
            justifyContent={"center"}
            mt={1}
          >
            <Text text={"back"} variant={"overline"} color={"#000"} />
          </Box>
          <Text text={error} variant={"body2"} color={"error"} />
        </form>
      )}
    </Formik>
  );
};

export default AuthNewPasswordView;
