import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import configSlice from "./slices/configSlice";
import webSocketSlice from "./slices/webSocketSlice";
import popupSlice from "./slices/popupSlice";
import errorSlice from "./slices/errorSlice";
import watchlistSlice from "./slices/watchlistSlice";

const store = configureStore({
  reducer: {
    config: configSlice,
    webSocket: webSocketSlice,
    user: userSlice,
    popup: popupSlice,
    error: errorSlice,
    watchlist: watchlistSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
