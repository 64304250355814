import React, { useEffect } from "react";

import { Rings } from "react-loader-spinner";

export const AppLoader = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://code.createjs.com/1.0.0/createjs.min.js";
    document.head.appendChild(script1);

    let script2;

    script1.onload = () => {
      script2 = document.createElement("script");
      script2.src = `${process.env.REACT_APP_URL}assets/js/preloader.js`;
      document.head.appendChild(script2);

      script2.onload = () => {
        if (window.LoaderInit) {
          window.LoaderInit();
        }
      };
    };

    return () => {
      document.head.removeChild(script1);
      if (script2 !== undefined) {
        document.head.removeChild(script2);
      }
    };
  }, []);

  return (
    <>
      <div className={"main-loading"}>
        <div id="animation_container">
          <canvas id="canvas" width="285" height="285"></canvas>
          <div id="dom_overlay_container"></div>
        </div>
      </div>
    </>
  );
};
