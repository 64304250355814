import { Rings } from "react-loader-spinner";
import { Star } from "react-feather";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUser from "../../../hooks/useUser";
import useWatchlist from "../../../hooks/useWatchlist";

const ObserverButton = ({ className, size, isText, uid }) => {
  const { t } = useTranslation();
  const { observed, handleObserve, observedLoading } = useWatchlist(uid);
  const { user, isIOS } = useUser();

  if (user && user.id === uid) return null;

  return (
    <button
      title={observed ? "Przestań obserwować" : "Obserwuj"}
      className={
        !observedLoading && observed
          ? `${className} observed icon-button`
          : `${className} icon-button`
      }
      onClick={() => handleObserve(uid)}
      disabled={observedLoading}
    >
      <div className={`observe-icon ${observed ? "animation-active" : ""}`}>
        {observedLoading ? (
          <Rings
            height="16"
            width="16"
            color="#ffffff"
            radius="6"
            visible={true}
            ariaLabel="rings-loading"
          />
        ) : (
          <Star className={observed ? "star-icon" : ""} size={size} />
        )}
      </div>
    </button>
  );
};

export default ObserverButton;
