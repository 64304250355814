import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

const SliderControls = ({ handlePrev, handleNext, index, total, ref }) => {
  return (
    <div className={"controls"} ref={ref}>
      {index !== 0 && (
        <button className={"prev"} onMouseDown={handlePrev}>
          <ChevronLeft color={"#fff"} size={50} />
        </button>
      )}
      {index + 1 !== total && (
        <button className={"next"} onMouseDown={handleNext}>
          <ChevronRight color={"#fff"} size={50} />
        </button>
      )}
    </div>
  );
};

export default SliderControls;
