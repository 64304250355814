import Text from "../../../components/Typography/Text";
import React, { useRef, useState } from "react";
import {
  Box,
  FormControl,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { RadioIcon } from "../../../components/Forms/Radio";
import buyerImage from "../../../assets/images/account-type/buyer.png";
import sellerImage from "../../../assets/images/account-type/seller.png";
import ButtonPrimary from "../../../components/Buttons/Button";
import useUser from "../../../hooks/useUser";
import useApi from "../../../hooks/useApi";
import { useNavigate } from "react-router";
import { ModalHelper } from "../../../components/Modals/Modal";

const AccountTypeView = () => {
  const { user, handleSetUser } = useUser();

  const { request } = useApi();

  const theme = useTheme();

  const searcherRadioRef = useRef();

  const providerRadioRef = useRef();

  const [accountType, setAccountType] = useState(user.account_type);

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  const handleSearcherClick = () => {
    if (searcherRadioRef.current) {
      searcherRadioRef.current.click();
    }
  };

  const handleProviderClick = () => {
    if (providerRadioRef.current) {
      providerRadioRef.current.click();
    }
  };

  const handleAccountTypeChange = (accountType) => {
    setAccountType(accountType);
  };

  const changeAccountType = async () => {
    if (accountType === user.account_type) {
      return;
    }
    setLoading(true);
    await request({
      url: "/profile",
      method: "post",
      body: {
        action: "set_account_type",
        type: accountType,
      },
      onSuccess: (data) => {
        if (data.status === "success") {
          handleSetUser({ ...user, account_type: data.type });
        }
      },
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Box alignItems={"center"} display={"flex"} mb={2}>
        <Text mr={1} text={"Choose your account type"} variant={"h3"} />
        <ModalHelper
          title={"Account type"}
          text={
            "In this section, you can choose the type of account that best fits your needs. If you’re looking to hire services, select Client. If you want to offer your services and showcase your skills, select Seller. You can switch between account types at any time, depending on your goals."
          }
        />
      </Box>
      <FormControl fullWidth>
        <RadioGroup
          defaultValue={accountType}
          onChange={(e) => handleAccountTypeChange(e.target.value)}
        >
          <Grid container spacing={1}>
            <Grid
              position="relative"
              borderRadius={3}
              p={isMobile ? 2 : 3}
              item
              size={6}
              onClick={handleSearcherClick}
              style={{ cursor: "pointer" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "flex-start" : "space-between",
                border: 1,
                borderColor:
                  accountType === "searcher"
                    ? theme.palette.primary.main
                    : "#D9D9D9",
              }}
            >
              <Box order={isMobile ? 2 : 1}>
                <Text text={"Client"} variant={"h2"} />
                <Text
                  mt={isMobile ? 1 : 4}
                  mb={isMobile ? 1 : 4}
                  text={
                    "As a Client, you can browse through profiles, search for services, and directly contact service providers. This account type is ideal if you're looking to hire or collaborate with freelancers. You can always switch to a Seller account if you decide to offer services yourself in the future."
                  }
                  color={"#40464E"}
                  variant={"body2"}
                />
                <RadioIcon
                  value="searcher"
                  controlRef={searcherRadioRef}
                  checked={accountType === "searcher"}
                  sx={{ position: "absolute", top: 0, right: 0, margin: 0 }}
                />
              </Box>
              <Box order={isMobile ? 1 : 2}>
                <img src={buyerImage} width={isMobile ? "100%" : "90%"} />
              </Box>
            </Grid>
            <Grid
              position="relative"
              borderRadius={3}
              p={isMobile ? 2 : 3}
              item
              size={6}
              onClick={handleProviderClick}
              style={{ cursor: "pointer" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "flex-start" : "space-between",
                border: 1,
                borderColor:
                  accountType === "provider"
                    ? theme.palette.primary.main
                    : "#D9D9D9",
              }}
            >
              <Box order={isMobile ? 2 : 1}>
                <Text text={"Seller"} variant={"h2"} mt={isMobile ? 1 : 0} />
                <Text
                  mt={isMobile ? 1 : 4}
                  mb={isMobile ? 1 : 4}
                  text={
                    "As a Seller, you can create and manage a profile to offer your services, showcase your skills, and connect with potential clients. This account type is ideal if you're looking to advertise your services and attract new clients. You can switch back to a Client account at any time."
                  }
                  color={"#40464E"}
                  variant={"body2"}
                />
                <RadioIcon
                  value="provider"
                  controlRef={providerRadioRef}
                  checked={accountType === "provider"}
                  sx={{ position: "absolute", top: 0, right: 0, margin: 0 }}
                />
              </Box>
              <Box order={isMobile ? 1 : 2}>
                <img src={sellerImage} width={isMobile ? "100%" : "90%"} />
              </Box>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box mt={2} fullWidth display="flex" flexDirection="row" gap={1}>
        <ButtonPrimary
          loading={loading}
          fullWidth
          onClick={changeAccountType}
          variant={"contained"}
          disabled={accountType === user.account_type}
        >
          {accountType === "searcher" ? "BECOME A CLIENT" : "BECOME A SELLER"}
        </ButtonPrimary>
        {user.account_type === "provider" && (
          <ButtonPrimary
            fullWidth
            variant={"contained"}
            onClick={() => navigate("/account/edit")}
          >
            Edit profile
          </ButtonPrimary>
        )}
      </Box>
    </>
  );
};

export default AccountTypeView;
