import NoResultsIcon from "../../assets/svg/icon-no-results.svg";
import { ClearFilters } from "../../old_components/Browse/Filters/FiltersParts";
import React from "react";
import { Box, Typography } from "@mui/material";

export const BrowseNoResults = ({ handleReset }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center" mb={3} mt={3}>
        <img
          src={NoResultsIcon}
          alt="No results found"
          style={{ maxWidth: "200px", width: "100%", height: "auto" }}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={3}>
        <ClearFilters handleReset={handleReset} />
      </Box>
      <Box textAlign="center">
        <Typography variant="h5" gutterBottom>
          We couldn&apos;t find any records listings that match your selected
          criteria.
        </Typography>
        <Typography variant="body1">
          Check for any typos in the phrases you entered or try a more general
          query.
        </Typography>
      </Box>
    </Box>
  );
};
